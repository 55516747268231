// import Switch from '../Switch';

import React, { useState, useEffect } from "react";
import "./Switch.css";
import FastAPIClient from "../../client";
import config from "../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Switch = ({ isOn, handleToggle, onColor }) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
                style={{ maxHeight: 5 }}
            />
            <label
                style={{ background: isOn && onColor }}
                className="react-switch-label"
                htmlFor={`react-switch-new`}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    );
};

const Collapsible = ({
    item,
    text,
    read,
    getRecommendations,
    clientInfo,
    client_id,
}) => {
    const [open, setOPen] = useState(false);

    const [value, setValue] = useState(false);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const updateNotifications = async () => {
        item.read = true;
        await client.updateRecommendation(item.task_id, item);
        await getRecommendations(client_id);
        await updateAlerts(clientInfo);
    };

    const updateAlerts = (info) => {
        info.alert = false;
        client.updateAlert(clientInfo);
    };

    const toggle = () => {
        setOPen(!open);
    };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="text-black bg-white">
                <div className="flex-col md:ml-5 md:mr-5">
                    <div className="flex w-full flex-row items-center">
                        <div className="flex w-5/6 xl:w-5/7 md:w-5/7 sm:w-5/7 font-bold text-black ">
                            <div className="flex w-full flex-row items-center">
                                <div className="w-5/6">{text}</div>
                                <div className="w-1/6">
                                    {!read && (
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-gold text-white text-xs rounded-full"
                                            data-config-id="change2"
                                            style={{ fontSize: 10 }}
                                        >
                                            <FormattedMessage
                                                id="nba.new"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-1/6 xl:w-2/7 md:w-2/7 sm:w-2/7">
                            <button onClick={toggle}>
                                <embed
                                    className=""
                                    src="https://cdn-icons-png.flaticon.com/512/60/60781.png"
                                    width="15px"
                                    height="15px"
                                />
                            </button>
                        </div>
                    </div>
                    {open && (
                        <div>
                            {" "}
                            <div className="mt-5">
                                {/* Clos Oratoire rouge sales are down 30% versus last year */}
                            </div>
                            <div>
                                <div className="mt-5">
                                    <label className="font-bold">
                                        {" "}
                                        <FormattedMessage
                                            id="nba.outcome"
                                            values={{ number: 1 }}
                                        />
                                    </label>
                                </div>
                                <div className="flex flex-row">
                                    {" "}
                                    <div className="mr-3">
                                        {" "}
                                        <FormattedMessage
                                            id="nba.accepted"
                                            values={{ number: 1 }}
                                        />{" "}
                                    </div>
                                    <Switch
                                        isOn={value}
                                        onColor="#EF476F"
                                        handleToggle={() => setValue(!value)}
                                    />{" "}
                                    <div className="ml-3">
                                        {" "}
                                        <FormattedMessage
                                            id="nba.declined"
                                            values={{ number: 1 }}
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            {value && client_id == "1" && (
                                <div className="mt-3">
                                    {/* <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Remplacé par un article de la
                                        concurrence
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Retiré de l&apos;assortiment
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Non pertinent
                                    </div> */}
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.1" />{" "}
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.2" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.3" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.4" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.5" />
                                    </div>
                                </div>
                            )}
                            {value && client_id == "2" && (
                                <div className="mt-3">
                                    {/* <div>
              <input type="radio" value="MALE" name="gender" /> Replaced with other reference
            </div> */}
                                    {/* <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Replaced with competitor item
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Dropped from assortment
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Not relevant
                                    </div> */}
                                    {/* <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Remplacé par un article de la
                                        concurrence
                                    </div> */}
                                    {/* <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Revenez plus tard
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Non pertinent
                                    </div> */}
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.1" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.2" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.3" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.4" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.5" />
                                    </div>
                                </div>
                            )}
                            {value && client_id == "3" && (
                                <div className="mt-3">
                                    {/* <div>
              <input type="radio" value="MALE" name="gender" /> Replaced with other reference
            </div> */}
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.1" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.2" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.3" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.4" />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        <FormattedMessage id="nba.5" />
                                    </div>
                                </div>
                            )}
                            {value && client_id == "79854267" && (
                                <div className="mt-3">
                                    {/* <div>
              <input type="radio" value="MALE" name="gender" /> Replaced with other reference
            </div> */}
                                    {/* <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Replaced with competitor
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Ordering from producer
                                    </div> */}
                                    <div>
                                        <input
                                            type="radio"
                                            value="MALE"
                                            name="gender"
                                        />{" "}
                                        Non pertinent
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className="mt-5">
                                    <label className="font-bold">
                                        <FormattedMessage
                                            id="nba.comments"
                                            values={{ number: 1 }}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <textarea
                                        rows="3"
                                        cols="45"
                                        className=" border-2"
                                    ></textarea>
                                </div>
                                <div className="flex w-full flex-row items-center pb-5 pt-5">
                                    <div className="w-3/5 md:w-4/5"></div>
                                    <div className="w-2/5 md:w-1/5">
                                        <a
                                            // onClick={toggle}
                                            onClick={() => {
                                                updateNotifications();
                                            }}
                                            className="transition ease-in-out delay-150 hover:-translate-y-0 hover:scale-100 border-buttonMainBackground border bg-buttonMainBackground cursor-pointer hover:bg-buttonSecondaryBackground text-white hover:text-buttonSecondaryFontColor font-bold px-4 py-2 mx-auto mt-3 rounded-full"
                                        >
                                            <FormattedMessage
                                                id="nba.submit"
                                                values={{ number: 1 }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </IntlProvider>
    );
};

export default Collapsible;
