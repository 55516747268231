import React, { useEffect } from "react";
// import DashboardHeader from "../../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
// import FastAPIClient from "../../client";
// import config from "../../config";
// import Button from "../../components/Button/Button";
// import FormInput from "../../components/FormInput/FormInput";
import "./shapedivider.css";
import { useAuth0 } from "@auth0/auth0-react";

import build_param from "../../build_params.json";
import LoginButton from "../../components/LoginButton";

import { isPlatform } from "@ionic/react";

const frontendPath = build_param.frontendPath;

const iosOrAndroid = isPlatform("hybrid");

// const client = new FastAPIClient(config);

const Login = () => {
    // const [error, setError] = useState({ email: "", password: "" });
    // const [loginForm, setLoginForm] = useState({ email: "", password: "" });

    // const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    // const onLogin = (e) => {
    //     e.preventDefault();
    //     setError(false);
    //     setLoading(true);

    //     if (loginForm.email.length <= 0) {
    //         setLoading(false);
    //         return setError({ email: "Please Enter Email Address" });
    //     }
    //     if (loginForm.password.length <= 0) {
    //         setLoading(false);
    //         return setError({ password: "Please Enter Password" });
    //     }

    //     client.login(loginForm.email, loginForm.password).then(() => {
    //         navigate("/home");
    //     });
    //     // .catch((err) => {
    //     //     setLoading(false);
    //     //     setError(true);
    //     //     print("Test tobi");
    //     // });
    // };
    const { getAccessTokenSilently } = useAuth0();

    const login_auth0 = async () => {
        console.log("Step 1");
        loginWithRedirect();
        console.log("Step 2");
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: `https://www.api.myway.technology`,
                scope: "read:appointments",
            },
        });
        console.log("Step 3");
        // console.log(accessToken);
        localStorage.setItem("token_test", accessToken);
        console.log("Huhu");
    };

    // const { logout } = useAuth0();

    // const logout_auth0 = async () => {
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("token_test");
    //     localStorage.removeItem("user");
    //     localStorage.removeItem("layoutData");
    //     localStorage.removeItem("language");
    //     logout({
    //         logoutParams: {
    //             returnTo: "http://127.0.0.1:3000",
    //         },
    //     });
    // };
    // const buttonStyle = "transition text hover:-translate-y-0 hover:scale-100  bg-white cursor-pointer hover:bg-fade text-black px-4 py-2 mx-auto rounded-md mt-10";

    const { loginWithRedirect } = useAuth0();

    const { user, isAuthenticated } = useAuth0();

    useEffect(() => {
        const accessToken = getAccessTokenSilently({
            authorizationParams: {
                audience: `https://www.api.myway.technology`,
                scope: "read:appointments",
            },
        });
        console.log(accessToken);
        // Set the current time using the moment library
        if (isAuthenticated) {
            console.log("redirecting");
            navigate("/home");
        }
    }, [isAuthenticated]);

    console.log(frontendPath);

    return (
        <>
            <section className="bg-white ">
                {/* <DashboardHeader /> */}
                <div className="flex flex-row w-full items-center justify-center">
                    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-left w-full xl:w-3/6 md:w-3/6 sm:w-full">
                        <div className="w-full max-w-xs m-auto bg-white rounded p-5 shadow-lg">
                            <header>
                                {/* <img className="w-20 mx-auto mb-5" src="https://img.icons8.com/fluent/344/year-of-tiger.png" /> */}
                                {/* <div className="flex items-center justify-center w-20 h-20 mx-auto mb-5 bg-black rounded-full "> */}
                                {/* <svg className=" h-8 w-8" width="54" height="54" viewBox="0 0 54 54" fill='white'  xmlns="http://www.w3.org/2000/svg" >
                  <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/>
                </svg> */}

                                {/* </div> */}
                                <img
                                    src={require("./myway.png")}
                                    width="120"
                                    className="ml-20 mb-10 mt-10"
                                ></img>
                            </header>
                            {/* <form onSubmit={(e) => onLogin(e)}>
                                <FormInput
                                    type={"text"}
                                    name={"email"}
                                    label={"Username"}
                                    error={error.email}
                                    value={loginForm.email}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            email: e.target.value,
                                        })
                                    }
                                />
                                <FormInput
                                    type={"password"}
                                    name={"password"}
                                    label={"Password"}
                                    error={error.password}
                                    value={loginForm.password}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            password: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    title={"Login"}
                                    loading={loading}
                                    error={error.password}
                                />
                            </form> */}
                            {/* <form onSubmit={(e) => onLogin(e)}>
                                <FormInput
                                    type={"text"}
                                    name={"email"}
                                    label={"Username"}
                                    error={error.email}
                                    value={loginForm.email}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            email: e.target.value,
                                        })
                                    }
                                />
                                <FormInput
                                    type={"password"}
                                    name={"password"}
                                    label={"Password"}
                                    error={error.password}
                                    value={loginForm.password}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            password: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    title={"Login"}
                                    loading={loading}
                                    error={error.password}
                                />

                            </form> */}
                            {!iosOrAndroid && (
                                <button
                                    className="rounded-md bg-black text-white w-full font-semibold p-2"
                                    onClick={() => login_auth0()}
                                >
                                    Login
                                </button>
                            )}

                            {/* <button
                                className="text-black"
                                onClick={() => logout_auth0()}
                            >
                                Log Out
                            </button> */}
                            {iosOrAndroid && <LoginButton />}

                            {isAuthenticated && (
                                <div>
                                    <img src={user.picture} alt={user.name} />
                                    <h2>{user.name}</h2>
                                    <p>{user.email}</p>
                                </div>
                            )}
                            <footer></footer>
                        </div>
                        <h1 className="text-xxs mb-5">
                            {" "}
                            © 2023 MYWAY Technology SAS, All rights reserved.
                        </h1>
                    </div>
                    {/* <div className="custom-shape-divider-top-1678744511">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
    </svg>
</div> */}
                    <div className="custom-shape-divider-bottom-1678740708 invisible xl:w-3/6 md:w-3/6 sm:3/6 md:visible">
                        <svg
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                className="shape-fill"
                            ></path>
                        </svg>
                    </div>
                    {/* <div className="custom-shape-divider-bottom-1678744624">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
    </svg>
</div> */}
                    <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-400 text-left w-0 invisible xl:w-3/6 md:w-3/6 sm:w-0 md:visible">
                        <h1 className="text-xxl font-bold text-white ml-10 mr-40">
                            Empower your sales agents and provide outstanding
                            customer experiences.
                        </h1>
                        <h1 className="text-white text-xl ml-10 mr-40 mt-10">
                            Grow your clients&apos; lifetime value, their
                            satisfaction, your revenue, operating margin, team
                            productivity and engagement with MYWAY.
                        </h1>
                        {/* <img src="https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/81NBLm66taL._AC_SL1500_.jpg" width='240px' className='mt-10'></img> */}
                        {/* <a className={buttonStyle}>GET THE E-BOOK</a> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;
