import React from "react";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

// import PopupModalSmall from "../../components/ModalSmall/PopupModalSmall";

const ProductDetails = ({ product, locale }) => {
    // const [open, setOPen] = useState(false);

    // var alert_color = [];
    // if (product.product_level_2 == "Red") {
    //     alert_color = "#b91c1c";
    // } else if (product.product_level_2 == "Rose") {
    //     alert_color = "#fecaca";
    // } else {
    //     alert_color = "#fef3c7";
    // }

    // const toggle = () => {
    //     setOPen(!open);
    //   };

    // const [archiveModal, setArchiveModal] = useState(false);

    return (
        product && (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="flex text-gray-700 w-full bg-white rounded-lg">
                        <div
                            className="flex flex-row items-center bg-white rounded-lg drop-shadow w-full"
                            // style={{ minHeight: 100, maxHeight: 180 }}
                        >
                            {product.stock < 1 && (
                                <div
                                    className="bg-royal_red absolute rounded-tr-lg rounded-bl-lg"
                                    style={{
                                        top: 0,
                                        right: 0,
                                        minWidth: 70,
                                        minHeight: 25,
                                    }}
                                >
                                    <span className="text-white font-semibold text-xxs p-2">
                                        <FormattedMessage id="productdetails.stock_disruption" />
                                    </span>
                                </div>
                            )}
                            {product.product_year == "Mezcal" && (
                                <div
                                    className="bg-royal_red absolute rounded-tr-lg rounded-bl-lg"
                                    style={{
                                        top: 0,
                                        right: 0,
                                        minWidth: 75,
                                        minHeight: 30,
                                    }}
                                >
                                    <span className="text-white font-semibold text-xxs p-2">
                                        {/* % Upcoming promo */}%{" "}
                                        <FormattedMessage id="productdetails.promotion" />
                                    </span>
                                </div>
                            )}
                            {/* <div className="flex w-1/5 h-2/3"> */}
                            <img
                                className="w-1/4 object-scale-down pl-2 pr-2"
                                style={{ height: "66.6666%" }}
                                src={product?.product_img}
                            />
                            {/* </div> */}
                            <div className="w-3/4 float-left text-xs">
                                <p className="text-base uppercase pt-3 pb-2 tracking-wider">
                                    {product?.product_name}
                                </p>
                                <div className="flex flex-row items-start text-gray-800 items-center">
                                    <img
                                        src="https://cdns.iconmonstr.com/wp-content/releases/preview/2012/240/iconmonstr-info-2.png"
                                        width="18"
                                        height="18"
                                        className="flex mt-0.5"
                                    />{" "}
                                    <span className="ml-2 mr-2">
                                        {product?.product_desc}{" "}
                                    </span>
                                </div>
                                <div className="grid grid-cols-2 gap-1 pt-1 pb-3 text-gray-800">
                                    <div className="flex flex-row items-start">
                                        {/* <img
                                        src="https://www.svgrepo.com/show/111256/house.svg"
                                        width="18"
                                        height="18"
                                    />
                                    <span className="ml-2">
                                        {product?.product_level_1}
                                    </span>{" "} */}
                                    </div>
                                    <div className="flex flex-row items-start">
                                        {/* <img
                                        src="https://freesvg.org/img/1529054037.png"
                                        width="18"
                                        height="18"
                                    />
                                    <span className="ml-2">
                                        {product?.product_year}
                                    </span> */}
                                    </div>

                                    <div className="flex flex-row items-start items-center">
                                        {/* <span
                                        className="inline-block py-2.5 px-2.5 text-white text-xs rounded-full"
                                        style={{ backgroundColor: alert_color }}
                                    /> */}
                                        <img
                                            // src="https://www.svgrepo.com/show/237548/bones-pet.svg"
                                            // src="https://www.svgrepo.com/show/490579/bottle-wine.svg"
                                            src="https://www.svgrepo.com/show/521523/box.svg"
                                            width="18"
                                            height="18"
                                        />{" "}
                                        <span className="ml-1.5">
                                            {product?.product_level_1}
                                        </span>
                                    </div>
                                    <div className="flex flex-row items-start items-center">
                                        <img
                                            // src="https://www.svgrepo.com/show/482797/weight-scale-1.svg"
                                            src="https://www.svgrepo.com/show/532833/battery-full.svg"
                                            // src="https://www.svgrepo.com/show/513464/house.svg"
                                            width="18"
                                            height="18"
                                        />{" "}
                                        <span className="ml-2">
                                            {/* {product?.product_level_3} */}
                                            {product?.product_level_2}
                                        </span>
                                    </div>
                                    <div className="flex flex-row items-start items-center">
                                        <img
                                            src="https://icons.veryicon.com/png/o/object/material-design-icons/euro-symbol.png"
                                            width="18"
                                            height="18"
                                        />{" "}
                                        <span className="ml-2">
                                            {product?.price}
                                        </span>{" "}
                                    </div>
                                    <div className="flex flex-row items-start items-center">
                                        <img
                                            src="https://www.svgrepo.com/show/308663/inventory-logistics-warehouse.svg"
                                            width="18"
                                            height="18"
                                        />{" "}
                                        <span className="ml-2">
                                            {" "}
                                            {product?.stock}
                                        </span>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IntlProvider>
            </>
        )
    );
};

export default ProductDetails;
