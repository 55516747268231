/*eslint-disable*/

import React, { useEffect, useState } from "react";
import {
    FiLogOut,
    FiHome,
    FiTool,
    FiUsers,
    FiBook,
    FiFilter,
    // FiMap,
    FiShoppingCart,
    FiCalendar,
} from "react-icons/fi";

import { FaGraduationCap } from "react-icons/fa";
// import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { VscBlank } from "react-icons/vsc";

import { useAuth0 } from "@auth0/auth0-react";

import { CgPerformance } from "react-icons/cg";
import {
    Sidebar,
    Menu,
    MenuItem,
    sidebarClasses,
    SubMenu,
    menuClasses,
} from "react-pro-sidebar";
import { useNavigate, Link } from "react-router-dom";
import FastAPIClient from "../../client";
import config from "../../config";
// import jwtDecode from "jwt-decode";
// import * as moment from "moment";

import Loader from "../Loader";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

import build_param from "../../build_params.json";

const frontendPath = build_param.frontendPath;

function Navigator() {
    const [layout, setLayout] = useState([]);
    const [color, setColor] = useState();

    const [loading, setLoading] = useState(false);

    const [locale, setLocale] = useState();
    const [tokenFlag, setToken] = useState(false);

    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const [leadNotification, setLeadNotification] = useState(false);
    const [recommendationNotification, setRecommendationNotification] =
        useState(false);

    const handleLogout = () => {
        client.logout();
        // setIsLoggedIn(false);
        navigate("/");
    };

    const { logout } = useAuth0();

    const logout_auth0 = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_test");
        localStorage.removeItem("user");
        localStorage.removeItem("layoutData");
        localStorage.removeItem("language");
        logout({
            logoutParams: {
                returnTo: `${frontendPath}/`,
            },
        });
    };

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            const data = await client.fetchUser();

            // Set user state
            await setUser(data);
            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // Function to check if user data is present in localStorage
    const getUserDataFromLocalStorage = () => {
        const storedUserData = localStorage.getItem("user");

        if (storedUserData) {
            // If data is present, parse and return it
            return JSON.parse(storedUserData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    // useEffect(() => {
    //     // Check if user data is present in localStorage
    //     const userDataFromLocalStorage = getUserDataFromLocalStorage();

    //     if (userDataFromLocalStorage) {
    //         // If data is present, use it directly
    //         setUser(userDataFromLocalStorage);
    //         console.log(loading);

    //         const storedLanguage = localStorage.getItem("language");
    //         setLocale(storedLanguage);
    //         console.log(storedLanguage);
    //         setLoading(true);
    //         console.log("changed to true");
    //         console.log(loading);
    //     } else {
    //         // If data is not present, fetch it
    //         fetchUserData();
    //     }

    //     // ... (other useEffect logic)
    // }, []); // Empty dependency array means it runs only on mount

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://www.api.myway.technology",
                        scope: "read:appointments",
                    },
                });

                console.log("logging token");
                console.log(accessToken);
                // localStorage.setItem(
                //     "@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments",
                //     accessToken
                // );
                localStorage.setItem("token", accessToken);
                // You can set the current time using the moment library here if needed
            } catch (error) {
                console.error("Failed to fetch access token:", error);
            }
        };
        // localStorage.setItem("language", "hase");
        const tokenData = localStorage.getItem("token");

        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            fetchAccessToken();
            setToken(true);
        } else {
            setToken(true);
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if user data is present in localStorage
                const userDataFromLocalStorage = getUserDataFromLocalStorage();

                if (userDataFromLocalStorage) {
                    // If data is present, use it directly
                    setUser(userDataFromLocalStorage);

                    const storedLanguage = localStorage.getItem("language");
                    setLocale(storedLanguage);

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    setLoading(false);
                } else {
                    // If data is not present, fetch it
                    await fetchUserData();

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // const storedLanguage =
                    await setLocale(localStorage.getItem("language"));

                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors here
            }
        };

        // Call the asynchronous function
        fetchData();

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    // useEffect(() => {
    //     // Retrieve token from localStorage
    //     const storedToken = localStorage.getItem("token");

    //     // Check if token exists in localStorage
    //     if (storedToken) {
    //         // Update token state
    //         setToken(true);
    //     } else {
    //         // If token doesn't exist in localStorage, set it
    //         localStorage.setItem(
    //             "token",
    //             "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2V4YW1wbGUuY29tL2VtYWlsIjoiZGVtb0BteXdheS5jb20iLCJ0ZW5hbnQiOiJteXdheSIsIm1haWwiOiJkZW1vQG15d2F5LmNvbSIsInRlbmFudF9iYWNrdXAiOiJteXdheSIsImlzcyI6Imh0dHBzOi8vZGV2LW15d2F5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NDlhZTAyYTMyMDRkMTQ1NWE0ZmRhMjciLCJhdWQiOlsiaHR0cHM6Ly93d3cuYXBpLm15d2F5LnRlY2hub2xvZ3kiXSwiaWF0IjoxNzEwODc0MDEzLCJleHAiOjE3MTA5NjA0MTMsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgcmVhZDphcHBvaW50bWVudHMiLCJhenAiOiJLS2JPWklNZU9rNTZET3hOcG53WUNhRXZ3aG5ub1pqUiIsInBlcm1pc3Npb25zIjpbInJlYWQ6YXBwb2ludG1lbnRzIl19.r2YcA-3RRgO5KtAvlyEkKtze3d2R-nDNaQrEZ16QAL0"
    //         );
    //         // Update token state
    //         setToken(true);
    //     }
    // }, []);

    useEffect(() => {
        // fetchLayout();
        // localStorage.setItem(
        //     "token",
        //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2V4YW1wbGUuY29tL2VtYWlsIjoiZGVtb0BteXdheS5jb20iLCJ0ZW5hbnQiOiJteXdheSIsIm1haWwiOiJkZW1vQG15d2F5LmNvbSIsInRlbmFudF9iYWNrdXAiOiJteXdheSIsImlzcyI6Imh0dHBzOi8vZGV2LW15d2F5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NDlhZTAyYTMyMDRkMTQ1NWE0ZmRhMjciLCJhdWQiOlsiaHR0cHM6Ly93d3cuYXBpLm15d2F5LnRlY2hub2xvZ3kiXSwiaWF0IjoxNzEwODc0MDEzLCJleHAiOjE3MTA5NjA0MTMsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgcmVhZDphcHBvaW50bWVudHMiLCJhenAiOiJLS2JPWklNZU9rNTZET3hOcG53WUNhRXZ3aG5ub1pqUiIsInBlcm1pc3Npb25zIjpbInJlYWQ6YXBwb2ludG1lbnRzIl19.r2YcA-3RRgO5KtAvlyEkKtze3d2R-nDNaQrEZ16QAL0"
        // );
        getLeadNotifications();
        getRecommendationNotifications();
    }, [tokenFlag]);

    const getLeadNotifications = () => {
        client.getLeadNotifications().then((data) => {
            // Set the username in the state
            setLeadNotification(data);
        });
    };

    const getRecommendationNotifications = () => {
        client.getRecommendationNotifications().then((data) => {
            // Set the username in the state
            setRecommendationNotification(data);
        });
    };

    // const fetchLayout = () => {
    //     client.getLayout().then((data) => {
    //         // setRefreshing(false);
    //         setLayout(data?.results);
    //     });
    // };

    const [user, setUser] = useState(false);

    // useEffect(() => {
    //     getUserName();
    // }, []);

    // const getUserName = () => {
    //     client.fetchUser().then((data) => {
    //         setUser(data);
    //     });
    // };

    // Function to fetch layout and save it in localStorage
    const fetchLayout = async () => {
        try {
            const data = await client.getLayout();
            // Save layout data in localStorage
            localStorage.setItem("layoutData", JSON.stringify(data?.results));
            // Set layout state
            setLayout(data?.results);
        } catch (error) {
            console.error("Error fetching layout:", error);
        }
    };

    // Function to check if layout data is present in localStorage
    const getLayoutDataFromLocalStorage = () => {
        const storedLayoutData = localStorage.getItem("layoutData");

        if (storedLayoutData) {
            // If data is present, parse and return it
            return JSON.parse(storedLayoutData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    useEffect(() => {
        // Check if layout data is present in localStorage
        const layoutDataFromLocalStorage = getLayoutDataFromLocalStorage();

        if (layoutDataFromLocalStorage) {
            // If data is present, use it directly
            setLayout(layoutDataFromLocalStorage);
            setColor(layoutDataFromLocalStorage[0]?.client);
            setColor(layoutDataFromLocalStorage[0]?.client);
        } else {
            // If data is not present, fetch it
            fetchLayout();
        }

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    useEffect(() => {
        setColor(layout[0]?.client);
        document.documentElement.style.setProperty(
            "--client_main_color",
            color
        );
        document.documentElement.style.setProperty(
            "--call_to_action",
            layout[0]?.call_to_action
        );
        document.documentElement.style.setProperty(
            "--chart_main",
            layout[0]?.chart_main
        );
        document.documentElement.style.setProperty(
            "--chart_second",
            layout[0]?.chart_second
        );
        document.documentElement.style.setProperty(
            "--chart_third",
            layout[0]?.chart_third
        );
        document.documentElement.style.setProperty(
            "--chart_fourth",
            layout[0]?.chart_fourth
        );
        document.documentElement.style.setProperty("--fade", layout[0]?.fade);
        document.documentElement.style.setProperty(
            "--notification",
            layout[0]?.notification
        );
        document.documentElement.style.setProperty(
            "--disruption",
            layout[0]?.disruption
        );
        document.documentElement.style.setProperty("--promo", layout[0]?.promo);
        document.documentElement.style.setProperty("--alert", layout[0]?.alert);
        document.documentElement.style.setProperty(
            "--buttonMainBorder",
            layout[0]?.buttonmainborder
        );
        document.documentElement.style.setProperty(
            "--buttonMainBackground",
            layout[0]?.buttonmainbackground
        );
        document.documentElement.style.setProperty(
            "--buttonMainFontColor",
            layout[0]?.buttonmainfontcolor
        );
        document.documentElement.style.setProperty(
            "--buttonMainHoverBorder",
            layout[0]?.buttonmainhoverborder
        );
        document.documentElement.style.setProperty(
            "--buttonMainHoverBackground",
            layout[0]?.buttonmainhoverbackground
        );
        document.documentElement.style.setProperty(
            "--buttonMainHoverFontColor",
            layout[0]?.buttonmainhoverfontcolor
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryBorder",
            layout[0]?.buttonsecondaryborder
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryBackground",
            layout[0]?.buttonsecondarybackground
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryFontColor",
            layout[0]?.buttonsecondaryfontcolor
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryHoverBorder",
            layout[0]?.buttonsecondaryhoverborder
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryHoverBackground",
            layout[0]?.buttonsecondaryhoverbackground
        );
        document.documentElement.style.setProperty(
            "--buttonSecondaryHoverFontColor",
            layout[0]?.buttonsecondaryhoverfontcolor
        );

        // setTest(document.documentElement.style.getPropertyValue('--client_main_color'))
    }, [layout, color]);

    // const color = "#ec0119";
    // const color = "#2e3923";
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            // You can adjust the breakpoint value as needed
            const breakpoint = 1366; // Example breakpoint value

            // Update the collapsed state based on the screen width
            setCollapsed(window.innerWidth <= breakpoint);
        };

        // Initial call to set the initial collapsed state
        handleResize();

        // Attach the event listener to handle window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Emp

    return !(locale == "en" || locale == "fr") ? (
        <div></div>
    ) : (
        // return (
        <div
            className="flex flex-col sticky top-0 z-50 h-screen text-left text-white rounded-r-lg bg-client text-base hidden md:flex font-semibold"
            style={{ backgroundColor: color }}
        >
            <IntlProvider
                locale={locale}
                defaultLocale="en"
                messages={messages[locale]}
            >
                <Sidebar
                    collapsed={collapsed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: color,
                            // borderRight: "10px",
                            borderTopRightRadius: "7px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <div className="pl-5 pt-10 pr-5 flex flex-col items-center h-100 mt-10">
                        {!collapsed && (
                            <div>
                                <img src={layout[0]?.logo} width="150"></img>
                            </div>
                        )}
                        {collapsed && (
                            <div
                                className="flex flex-col items-center"
                                onClick={() => navigate("/profile")}
                            >
                                <div className="w-25 h-25 overflow-hidden rounded-full border-2 border-buttonSecondaryBackground">
                                    <img
                                        src="https://img.freepik.com/vecteurs-premium/avatar-icon002_750950-52.jpg"
                                        width="30"
                                        className=""
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Menu
                        menuItemStyles={{
                            button: {
                                // the active class will be added automatically by react router
                                // so we can use it to style the active menu item
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: "#666666",
                                },
                                "&:hover": {
                                    backgroundColor: "#666666",
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        <MenuItem
                            icon={<FiHome />}
                            component={<Link to="/home" />}
                        >
                            {" "}
                            {/* Home{" "} */}
                            <FormattedMessage
                                id="navigator.home"
                                values={{ number: 1 }}
                            />
                        </MenuItem>

                        {user.routemodule && (
                            <MenuItem
                                icon={<FiCalendar />}
                                component={<Link to="/routing" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.myDay"
                                        values={{ number: 1 }}
                                    />
                                    {leadNotification != 0 && (
                                        <div className="bg-notification rounded-full ml-5 font-semibold">
                                            <div
                                                style={{
                                                    width: "7px",
                                                    height: "7px",
                                                    backgroundColor: "#26C5CC",
                                                    borderRadius: "50%",
                                                }}
                                            ></div>
                                        </div>
                                    )}
                                </div>
                            </MenuItem>
                        )}
                        {user.clientModule && (
                            <MenuItem
                                icon={<FiUsers />}
                                component={<Link to="/clients" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.Clients"
                                        values={{ number: 1 }}
                                    />
                                    {recommendationNotification != 0 && (
                                        <div className="bg-notification rounded-full ml-5 font-semibold">
                                            <div
                                                style={{
                                                    width: "7px",
                                                    height: "7px",
                                                    backgroundColor: "#26C5CC",
                                                    borderRadius: "50%",
                                                }}
                                            ></div>
                                        </div>
                                    )}
                                </div>
                            </MenuItem>
                        )}
                        {user.leadModule && (
                            <MenuItem
                                icon={<FiFilter />}
                                component={<Link to="/leads" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.Leads"
                                        values={{ number: 1 }}
                                    />
                                    {leadNotification != 0 && (
                                        <div className="bg-notification rounded-full ml-5 font-semibold">
                                            {leadNotification && (
                                                <div
                                                    style={{
                                                        width: "7px",
                                                        height: "7px",
                                                        backgroundColor:
                                                            "#26C5CC",
                                                        borderRadius: "50%",
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </MenuItem>
                        )}
                        {user.statsModule && (
                            <SubMenu
                                defaultOpen
                                icon={<CgPerformance />}
                                // label="Tableaux de bord"
                                label={
                                    <FormattedMessage
                                        id="navigator.Stats"
                                        defaultMessage="Tableaux de bord"
                                    />
                                }
                                rootStyles={{
                                    ["& > ." + menuClasses.button]: {
                                        backgroundColor: color,
                                        color: "#9f0099",
                                        "&:hover": {
                                            backgroundColor: "#eecef9",
                                        },
                                    },
                                    ["." + menuClasses.subMenuContent]: {
                                        backgroundColor: color,
                                    },
                                }}
                            >
                                {user.manager && (
                                    <MenuItem
                                        component={<Link to="/dashboard" />}
                                        icon={<VscBlank />}
                                    >
                                        <FormattedMessage
                                            id="navigator.Management"
                                            values={{ number: 1 }}
                                        />
                                    </MenuItem>
                                )}
                                {user.trainingModule && (
                                    <MenuItem
                                        component={<Link to="/profile" />}
                                        icon={<VscBlank />}
                                    >
                                        <FormattedMessage
                                            id="navigator.Individual"
                                            values={{ number: 1 }}
                                        />
                                    </MenuItem>
                                )}
                            </SubMenu>
                        )}
                        {user.productModule && (
                            <MenuItem
                                icon={<FiBook />}
                                component={<Link to="/products" />}
                            >
                                <FormattedMessage
                                    id="navigator.Product"
                                    values={{ number: 1 }}
                                />
                            </MenuItem>
                        )}
                        {user.trainingModule && (
                            <MenuItem
                                icon={<FaGraduationCap />}
                                component={<Link to="/training" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.Training"
                                        values={{ number: 1 }}
                                    />
                                    <div className="bg-notification rounded-full ml-5 font-semibold">
                                        <div
                                            style={{
                                                width: "7px",
                                                height: "7px",
                                                backgroundColor: "",
                                                borderRadius: "50%",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </MenuItem>
                        )}
                        {/* {user.trainingModule && (
                            <MenuItem
                                icon={<FiShoppingCart />}
                                component={<Link to="/orders" />}
                            >
                                <div className="flex flex-row items-center text-sm">
                                    <FormattedMessage
                                        id="navigator.Orders"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </MenuItem>
                        )} */}
                    </Menu>
                </Sidebar>
                <div className="flex-grow"></div>
                <Sidebar
                    collapsed={collapsed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: color,
                            borderBottomRightRadius: "7px",
                            borderRight: "0px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <Menu
                        menuItemStyles={{
                            button: {
                                // the active class will be added automatically by react router
                                // so we can use it to style the active menu item
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: color,
                                },
                                "&:hover": {
                                    backgroundColor: "#666666",
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        <MenuItem
                            icon={<FiTool />}
                            component={<Link to="/settings" />}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.Settings"
                                values={{ number: 1 }}
                            />
                        </MenuItem>
                        <MenuItem
                            icon={<FiLogOut />}
                            onClick={() => logout_auth0()}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.Logout"
                                values={{ number: 1 }}
                            />
                            {/* {isLoggedIn}{" "} */}
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </IntlProvider>
        </div>
    );
}

export default Navigator;
