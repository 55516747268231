import config from './config';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

const axios = require('axios');

class FastAPIClient {
  constructor(overrides) {
    this.config = {
      ...config,
      ...overrides,
    };
    this.authToken = config.authToken;
    // this.authToken = {'token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2V4YW1wbGUuY29tL2VtYWlsIjoiZGVtb0BteXdheS5jb20iLCJ0ZW5hbnQiOiJteXdheSIsImlzcyI6Imh0dHBzOi8vZGV2LW15d2F5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NDlhZTAyYTMyMDRkMTQ1NWE0ZmRhMjciLCJhdWQiOlsiaHR0cHM6Ly93d3cuYXBpLm15d2F5LnRlY2hub2xvZ3kiXSwiaWF0IjoxNzEwMjUwNjIyLCJleHAiOjE3MTAzMzcwMjIsImF6cCI6IktLYk9aSU1lT2s1NkRPeE5wbndZQ2FFdndobm5vWmpSIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSByZWFkOmFwcG9pbnRtZW50cyIsInBlcm1pc3Npb25zIjpbInJlYWQ6YXBwb2ludG1lbnRzIl19.I24Z0yNIGtLoJgzd3duV7a971p7MdV5_bdTjzLD--I4'}

    this.login = this.login.bind(this);
    this.apiClient = this.getApiClient(this.config);
  }

  /* ----- Authentication & User Operations ----- */

  /* Authenticate the user with the backend services.
	 * The same JWT should be valid for both the api and cms */
  login(username, password) {
    delete this.apiClient.defaults.headers['Authorization'];

    // HACK: This is a hack for scenario where there is no login form
    const form_data = new FormData();
    const grant_type = 'password';
    const item = {grant_type, username, password};
    for (const key in item) {
      form_data.append(key, item[key]);
    }

    return this.apiClient
        .post('/auth/login', form_data)
        .then((resp) => {
          localStorage.setItem('token', JSON.stringify(resp.data));
          return this.fetchUserName();
        });
  }

  fetchUser() {
    return this.apiClient.get('/auth/me').then(({data}) => {
      localStorage.setItem('user', JSON.stringify(data));
      return data;
    });
  }

  fetchUserName() {
    return this.apiClient.get('/auth/me').then(({data}) => {
      localStorage.setItem('userfirstname', JSON.stringify(data["first_name"]).replace(/['"]+/g, ''));
      return data;
    });
  }

  register(email, password, first_name, surname) {
    const loginData = {
      email,
      password,
      first_name,
      surname,
      is_active: true,
    };

    return this.apiClient.post('/auth/signup', loginData).then(
        (resp) => {
          return resp.data;
        });
  }

  // Logging out is just deleting the jwt.
  logout() {
    // Add here any other data that needs to be deleted from local storage
    // on logout
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('layoutData');
    localStorage.removeItem('language');
  }

  /* ----- Client Configuration ----- */

  /* Create Axios client instance pointing at the REST api backend */
  getApiClient(config) {
    const initialConfig = {
      baseURL: `${config.apiBasePath}/api/v1`,
    };
    const client = axios.create(initialConfig);
    console.log("Getting the API client")
    // client.interceptors.request.use(localStorageTokenInterceptor);
    // Add the interceptor to Axios
    client.interceptors.request.use(
      config => {
        return localStorageTokenInterceptor(config);
      },
      error => {
        return Promise.reject(error);
      }
    );
    return client;
  }

  getProduct(productId) {
    return this.apiClient.get(`/product/${productId}`).then(({data}) => {
      return data;
    });
  }

  getProducts(clientId) {
    return this.apiClient.get(`/product/search/?client_id=${clientId}`).then(({data}) => {
      return data;
    });
  }

  getAllProducts() {
    return this.apiClient.get(`/product/product_list/`).then(({data}) => {
      return data;
    });
  }

  getRecommendations(clientId) {
    return this.apiClient.get(`/recommendation/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getProductSales(clientId) {
    return this.apiClient.get(`/productsales/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getLeads(clientId) {
    return this.apiClient.get(`/leads/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getReminder(clientId) {
    return this.apiClient.get(`/reminder/${clientId}`).then(({data}) => {
      return data;
    });
  }

  updateLead(leadId, leadUpdate) {
    return this.apiClient.put(`/leads/${leadId}`, leadUpdate)
  }

  updateRecommendation(recommendationId, recommendationupdate) {
    return this.apiClient.put(`/recommendation/${recommendationId}`,recommendationupdate)
  }


  getClients(keyword) {
    return this.apiClient.get(`/clients/search/?keyword=${keyword}&max_results=10`).then(({data}) => {
      return data;
    });
  }

  getClient(id) {
    return this.apiClient.get(`/clients/${id}`).then(({data}) => {
      return data;
    });
  }

  getClientHistory(id) {
    return this.apiClient.get(`/clients/history/?id=${id}`).then(({data}) => {
      return data;
    });
  }

  getLayout() {
    return this.apiClient.get(`/layout/my-layout/`).then(({data}) => {
      return data;
    });
  }

  getUserClients() {
    return this.apiClient.get(`/clients/my-clients/`).then(({data}) => {
      return data;
    });
  }

  getCharts() {
    return this.apiClient.get(`/charts/`).then(({data}) => {
      return data;
    });
  }

  resetNBA(recommendationId) {
    return this.apiClient.put(`/recommendation/reset/${recommendationId}`)
  }

  resetLeads() {
    return this.apiClient.put(`/leads/reset/`)
  }

  resetClients() {
    return this.apiClient.put(`/clients/reset/`)
  }

  createClient(label, url, source, submitter_id) {
    const clientData = {
      label,
      url,
      source,
      submitter_id: submitter_id,
    };
    return this.apiClient.post(`/clients/`, clientData);
  }

  updateAlert(recommendationupdate) {
    return this.apiClient.put(`/clients/`,recommendationupdate)
  }

  getLeadNotifications() {
    return this.apiClient.get(`/leads/lead_count/`).then(({data}) => {
      return data;
    });
  }

  getRecommendationNotifications() {
    return this.apiClient.get(`/recommendation/notification_count/`).then(({data}) => {
      return data;
    });
  }

  getRecommendationValue() {
    return this.apiClient.get(`/recommendation/recommendation_value/`).then(({data}) => {
      return data;
    });
  }
}


// every request is intercepted and has auth header injected.
// function localStorageTokenInterceptor(config) {
//   const headers = {};
//   var tokenData = localStorage.getItem('@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments');

//   // Check if tokenData is empty or null
//   if (tokenData == null || tokenData == undefined) {
//     // Wait for 3 seconds before retrying
//     console.log("Token data is null or undefined")
//     setTimeout(() => {
//         // Call the function again recursively
//         console.log("trying again")
//         tokenData = localStorage.getItem('@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments');
//     }, 10000); // 3000 milliseconds = 3 seconds
//   } else {
//       // Token data is not empty, continue with your logic here
//       console.log(tokenData);
//       // You can place your code here that relies on tokenData
//   }

//   // Parse the JSON string to an object
//   const tokenObject = JSON.parse(tokenData);

//   console.log("This is the token")
//   console.log(tokenObject)
//   console.log(tokenObject.body.access_token)

//   const token = {"access_token":"","token_type":"bearer"}
//   console.log("This was the token")
//   token.access_token = tokenObject.body.access_token

//   if (token) {
//     // const token = JSON.parse(tokenString);
//     const decodedAccessToken = jwtDecode(token.access_token);
//     const isAccessTokenValid =
// 			moment.unix(decodedAccessToken.exp).toDate() > new Date();
//     if (isAccessTokenValid) {
//       headers['Authorization'] = `Bearer ${token.access_token}`;
//     } else {
//       alert('Your login session has expired');
//     }
//   }
//   config['headers'] = headers;
//   return config;
// }

// Modify your localStorageTokenInterceptor function to work with Axios interceptors
function localStorageTokenInterceptor(request) {
  return new Promise((resolve) => {

    // Logging all elements in localStorage
    console.log('Local storage length')
    console.log(localStorage.length)
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      console.log(`localStorage[${key}] = ${value}`);
    }

    const headers = {};
    var tokenData_web = localStorage.getItem('@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments');
    var tokenData = localStorage.getItem('token');

    // Check if tokenData is empty or null
    if ((tokenData == null || tokenData == undefined) && (tokenData_web == null || tokenData_web == undefined)) {
      // Wait for 3 seconds before retrying

      setTimeout(() => {
        // Call the function again recursively
        console.log("trying again")
        localStorageTokenInterceptor(request).then(resolve); // Call recursively after timeout
      }, 1000); // 10000 milliseconds = 10 seconds
    } else {
      // Token data is not empty, continue with your logic here

      // You can place your code here that relies on tokenData

      // Parse the JSON string to an object
      // const tokenObject = JSON.parse(tokenData);
      // console.log('this is the tokenobject')
      // console.log(tokenObject)

      console.log('HAAAAALLLLLLLLOOOOOOOO')


      const token = {"access_token":"","token_type":"bearer"}
      // const token = {"access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2V4YW1wbGUuY29tL2VtYWlsIjoiZGVtb0BteXdheS5jb20iLCJ0ZW5hbnQiOiJteXdheSIsIm1haWwiOiJkZW1vQG15d2F5LmNvbSIsInRlbmFudF9iYWNrdXAiOiJteXdheSIsImlzcyI6Imh0dHBzOi8vZGV2LW15d2F5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NDlhZTAyYTMyMDRkMTQ1NWE0ZmRhMjciLCJhdWQiOlsiaHR0cHM6Ly93d3cuYXBpLm15d2F5LnRlY2hub2xvZ3kiXSwiaWF0IjoxNzEwODc0MDEzLCJleHAiOjE3MTA5NjA0MTMsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgcmVhZDphcHBvaW50bWVudHMiLCJhenAiOiJLS2JPWklNZU9rNTZET3hOcG53WUNhRXZ3aG5ub1pqUiIsInBlcm1pc3Npb25zIjpbInJlYWQ6YXBwb2ludG1lbnRzIl19.r2YcA-3RRgO5KtAvlyEkKtze3d2R-nDNaQrEZ16QAL0","token_type":"bearer"}

      if (tokenData_web == null || tokenData_web == undefined){

        token.access_token = tokenData
      } else {
        const tokenObject = JSON.parse(tokenData_web);
        token.access_token = tokenObject.body.access_token

      }
      // token.access_token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2V4YW1wbGUuY29tL2VtYWlsIjoiZGVtb0BteXdheS5jb20iLCJ0ZW5hbnQiOiJteXdheSIsIm1haWwiOiJkZW1vQG15d2F5LmNvbSIsInRlbmFudF9iYWNrdXAiOiJteXdheSIsImlzcyI6Imh0dHBzOi8vZGV2LW15d2F5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NDlhZTAyYTMyMDRkMTQ1NWE0ZmRhMjciLCJhdWQiOlsiaHR0cHM6Ly93d3cuYXBpLm15d2F5LnRlY2hub2xvZ3kiXSwiaWF0IjoxNzEwODc0MDEzLCJleHAiOjE3MTA5NjA0MTMsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgcmVhZDphcHBvaW50bWVudHMiLCJhenAiOiJLS2JPWklNZU9rNTZET3hOcG53WUNhRXZ3aG5ub1pqUiIsInBlcm1pc3Npb25zIjpbInJlYWQ6YXBwb2ludG1lbnRzIl19.r2YcA-3RRgO5KtAvlyEkKtze3d2R-nDNaQrEZ16QAL0"


      if (token) {
        // const token = JSON.parse(tokenString);
        const decodedAccessToken = jwtDecode(token.access_token);
        const isAccessTokenValid =
          moment.unix(decodedAccessToken.exp).toDate() > new Date();
        if (isAccessTokenValid) {
          headers['Authorization'] = `Bearer ${token.access_token}`;
        } else {
          console.log('expired')
        }
      }
      request.headers = headers;
      resolve(request); // Resolve the promise with the modified request object
    }
  });
}


export default FastAPIClient;
