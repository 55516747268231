/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navigator from "../../components/Navigator";
import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

const Orders = () => {
    const { isLoggedIn } = useAuthentication();
    const [refreshing, setRefreshing] = useState(true);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        orderNumber: "",
        clientName: "",
        status: "",
    });
    const [sortBy, setSortBy] = useState("deliveryDate"); // Default sorting by delivery date
    const [sortOrder, setSortOrder] = useState("asc");
    const navigate = useNavigate();

    useEffect(() => {
        // Simulating an API call to fetch orders
        // Replace this with your actual data fetching logic
        setTimeout(() => {
            setOrders([
                {
                    ref: "#001",
                    client: "LA MARE AUX CANARDS",
                    status: "on track",
                    deliveryDate: "2024-02-01",
                    value: 500,
                },
                {
                    ref: "#002",
                    client: "POTEL ET CHABOT",
                    status: "delay",
                    deliveryDate: "2024-02-03",
                    value: 700,
                },
                {
                    ref: "#003",
                    client: "BB RESTAURANT",
                    status: "delay",
                    deliveryDate: "2024-02-03",
                    value: 700,
                },
                {
                    ref: "#004",
                    client: "LA MARE AUX CANARDS",
                    status: "delay",
                    deliveryDate: "2024-02-03",
                    value: 700,
                },
                {
                    ref: "#005",
                    client: "BB RESTAURANT",
                    status: "disruption",
                    deliveryDate: "2024-02-03",
                    value: 700,
                },
                {
                    ref: "#006",
                    client: "LA MARE AUX CANARDS",
                    status: "on track",
                    deliveryDate: "2024-02-03",
                    value: 700,
                },
                // ... (Add more orders as needed)
            ]);
            setRefreshing(false);
        }, 0); // Simulating a delay of 2 seconds (replace with actual data fetching logic)
    }, []);

    useEffect(() => {
        // Apply filtering based on filter criteria
        const filtered = orders.filter((order) => {
            const orderNumberMatch = order.ref
                .toLowerCase()
                .includes(filterCriteria.orderNumber.toLowerCase());
            const clientNameMatch = order.client
                .toLowerCase()
                .includes(filterCriteria.clientName.toLowerCase());
            const statusMatch = order.status
                .toLowerCase()
                .includes(filterCriteria.status.toLowerCase());

            return orderNumberMatch && clientNameMatch && statusMatch;
        });

        // Apply sorting based on sortBy and sortOrder
        const sorted = filtered.sort((a, b) => {
            if (sortOrder === "asc") {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        setFilteredOrders(sorted);
    }, [filterCriteria, sortBy, sortOrder, orders]);

    const handleFilterChange = (field, value) => {
        setFilterCriteria({
            ...filterCriteria,
            [field]: value,
        });
    };

    const handleSortChange = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortBy(field);
            setSortOrder("asc");
        }
    };

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    if (!isLoggedIn) {
        // Redirect to another page if the user is not logged in
        return <Navigate to="/" />;
    }

    const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;

    return (
        <section className="flex flex-row bg-white w-full h-full">
            <div className="z-10 border-r-1 border-white">
                <Navigator />
            </div>
            <div className="flex flex-col h-full w-full">
                <Header />
                {/* <div className="flex flex-col md:flex-row items-center md:mt-20 ">
                    <div>
                        <h1 className="mb-5 md:mb-12 text-xl text-black mt-5 md:ml-20">
                            Order overview
                        </h1>{" "}
                    </div>
                    <div className="flex flex-col md:flex-row md:ml-20 items-center justify-center md:mb-10">
                        <input
                            type="text"
                            className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction
      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500 invalid:text-pink-600
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                            style={{ minWidth: 350 }}
                            placeholder="Search Order..."
                        />
                        <a className={buttonStyle}>Search Order</a>
                    </div>
                </div> */}
                <section className="md:w-4/5 h-full flex flex-col p-4 md:ml-20 md:mt-20">
                    <div className="flex flex-col md:flex-row md:items-center">
                        <div className="flex md:w-1/4">
                            <h1 className=" md:ml-0 md:mb-12 text-xl text-black md:text-left">
                                Order overview
                            </h1>{" "}
                        </div>
                        <div className="flex flex-col md:flex-row mr-5 md:mr-20 xl:ml-20 mt-5 items-center justify-center mb-5 md:mb-20 ">
                            <input
                                type="text"
                                className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction
          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
          invalid:border-pink-500 invalid:text-pink-600
          focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                                style={{ minWidth: 350 }}
                                placeholder="Search order..."
                            />
                            <a className={buttonStyle}>Search order</a>
                        </div>
                    </div>
                    <div className="flex flex-row mb-4 rounded-md md:pl-10 md:pr-10 text-gray-500 font-semibold text-sm text-left">
                        <div className="w-1/12">Order</div>
                        <div className="w-1/3">Client</div>
                        <div className="w-1/6">Status</div>
                        <div className="w-1/6">Delivery Date</div>
                        <div className="w-1/6">Value</div>
                        <div className="w-1/12 md:w-1/6"></div>
                    </div>
                    {filteredOrders.map((order) => (
                        <div
                            key={order.ref}
                            className="flex flex-col mb-4 border-2 broder-gray-200 rounded-md md:text-base md:pl-10 md:pr-10 pt-2 pb-2"
                        >
                            <div className="flex flex-row text-left text-xs md:text-sm items-center">
                                <div className="w-1/12 font-semibold ml-1">{`${order.ref}`}</div>
                                <div className="w-1/3 pl-2">{`${order.client}`}</div>
                                <div className="w-1/6 mr-2">
                                    <div
                                        className="w-full md:w-1/2 rounded-full text-center"
                                        style={{
                                            background:
                                                getStatusBackgroundColor(
                                                    order.status
                                                ),
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: getStatusColor(
                                                    order.status
                                                ),
                                            }}
                                            className="text-xxs md:text-xxs lg:text-xxs"
                                        >
                                            {order.status}
                                        </span>
                                    </div>
                                </div>

                                <div className="w-1/6">
                                    {order.deliveryDate}
                                </div>
                                <div className="w-1/6 ml-2">€{order.value}</div>
                                <button
                                    className=" w-1/12 md:w-1/6 mr-1 expand-btn bg-buttonSecondaryBackground rounded-full text-buttonSecondaryFontColor border border-buttonSecondaryBorder font-semibold p-1 pl-2 pr-2 text-xs"
                                    onClick={() =>
                                        toggleOrderDetails(order.ref)
                                    }
                                >
                                    <span className="hidden md:inline">
                                        Details
                                    </span>
                                    {/* Show ">" for smaller screens */}
                                    <span className="inline md:hidden">
                                        &gt;
                                    </span>
                                </button>
                            </div>
                            <div
                                id={`order-details-${order.ref}`}
                                className="order-details border-t-2 border-grey-200 mt-2"
                                style={{ display: "none" }}
                            >
                                <div className="flex flex-row mb-4 rounded-md text-left pt-2 text-sm">
                                    <div className="w-1/5"></div>
                                    <div className="w-1/5">Product Name</div>
                                    <div className="w-1/5">Product ID</div>
                                    <div className="w-1/5">Quantity</div>
                                    <div className="w-1/5">Value</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="w-full flex flex-row mt-10">
                        <div className="w-4/5"></div>
                        <div
                            onClick={() => navigate("/orderform")}
                            className="w-1/5 bg-buttonMainBackground text-sm md:text-base rounded-full text-buttonMainFontColor font-semibold w-1/3 md:w-1/6 pt-2 mr-5 md:mr-20 pb-2 drop-shadow-lg "
                        >
                            + New order
                        </div>
                    </div>
                </section>
                <div className="flex-grow"></div>
                <Footer />
            </div>
        </section>
    );
};

// Function to toggle order details visibility
const toggleOrderDetails = (orderRef) => {
    const orderDetails = document.getElementById(`order-details-${orderRef}`);
    if (orderDetails) {
        orderDetails.style.display =
            orderDetails.style.display === "none" ? "block" : "none";
    }
};

// Function to get color based on order status
const getStatusColor = (status) => {
    switch (status) {
        case "disruption":
            return "#e11d48";
        case "on track":
            return "#10b981";
        case "in preparation":
            return "grey";
        case "delay":
            return "#f59e0b";
        default:
            return "black";
    }
};

// Function to get color based on order status
const getStatusBackgroundColor = (status) => {
    switch (status) {
        case "disruption":
            return "#fda4af";
        case "on track":
            return "#a7f3d0";
        case "in preparation":
            return "grey";
        case "delay":
            return "#fde68a";
        default:
            return "black";
    }
};

export default Orders;
