import React, { useState } from "react";

const CollapseComponent = ({
    text,
    iconSrc,
    children,
    isOpen: initialIsOpen = true,
}) => {
    const [open, setOpen] = useState(initialIsOpen);

    const toggle = () => {
        setOpen(!open);
    };

    return (
        <div className="overflow-hidden touch-none w-full mb-3">
            <div className="ml-3 mr-3 md:mr-0 border-2 border-gray-100 bg-white rounded-md">
                <div className="flex w-full flex-row pt-2 items-center pb-4">
                    <div className="flex-none float-left ml-4 mt-1 mr-4">
                        <img
                            src={iconSrc}
                            alt="icon"
                            height="25px"
                            width="25px"
                        />
                    </div>
                    <div className="text-lg md:text-base xl:text-lg text-black text-left mt-1 w-5/6">
                        {text}
                    </div>
                    <div className="flex-none w-full xl:w-1/6 md:w-1/6 sm:w-1/6 mt-2 w-1/6">
                        <button onClick={toggle}>
                            <img
                                className="mr-5"
                                src="https://cdn-icons-png.flaticon.com/512/60/60781.png"
                                alt="toggle button"
                                width="15px"
                                height="15px"
                            />
                        </button>
                    </div>
                </div>
                {open && <div className="ml-4 mr-4 mb-5">{children}</div>}
            </div>
        </div>
    );
};

export default CollapseComponent;
