/* eslint-disable */
import React, { useState, useEffect } from "react";
// import DashboardHeader from "../../components/DashboardHeader";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import { faker } from "@faker-js/faker";
import jwtDecode from "jwt-decode";
import * as moment from "moment";
import { Navigate } from "react-router-dom";
// import tailwindConfig from "../../tailwind.config";
// import resolveConfig from "tailwindcss/resolveConfig";
// const tailwindConfig = React.lazy(() => import('../../tailwind.config'));
// const resolveConfig = React.lazy(() => import('tailwindcss/resolveConfig'));
// const twFullConfig = resolveConfig(tailwindConfig)

import Loader from "../../components/Loader";

import FastAPIClient from "../../client";
import config from "../../config";
import "./style.css";
// import * as FiIcons from "react-icons/fi";

import ImageBarChart from "../../components/HorizontalChart";

import FranceMap from "../../components/RevenueMap";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const client = new FastAPIClient(config);

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut, Line, Scatter } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

// const training = "container flex flex-row bg-white rounded-md drop-shadow-md mt-10 w-full xl:w-5/6 md:w-5/6"

const ManagementDashboard = () => {
    const [layout, setLayout] = useState([]);
    const [locale, setLocale] = useState();

    const [user, setUser] = useState(false);

    const [refreshing, setRefreshing] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        getUserName();
        setLocale(localStorage.getItem("language"));
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    // faker.seed(42);

    // State variable to hold the selected person's name
    const [selectedPerson, setSelectedPerson] = useState(null);

    // const [nba_data, setNbaData] = useState({
    //     labels: [],
    //     datasets: [
    //         {
    //             label: "Value",
    //             data: [],
    //             backgroundColor: [
    //                 "rgba(214, 168, 92, 255)",
    //                 "rgb(128, 128, 128)",
    //                 "rgba(232, 53, 89)",
    //             ], // Bar color
    //             borderRadius: 15,
    //         },
    //     ],
    // });

    // Handle tooltip display on hover
    const handleTooltip = (tooltipItem) => {
        if (tooltipItem && tooltipItem.length > 0) {
            const dataIndex = tooltipItem[0].dataIndex;
            const person = scatterdata[dataIndex];
            setSelectedPerson(person.name);
        } else {
            setSelectedPerson(null);
        }
    };

    // Generate random data for 10 people
    const generateData = () => {
        const data = [];
        for (let i = 0; i < 50; i++) {
            const person = {
                name: `Person ${i + 1}`,
                x: getRandomValue(),
                y: getRandomValue(),
            };
            data.push(person);
        }
        return data;
    };

    // Generate a random value between 0 and 5
    const getRandomValue = () => {
        return Math.random() * 5;
    };

    const createDataset = (data) => {
        const dataset = {
            data: data.map((person) => ({ x: person.x, y: person.y })),
            // backgroundColor: "rgb(10, 18, 37)",
            backgroundColor: (context) => {
                const { x, y } = context.dataset.data[context.dataIndex];
                // Define your color logic based on x-y coordinates
                if (x > 2.5 && y > 2.5) {
                    return "#14b8a6";
                } else if (x > 2.5 && y < 2.5) {
                    return "#84cc16";
                } else if (x < 2.5 && y > 2.5) {
                    return "#fb923c";
                } else {
                    return "#f43f5e";
                }
            },
            borderColor: (context) => {
                const { x, y } = context.dataset.data[context.dataIndex];
                // Define your color logic based on x-y coordinates
                if (x > 2.5 && y > 2.5) {
                    return "#14b8a6";
                } else if (x > 2.5 && y < 2.5) {
                    return "#84cc16";
                } else if (x < 2.5 && y > 2.5) {
                    return "#fb923c";
                } else {
                    return "#f43f5e";
                }
            },
            // borderColor: "rgb(10, 18, 37)",
            pointStyle: "circle",
            hoverRadius: 3,
            radius: 3,
        };
        return [dataset];
    };
    const scatterdata = generateData();
    const dataset = createDataset(scatterdata);

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: "Q2 2023",
                },
                min: 0,
                max: 5,
                grid: {
                    display: true, // Remove x-axis gridlines
                },
                ticks: {
                    stepSize: 1, // Set x-axis tick interval to 1
                },
                afterDataLimits: (axis) => {
                    axis.max = 5; // Ensure x-axis maximum is always 5
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: "Q1 2023",
                },
                min: 0,
                max: 5,
                grid: {
                    display: true, // Remove y-axis gridlines
                },
                ticks: {
                    stepSize: 1, // Set y-axis tick interval to 1
                },
                afterDataLimits: (axis) => {
                    axis.max = 5; // Ensure y-axis maximum is always 5
                },
            },
        },
        elements: {
            point: {
                radius: 0.5, // Reduce the size of the dots
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const person = scatterdata[dataIndex];
                        return [person.name, "Q1:", "Q2:"];
                    },
                },
            },
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    fontColor: "#333",
                    usePointStyle: true,
                },
            },
        },
        onHover: handleTooltip,
    };

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchCharts(), fetchLayout()]);
                // Set styles once data is fetched
                document.documentElement.style.setProperty(
                    "--client_main_color",
                    layout[0]?.client
                );
                // setNbaData((prevData) => ({
                //     ...prevData,
                //     labels: chartData[2].labels || [],
                //     datasets: [
                //         {
                //             ...prevData.datasets[0],
                //             data: chartData[2].data || [],
                //         },
                //     ],
                // }));
                // ... Set other styles similarly
            } catch (error) {
                // console.error("Error fetching data:", error);
                // Handle errors if necessary
            }
        };
        fetchData();
        // setNbaData((prevData) => ({
        //     ...prevData,
        //     labels: chartData[2].labels || [],
        //     datasets: [
        //         {
        //             ...prevData.datasets[0],
        //             data: chartData[2].data || [],
        //         },
        //     ],
        // }));
    }, []);

    // declare the data fetching function

    const fetchLayout = () => {
        client.getLayout().then((data) => {
            // setRefreshing(false);
            setLayout(data?.results);
        });
    };

    // const chartDataStr = chartData[2];
    // const chartDataObj = JSON.parse(chartDataStr);

    const fetchCharts = () => {
        client.getCharts().then((data) => {
            // setRefreshing(false);
            setChartData(data?.results);
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setRefreshing(false);
        }, 1500); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer); // Clear the timer on component unmount
    }, [dataset]);

    // Transposing the tbody
    const headers = [];

    // Extracting headers and rows from the JSON data
    const tableData = chartData
        .find((chart_name) => chart_name.chart_name === "product_insights")
        ?.data.map((group, index) => {
            const rowKeys = Object.keys(group).filter(
                (key) => key !== "product_type"
            ); // Exclude "product_type" key

            headers.push(
                <th key={`header${index}`} className="p-2 whitespace-nowrap">
                    {group.product_type === null ? "Other" : group.product_type}
                </th>
            );

            return (
                <tr key={`row${index}`}>
                    {rowKeys.map((key, i) => (
                        <td
                            key={`row${index}-col${i}`}
                            className={`p-2 whitespace-nowrap ${
                                index === 0 ? "font-semibold" : "text-center"
                            } ${
                                typeof group[key] === "string" &&
                                group[key].startsWith &&
                                group[key].endsWith &&
                                group[key].startsWith("-") &&
                                group[key].endsWith("%")
                                    ? "text-rose-500 font-semibold"
                                    : typeof group[key] === "string" &&
                                      group[key].endsWith &&
                                      group[key].endsWith("%")
                                    ? "text-emerald-400 font-semibold"
                                    : ""
                            }`}
                        >
                            {group[key]}
                        </td>
                    ))}
                </tr>
            );
        });

    // Transposing the tbody
    const customer_headers = [];

    // Extracting headers and rows from the JSON data
    const customer_tableData = chartData
        .find((chart_name) => chart_name.chart_name === "customer_insights")
        ?.data.map((group, index) => {
            const rowKeys = Object.keys(group).filter(
                (key) => key !== "client_segment"
            ); // Exclude "product_type" key

            customer_headers.push(
                <th key={`header${index}`} className="p-2 whitespace-nowrap">
                    {group.client_segment === null
                        ? "Other"
                        : group.client_segment}
                </th>
            );

            return (
                <tr key={`row${index}`}>
                    {rowKeys.map((key, i) => (
                        <td
                            key={`row${index}-col${i}`}
                            className={`p-2 whitespace-nowrap ${
                                index === 0 ? "font-semibold" : "text-center"
                            } ${
                                typeof group[key] === "string" &&
                                group[key].startsWith &&
                                group[key].endsWith &&
                                group[key].startsWith("-") &&
                                group[key].endsWith("%")
                                    ? "text-rose-500 font-semibold"
                                    : typeof group[key] === "string" &&
                                      group[key].endsWith &&
                                      group[key].endsWith("%")
                                    ? "text-rose-500 font-semibold"
                                    : ""
                            }`}
                        >
                            {group[key]}
                        </td>
                    ))}
                </tr>
            );
        });

    // const labels = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "November",
    //     "December",
    // ];

    const labels = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Novembre",
        "Décembre",
    ];

    const data1 = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "monthly_sales_data"
        )?.data.labels,
        datasets: [
            {
                label: "Actual",
                data: chartData.find(
                    (chart_name) =>
                        chart_name.chart_name === "monthly_sales_data"
                )?.data.datasets,
                backgroundColor: "rgba(85,85,85,255)",
                color: "rgba(85,85,85,255)",
                borderRadius: 15,
            },
        ],
    };

    const monthlySalesData = chartData.find(
        (chart_name) => chart_name.chart_name === "monthly_sales_data"
    )?.data.datasets;

    const data2 = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "sales_outlook"
        )?.data.labels,
        datasets: [
            // {
            //     label: "Actual",
            //     data: [2, 4, 6, 10, 21, 35, 0, 0, 0, 0, 0],
            //     backgroundColor: "#434343",
            //     borderRadius: 15,
            // },
            {
                // label: "Forecast",
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_outlook"
                )?.data.datasets,
                backgroundColor: "rgba(85,85,85,255)",
                borderRadius: 15,
            },
        ],
    };

    const doughnut_data = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "customer_distribution"
        )?.data.labels,
        datasets: [
            {
                data: chartData.find(
                    (chart_name) =>
                        chart_name.chart_name === "customer_distribution"
                )?.data.data,
                backgroundColor: chartData.find(
                    (chart_name) =>
                        chart_name.chart_name === "customer_distribution"
                )?.data.backgroundColor,
            },
        ],
    };

    const doughnut_data1 = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "sales_target"
        )?.data.labels,
        datasets: [
            {
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_target"
                )?.data.data,
                backgroundColor: ["#bebebe", "rgba(85,85,85,255)"],
                borderColor: ["#bebebe", "rgba(85,85,85,255)"],
                hoverOffset: 1,
                cutout: "75%",
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
            },
            elements: {
                arc: {
                    borderWidth: 10,
                },
            },
        },
    };
    const doughnut_options1 = {
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                text: "Client type",
                fontColor: "rgb(255,255,255)",
            },
            elements: {
                arc: {
                    borderWidth: 20,
                },
                labels: {
                    fontColor: "rgb(255,255,255)",
                },
            },
        },
    };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const stacked_options1 = {
        plugins: {
            legend: {
                display: false,
                position: "right",
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
            labels: {
                fontColor: "rgba(2,117,131,255)",
            },
            borderRadius: 3,
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const categories = chartData.find(
        (chart_name) => chart_name.chart_name === "sales_per_customer_segment"
    )?.data.datasets;

    const stacked_data = {
        labels: chartData.find(
            (chart_name) =>
                chart_name.chart_name === "sales_per_customer_segment"
        )?.data.labels,
        datasets: categories
            ? categories.map((categoryItem) => ({
                  label: categoryItem.label,
                  data: categoryItem.data, // Replace this with the actual data from your API response
                  backgroundColor: categoryItem.backgroundColor, // Use a function to generate random colors or specify predefined colors
                  borderRadius: 15,
              }))
            : [],
    };

    const line_options = {
        responsive: true,
        lineTension: 0.4,
        plugins: {
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Chart.js Line Chart",
            },
        },
        scales: {
            x: {
                stacked: false,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: false,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const bold_rows = ["2", "4", "6"];
    const product_rows = ["4"];

    const customer_groups_en = [
        {
            id: "1",
            row: "Revenue",
            premium: "360.000 €",
            core: "766.000 €",
            local: "986.000 €",
        },
        {
            id: "2",
            row: "Δ Revenue (2022 - YTD)",
            premium: "12%",
            core: "10%",
            local: "5%",
        },
        {
            id: "2",
            row: "Δ Target revenue (2023 - YTD)",
            premium: "10%",
            core: "3%",
            local: "6%",
        },
        {
            id: "3",
            row: "Margin",
            premium: "36.000 €",
            core: "76.000 €",
            local: "98.000 €",
        },
        {
            id: "4",
            row: "Δ Margin (2022)",
            premium: "-8%",
            core: "10%",
            local: "5%",
        },
        {
            id: "5",
            row: "# Clients",
            premium: "36",
            core: "126",
            local: "43",
        },
        {
            id: "6",
            row: "Δ Clients (2022)",
            premium: "3%",
            core: "7%",
            local: "2%",
        },
        {
            id: "7",
            row: "Avg. Basket value",
            premium: "12.000 €",
            core: "5.250 €",
            local: "4.666 €",
        },
        {
            id: "8",
            row: "Avg. Basket size",
            premium: "5",
            core: "8",
            local: "7",
        },
        {
            id: "9",
            row: "Avg. Yearly purchase frequency",
            premium: "3",
            core: "4",
            local: "3",
        },
        {
            id: "10",
            row: "Avg. Yearly revenue generated",
            premium: "35.000 €",
            core: "21.000 €",
            local: "14.000 €",
        },
        {
            id: "11",
            row: "Avg. Tenure",
            premium: "5",
            core: "3",
            local: "6",
        },
        {
            id: "12",
            row: "Top product group",
            premium: "Beer",
            core: "Champagne",
            local: "Wine",
        },
        {
            id: "13",
            row: "# Clients at risk",
            premium: "5 (13%)",
            core: "9 (7%)",
            local: "14 (32%)",
        },
        {
            id: "14",
            row: "NBA value realized",
            premium: "40.000 € (85% NBA accepted)",
            core: "135.000 € (92% NBA accepted)",
            local: "25.000 € (90% NBA accepted)",
        },
        {
            id: "15",
            row: "# Sales reps (coverage)",
            premium: "12",
            core: "18",
            local: "12",
        },
    ];

    const customer_groups_fr = [
        {
            id: "1",
            row: "Chiffre d'affaires",
            premium: "360 000 €",
            core: "766 000 €",
            local: "986 000 €",
        },
        {
            id: "2",
            row: "Δ Chiffre d'affaires (2023 - Cumul annuel)",
            premium: "12%",
            core: "10%",
            local: "5%",
        },
        {
            id: "2",
            row: "Δ Chiffre d'affaires cible (2023 - Cumul annuel)",
            premium: "10%",
            core: "3%",
            local: "6%",
        },
        {
            id: "3",
            row: "Marge",
            premium: "36 000 €",
            core: "76 000 €",
            local: "98 000 €",
        },
        {
            id: "4",
            row: "Δ Marge (2023)",
            premium: "-8%",
            core: "10%",
            local: "5%",
        },
        {
            id: "5",
            row: "# Clients",
            premium: "36",
            core: "126",
            local: "43",
        },
        {
            id: "6",
            row: "Δ Clients (2023)",
            premium: "3%",
            core: "7%",
            local: "2%",
        },
        {
            id: "7",
            row: "Valeur moyenne du panier",
            premium: "12 000 €",
            core: "5 250 €",
            local: "4 666 €",
        },
        {
            id: "8",
            row: "Taille moyenne du panier",
            premium: "5",
            core: "8",
            local: "7",
        },
        {
            id: "9",
            row: "Fréquence d'achat moyenne annuelle",
            premium: "3",
            core: "4",
            local: "3",
        },
        {
            id: "10",
            row: "Revenu annuel moyen généré",
            premium: "35 000 €",
            core: "21 000 €",
            local: "14 000 €",
        },
        {
            id: "11",
            row: "Ancienneté moyenne",
            premium: "5",
            core: "3",
            local: "6",
        },
        {
            id: "12",
            row: "Meilleur groupe de produits",
            premium: "Bière",
            core: "Champagne",
            local: "Vin",
        },
        {
            id: "13",
            row: "# Clients à risque",
            premium: "5 (13%)",
            core: "9 (7%)",
            local: "14 (32%)",
        },
        {
            id: "14",
            row: "Valeur NBA réalisée",
            premium: "40 000 € (85% accepté NBA)",
            core: "135 000 € (92% accepté NBA)",
            local: "25 000 € (90% accepté NBA)",
        },
        {
            id: "15",
            row: "# Représentants des ventes (couverture)",
            premium: "12",
            core: "18",
            local: "12",
        },
    ];

    const customer_groups =
        locale === "en"
            ? customer_groups_en
            : locale === "fr"
            ? customer_groups_fr
            : [];

    const line_labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "November",
        "December",
    ];

    const line_data = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "sales_comparison"
        )?.data.labels,
        datasets: [
            {
                label: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_comparison"
                )?.data.currentYearLabel,
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_comparison"
                )?.data.currentYearData,
                // borderColor: "#e10119",
                // backgroundColor: "#e10119",
                borderColor: "rgba(85,85,85,255)",
                backgroundColor: "rgba(85,85,85,255)",
            },

            {
                label: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_comparison"
                )?.data.previousYearLabel,
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "sales_comparison"
                )?.data.previousYearData,
                borderColor: "#bebebe",
                backgroundColor: "#bebebe",
            },
        ],
    };

    const customers = [
        {
            id: "0",
            rank: "1",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
            name: "Paris Est",
            sales: faker.datatype.number({ min: 100000, max: 1000000 }) + " €",
            margin: faker.datatype.number({ min: 10000, max: 100000 }) + " €",
            clients: faker.datatype.number({ min: 0, max: 200 }),
            basket_value:
                faker.datatype.number({ min: 1000, max: 10000 }) + " €",
            purchase_frequency: faker.datatype.number({ min: 2, max: 15 }),
            conversion_rate: faker.datatype.number({ min: 10, max: 30 }) + "%",
            customer_churn: faker.datatype.number({ min: 10, max: 40 }) + "%",
            price_diff: faker.datatype.number({ min: -5, max: 3 }),
        },
        {
            id: "1",
            rank: "2",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
            name: "Paris Ouest",
            sales: faker.datatype.number({ min: 100000, max: 1000000 }) + " €",
            margin: faker.datatype.number({ min: 10000, max: 100000 }) + " €",
            clients: faker.datatype.number({ min: 0, max: 200 }),
            basket_value:
                faker.datatype.number({ min: 1000, max: 10000 }) + " €",
            purchase_frequency: faker.datatype.number({ min: 2, max: 15 }),
            conversion_rate: faker.datatype.number({ min: 10, max: 30 }) + "%",
            customer_churn: faker.datatype.number({ min: 10, max: 40 }) + "%",
            price_diff: faker.datatype.number({ min: -5, max: 3 }),
        },
        {
            id: "2",
            rank: "3",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
            name: "Bretagne",
            sales: faker.datatype.number({ min: 100000, max: 1000000 }) + " €",
            margin: faker.datatype.number({ min: 10000, max: 100000 }) + " €",
            clients: faker.datatype.number({ min: 0, max: 200 }),
            basket_value:
                faker.datatype.number({ min: 1000, max: 10000 }) + " €",
            purchase_frequency: faker.datatype.number({ min: 2, max: 15 }),
            conversion_rate: faker.datatype.number({ min: 10, max: 30 }) + "%",
            customer_churn: faker.datatype.number({ min: 10, max: 40 }) + "%",
            price_diff: faker.datatype.number({ min: -5, max: 3 }),
        },
        {
            id: "3",
            rank: "4",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
            name: "Sud Ouest",
            sales: faker.datatype.number({ min: 100000, max: 1000000 }) + " €",
            margin: faker.datatype.number({ min: 10000, max: 100000 }) + " €",
            clients: faker.datatype.number({ min: 0, max: 200 }),
            basket_value:
                faker.datatype.number({ min: 1000, max: 10000 }) + " €",
            purchase_frequency: faker.datatype.number({ min: 2, max: 15 }),
            conversion_rate: faker.datatype.number({ min: 10, max: 30 }) + "%",
            customer_churn: faker.datatype.number({ min: 10, max: 40 }) + "%",
            price_diff: faker.datatype.number({ min: -5, max: 3 }),
        },
    ];

    const product_data = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "nba_summary"
        )?.data.labels,
        datasets: [
            {
                label: "Value",
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "nba_summary"
                )?.data.data,
                backgroundColor: "#434343", // Bar color
                borderRadius: 15,
            },
        ],
    };

    const product_options = {
        indexAxis: "y",
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const nba_data = {
        labels: chartData.find(
            (chart_name) => chart_name.chart_name === "nba_insights"
        )?.data.labels,
        datasets: [
            {
                label: "Value",
                data: chartData.find(
                    (chart_name) => chart_name.chart_name === "nba_insights"
                )?.data.data,
                backgroundColor: [
                    "#434343",
                    "rgba(214, 168, 92,255)",
                    "#e10119",
                ], // Bar color
                borderRadius: 15,
            },
        ],
    };

    const nba_options = {
        indexAxis: "x",
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    // const stacked_labels = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "November",
    //     "December",
    // ];

    const stacked_labels = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];

    const stacked_data2 = {
        labels,
        datasets: [
            {
                label: "Dry food",
                data: stacked_labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "#ed7185",
                borderRadius: 15,
            },
            // {
            //     label: "Hotel",
            //     data: stacked_labels.map(() =>
            //         faker.datatype.number({ min: 0, max: 1000 })
            //     ),
            //     backgroundColor: "rgb(101,115,126)",
            //     borderRadius: 15,
            // },
            {
                label: "Wet food",
                data: stacked_labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "#e10119",
                borderRadius: 15,
            },
        ],
    };

    const doughnut_data2 = {
        labels: ["Dry food", "Wet food"],
        datasets: [
            {
                data: [300, 50],
                backgroundColor: ["rgb(192,192,192)", "rgba(79,91,102,255)"],
            },
        ],
    };

    const product_groups_en = [
        {
            id: "1",
            row: "# Orders (YTD)",
            premium: "345",
            core: "243",
            local: "456",
        },
        {
            id: "2",
            row: "Revenue (YTD)",
            premium: "56.328 €",
            core: "54.899 €",
            local: "43.279 €",
        },
        {
            id: "3",
            row: "Target (YTD)",
            premium: "46.372 €",
            core: "42.353 €",
            local: "32.45 0€",
        },
        {
            id: "4",
            row: "Δ Revenue target",
            premium: "22%",
            core: "30%",
            local: "40%",
        },
        {
            id: "5",
            row: "Top client category in volume",
            premium: "Core",
            core: "Premium",
            local: "Local",
        },
        {
            id: "5",
            row: "Top client category in revenue",
            premium: "Core",
            core: "Core",
            local: "Premium",
        },
        {
            id: "6",
            row: "Top products",
            premium: "Vodka Grey Goose",
            core: "Citadelle Gin",
            local: "La Seigneurie",
        },
    ];

    const product_groups_fr = [
        {
            id: "1",
            row: "# Commandes (Cumul annuel)",
            premium: "345",
            core: "243",
            local: "456",
        },
        {
            id: "2",
            row: "Chiffre d'affaires (Cumul annuel)",
            premium: "56 328 €",
            core: "54 899 €",
            local: "43 279 €",
        },
        {
            id: "3",
            row: "Objectif (Cumul annuel)",
            premium: "46 372 €",
            core: "42 353 €",
            local: "32 450 €",
        },
        {
            id: "4",
            row: "Δ Objectif de chiffre d'affaires",
            premium: "22%",
            core: "30%",
            local: "5%",
        },
        {
            id: "5",
            row: "Catégorie de client la plus importante en volume",
            premium: "Traditionnel",
            core: "Premium",
            local: "Local",
        },
        {
            id: "5",
            row: "Catégorie de client la plus importante en chiffre d'affaires",
            premium: "Traditionnel",
            core: "Traditionnel",
            local: "Premium",
        },
        {
            id: "6",
            row: "Meilleurs produits",
            premium: "Vodka Grey Goose",
            core: "Citadelle Gin",
            local: "Fevertree",
        },
    ];

    const product_groups =
        locale === "en"
            ? product_groups_en
            : locale === "fr"
            ? product_groups_fr
            : [];

    // const display_content = "B";

    const [recommendationValue, setRecommendationValue] = useState([]);

    useEffect(() => {
        fetchRecommendationValue();
    }, []);

    const fetchRecommendationValue = () => {
        client.getRecommendationValue().then((data) => {
            // const sortedResults = data?.results.sort((a, b) => {
            //     // Move objects with 'alert' as true to the beginning
            //     return b.alert - a.alert; // b.alert - a.alert will move true values to the top
            // });
            setRecommendationValue(data);
        });
    };

    // const [refreshing, setRefreshing] = useState(true);
    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         } else {
    //             setIsLoggedIn(false); // User token has expired or is not valid
    //             setRefreshing(false);
    //         }
    //     } else {
    //         setIsLoggedIn(false); // User token does not exist
    //         setRefreshing(false);
    //     }
    // }, []);

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <section
                    className="flex flex-col bg-white text-center w-full items-center pl-10"
                    // style={{ minHeight: "100vh" }}
                    style={{
                        // flex: 1,
                        // paddingBottom: "10px",
                        overflowY: "auto",
                    }}
                >
                    <div className="container flex flex-col bg-white rounded-md mt-10 pr-10">
                        <div className="flex flex-row divide-x-2 mt-5 mb-3 divide-white text-black items-center border-2 rounded-md border-gray-100 dropshadow-md">
                            <div className="flex flex-col w-1/3 text-left pl-20">
                                <div className="flex flex-row items-center mb-5 text-left ">
                                    <img
                                        className=""
                                        src={require("./icons8-add-user-male-100 (1).png")}
                                        height="50"
                                        width="50"
                                    />
                                    <div className="ml-10">
                                        <span className="text-xl font-bold">
                                            {
                                                chartData.find(
                                                    (chart_name) =>
                                                        chart_name.chart_name ===
                                                        "new_customers"
                                                )?.data.data
                                            }
                                        </span>{" "}
                                        <div>
                                            {" "}
                                            <FormattedMessage
                                                id="management.new_customers"
                                                values={{ number: 1 }}
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center mb-5 text-left ">
                                    <img
                                        src={require("./icons8-stocks-growth-96 (1).png")}
                                        height="50"
                                        width="50"
                                    />
                                    <div className="ml-10">
                                        <span className="text-xl font-bold">
                                            {chartData
                                                .find(
                                                    (chart_name) =>
                                                        chart_name.chart_name ===
                                                        "sales_target"
                                                )
                                                ?.data.data[1].toFixed(0) +
                                                " €"}
                                        </span>{" "}
                                        <div>
                                            {" "}
                                            <FormattedMessage
                                                id="management.total_sales"
                                                values={{ number: 1 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {user.tenant.includes("myway") && (
                                    <div className="flex flex-row items-center text-left">
                                        <img
                                            src={require("./icons8-coins-100 (2).png")}
                                            height="50"
                                            width="50"
                                        />
                                        <div className="ml-10">
                                            <span className="text-xl font-bold">
                                                {(
                                                    chartData.find(
                                                        (chart_name) =>
                                                            chart_name.chart_name ===
                                                            "sales_target"
                                                    )?.data.data[1] * 0.5
                                                ).toFixed(0) + " €"}
                                            </span>{" "}
                                            <div>
                                                {" "}
                                                <FormattedMessage
                                                    id="management.total_profit"
                                                    values={{ number: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col w-1/3 items-center justify-center mt-5">
                                <div className="text-xl font-bold">
                                    {monthlySalesData &&
                                        (
                                            monthlySalesData.reduce(
                                                (sum, value) => sum + value,
                                                0
                                            ) / monthlySalesData.length
                                        ).toFixed(0)}{" "}
                                    €
                                </div>
                                <div className="text-large">
                                    <FormattedMessage
                                        id="management.average_monthly_sales"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div
                                    style={{ maxWidth: 300 }}
                                    className="mb-5 mt-5 flex items-center justify-center w-full"
                                >
                                    <Bar
                                        options={stacked_options1}
                                        data={data1}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 items-center justify-center">
                                <div className="text-xl font-bold">
                                    {chartData
                                        .find(
                                            (chart_name) =>
                                                chart_name.chart_name ===
                                                "sales_target"
                                        )
                                        ?.data.data.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator + currentValue,
                                            0
                                        )
                                        .toFixed(0) + " €"}
                                </div>
                                <div className="text-large">
                                    <FormattedMessage
                                        id="management.sales_target"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div
                                    style={{ maxWidth: 120 }}
                                    className="text-white mt-3 mb-10"
                                >
                                    <Doughnut
                                        options={doughnut_options1}
                                        data={doughnut_data1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container flex flex-col bg-white rounded-md justify-center pr-10">
                        <div className="grid grid-cols-2 gap-2 align-start">
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left flex-col items-center">
                                <h3 className="text-black ml-5 mt-5 font-semibold">
                                    <FormattedMessage
                                        id="management.accumulated_sales"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ maxWidth: 450 }}
                                    className=" ml-5 mt-5 pr-10 flex items-center justify-center w-full"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={data2}
                                    />
                                </div>
                            </div>
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black ml-5 mt-5 font-semibold">
                                    <FormattedMessage
                                        id="management.sales_comparison"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ minWidth: 100, maxWidth: 500 }}
                                    className=" mt-5 ml-5 pr-5"
                                >
                                    <Line
                                        options={line_options}
                                        data={line_data}
                                    />
                                </div>
                            </div>
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black ml-5 mt-5 font-semibold">
                                    <FormattedMessage
                                        id="management.sales_client"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minHeight: 100,
                                        maxWidth: 450,
                                    }}
                                    className=" mt-5 ml-5 pr-5"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data}
                                    />
                                </div>
                            </div>
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black ml-5 mt-5 font-semibold">
                                    <FormattedMessage
                                        id="management.client_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ maxWidth: 300 }}
                                    className="ml-20"
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="container flex flex-col bg-white rounded-md justify-center pr-8 mt-3">
                        <div className="grid grid-cols-2 gap-2 align-start ml-2">
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black ml-20 mt-5 font-semibold">
                                    Sales by product type{" "}
                                </h3>
                                <div
                                    style={{ minHeight: 100, maxWidth: 450 }}
                                    className=" mt-5 ml-20"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data2}
                                    />
                                </div>
                            </div>
                            <div className="p-2 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black ml-20 mt-5 font-semibold">
                                    Product type distribution
                                </h3>
                                <div
                                    style={{ maxWidth: 300 }}
                                    className="ml-40"
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container flex flex-col bg-white rounded-md mt-2 pr-10">
                        <div className="flex flex-col divide-x-2 mt-5 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left">
                            <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                <FormattedMessage
                                    id="management.summary_nba"
                                    values={{ number: 1 }}
                                />
                                {chartData[2]?.labels}
                            </div>
                            <div className="flex flex-row ml-20">
                                <div className="flex flex-col w-1/2">
                                    {" "}
                                    <div className="text-black font-semibold text-sm">
                                        <FormattedMessage
                                            id="management.statistics"
                                            values={{ number: 1 }}
                                        />{" "}
                                        [{recommendationValue}€
                                        <FormattedMessage
                                            id="management.sales_impact"
                                            values={{ number: 1 }}
                                        />
                                        ]
                                    </div>
                                    <div className="w-3/4 mt-5">
                                        <Bar
                                            data={nba_data}
                                            options={nba_options}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-1/2">
                                    {" "}
                                    <div className="text-black font-semibold text-sm">
                                        <FormattedMessage
                                            id="management.categories"
                                            values={{ number: 1 }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            minHeight: 100,
                                            maxWidth: 450,
                                        }}
                                        className=" mt-5 ml-10 mb-5"
                                    >
                                        <Bar
                                            data={product_data}
                                            options={product_options}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {user.tenant.includes("myway") && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className=" flex flex-col mt-5 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left pr-10">
                                <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                    <FormattedMessage
                                        id="management.comparison_client_cluster"
                                        values={{ number: 1 }}
                                    />
                                </div>

                                <table className="table-auto w-5/6 mb-5 ml-20">
                                    <thead className="text-xs font-semibold text-black rounded-header">
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left"></div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center">
                                                    Traditionnel
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center ">
                                                    Premium
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center">
                                                    Local
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {customer_groups.map((group) => {
                                            return (
                                                <tr key={group.id}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="text-black font-semibold">
                                                                {group.row}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div
                                                                className={`text-center font-bold ${
                                                                    group.premium.startsWith(
                                                                        "-"
                                                                    )
                                                                        ? "text-rose-500"
                                                                        : "text-emerald-400"
                                                                }`}
                                                            >
                                                                {group.premium}
                                                                {group.premium.startsWith(
                                                                    "-"
                                                                )
                                                                    ? ""
                                                                    : `↗`}
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.premium}
                                                            </div>
                                                        </td>
                                                    )}

                                                    {bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div
                                                                className={`text-center font-bold ${
                                                                    group.core.startsWith(
                                                                        "-"
                                                                    )
                                                                        ? "text-rose-500"
                                                                        : "text-emerald-400"
                                                                }`}
                                                            >
                                                                {group.core}↗
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.core}
                                                            </div>
                                                        </td>
                                                    )}
                                                    {bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div
                                                                className={`text-center font-bold ${
                                                                    group.core.startsWith(
                                                                        "-"
                                                                    )
                                                                        ? "text-rose-500"
                                                                        : "text-emerald-400"
                                                                }`}
                                                            >
                                                                {group.local}↗
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!bold_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.local}
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="mt-5 mb-5 ml-20 align-left text-sm font-semibold">
                                    <FormattedMessage
                                        id="management.client_nba"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div className="flex grid-cols-2 gap-1 align-start ml-20 mb-5 w-5/6">
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="30"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <FormattedMessage id="management.client_nba1" />
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="30"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <FormattedMessage id="management.client_nba2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!user.tenant.includes("myway") && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className=" flex flex-col mt-5 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left pr-10">
                                <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                    Comparison by client cluster
                                </div>

                                <table className="table-auto w-5/6 mb-5 ml-20">
                                    <thead className="text-xs font-semibold text-black rounded-header">
                                        <tr className="text-center">
                                            {customer_headers}
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {/* Conditional rendering when tableData is available */}
                                        {customer_tableData &&
                                            customer_tableData.length > 0 &&
                                            Array.from(
                                                { length: 6 },
                                                (_, i) => (
                                                    <tr
                                                        key={`transposedRow${i}`}
                                                    >
                                                        {customer_tableData.map(
                                                            (row, index) =>
                                                                row.props
                                                                    ?.children
                                                                    ? row.props
                                                                          .children[
                                                                          i
                                                                      ]
                                                                    : null
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                                {/* <div className="mt-5 mb-5 ml-20 align-left text-sm font-semibold">
                                    Client next best actions
                                </div>
                                <div className="flex grid-cols-2 gap-1 align-start ml-20 mb-5 w-5/6">
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="30"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            Margin in segment "Animal Shop"
                                            decreasing. Accellerate sales of
                                            high margin items.
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="30"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            Number of clients with churn risk in
                                            segement "Veterinary Clinic"
                                            increasing. Conduct client survey.
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )}
                    {user.tenant.includes("myway") && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className="flex flex-col divide-x-2 mt-5 mb-3 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left">
                                <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                    <FormattedMessage
                                        id="management.comparison_product"
                                        values={{ number: 1 }}
                                    />
                                </div>

                                <table className="table-auto w-5/6 mb-5 ml-20">
                                    <thead className="text-xs font-semibold text-black rounded-header">
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left"></div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center">
                                                    Vodka
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center ">
                                                    Gin
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap border-b-2">
                                                <div className="font-semibold text-center">
                                                    Mixer
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {product_groups.map((group) => {
                                            return (
                                                <tr key={group.id}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="text-black font-semibold">
                                                                {group.row}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-bold text-emerald-400">
                                                                {group.premium}{" "}
                                                                ↗
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.premium}
                                                            </div>
                                                        </td>
                                                    )}

                                                    {product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-bold text-emerald-400">
                                                                {group.core}↗
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.core}
                                                            </div>
                                                        </td>
                                                    )}
                                                    {product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-bold text-emerald-400">
                                                                {group.local}↗
                                                            </div>
                                                        </td>
                                                    )}
                                                    {!product_rows.includes(
                                                        group.id
                                                    ) && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {group.local}
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="mt-5 mb-5 ml-20 align-left text-sm font-semibold">
                                    <FormattedMessage
                                        id="management.product_nba"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div className="flex grid-cols-2 gap-1 align-start ml-20 mb-5 w-5/6">
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div className="flex w-1/6">
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="20"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <FormattedMessage id="management.product_nba1" />
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div className="flex w-1/6">
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="20"
                                            />
                                        </div>
                                        <div className="ml-5 flex w-9/10">
                                            <FormattedMessage id="management.product_nba2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!user.tenant.includes("myway") && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className="flex flex-col divide-x-2 mt-5 mb-3 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left">
                                <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                    Comparison by product group
                                </div>

                                <table className="table-auto w-5/6 mb-5 ml-20">
                                    <thead className="text-xs font-semibold text-black rounded-header">
                                        <tr className="text-center">
                                            {headers}
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {/* Conditional rendering when tableData is available */}
                                        {tableData &&
                                            tableData.length > 0 &&
                                            Array.from(
                                                { length: 5 },
                                                (_, i) => (
                                                    <tr
                                                        key={`transposedRow${i}`}
                                                    >
                                                        {tableData.map(
                                                            (row, index) =>
                                                                row.props
                                                                    ?.children
                                                                    ? row.props
                                                                          .children[
                                                                          i
                                                                      ]
                                                                    : null
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                                {/* <div className="mt-5 mb-5 ml-20 align-left text-sm font-semibold">
                                    Product next best actions
                                </div>
                                <div className="flex grid-cols-2 gap-1 align-start ml-20 mb-5 w-5/6">
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src="https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=300,h=300,format=webp/https://cdn.royalcanin-weshare-online.io/H-fomX0BaPOZra8q2MuM/v339/sol-mini-puppy-cig-wet-packshot-b1"
                                                width="70"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            Mini puppy chunks in gravy sales
                                            decreasing due to ongoing promotions
                                            from competition. Launch promo offer
                                            to accelerate sales.
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div>
                                            <img
                                                src="https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=300,h=300,format=webp/https://cdn.royalcanin-weshare-online.io/v2k6a2QBG95Xk-RBN9oV/v208/packshot-mini-ad-shn17"
                                                width="30"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            Dry food segment not increasing
                                            proportional to wet food.
                                            Incentivize sales agents to push
                                            product recommendations.
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )}
                    {user.leadModule && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className="flex flex-col divide-x-2 mt-5 mb-3 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left">
                                <div className="mt-10 mb-5 ml-20 align-left font-semibold">
                                    <FormattedMessage
                                        id="management.comparison_by_sales_group"
                                        values={{ number: 1 }}
                                    />
                                </div>

                                <table className="table-auto w-5/6 mb-5 ml-20">
                                    <thead className="text-xs font-semibold text-black rounded-header">
                                        <tr>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left">
                                                    <FormattedMessage id="management.sales_group" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-left">
                                                    <FormattedMessage id="management.sales_revenue" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage id="management.sales_margin" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    # Clients
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage id="management.sales_basket" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage id="management.sales_frequency" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage id="management.sales_conversion" />
                                                </div>
                                            </th>
                                            <th className="p-2 whitespace-nowrap">
                                                <div className="font-semibold text-center">
                                                    <FormattedMessage id="management.sales_churn" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-xs divide-y divide-slate-100">
                                        {customers.map((customer) => {
                                            return (
                                                <tr key={customer.id}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="font-medium text-black">
                                                                {customer.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center font-medium">
                                                            {customer.sales}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center font-medium">
                                                            {customer.margin}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center font-medium">
                                                            {customer.clients}
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center font-medium">
                                                            {
                                                                customer.basket_value
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="text-center font-medium">
                                                            {
                                                                customer.purchase_frequency
                                                            }
                                                        </div>
                                                    </td>

                                                    {customer.conversion_rate >
                                                        "15%" && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {
                                                                    customer.conversion_rate
                                                                }
                                                            </div>
                                                        </td>
                                                    )}
                                                    {customer.conversion_rate <=
                                                        "15%" && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-bold text-rose">
                                                                {
                                                                    customer.conversion_rate
                                                                }
                                                            </div>
                                                        </td>
                                                    )}
                                                    {customer.customer_churn <=
                                                        "30%" && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-medium">
                                                                {
                                                                    customer.customer_churn
                                                                }
                                                            </div>
                                                        </td>
                                                    )}
                                                    {customer.customer_churn >
                                                        "30%" && (
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="text-center font-bold text-rose">
                                                                {
                                                                    customer.customer_churn
                                                                }
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="mt-5 mb-5 ml-20 align-left text-sm font-semibold">
                                    <FormattedMessage
                                        id="management.team_nba"
                                        values={{ number: 1 }}
                                    />
                                </div>
                                <div className="flex grid-cols-2 gap-1 align-start ml-20 mb-5 w-5/6">
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div className="flex w-1/6">
                                            <img
                                                src={require("./icons8-information-50.png")}
                                                width="20"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <FormattedMessage id="management.sales_nba1" />
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center w-1/2 text-sm rounded-md border-2 border-gray-200 dropshadow-md p-4">
                                        {" "}
                                        <div className="flex w-1/6">
                                            <img
                                                src={require("./icons8-female-profile-50.png")}
                                                width="20"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <FormattedMessage id="management.sales_nba2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {user.leadModule && (
                        <div className="container flex flex-col bg-white rounded-md pr-10">
                            <div className="flex flex-col divide-x-2 mt-5 mb-3 divide-white text-black border-2 rounded-md border-gray-100 dropshadow-md text-left">
                                <div className="text-left mt-10 ml-20 font-semibold">
                                    {/* Individual Performances */}
                                    <FormattedMessage id="management.individual_performance" />
                                </div>
                                <div className="text-left text-xs text-gray-600 ml-20">
                                    <FormattedMessage id="management.individual_performance_text" />
                                </div>
                                {/* <div className="text-left text-xs text-gray-600 ml-20">
                                    Compare Individual performances and
                                    development vs last assessement.
                                </div> */}
                                <div className="flex flex-row w-3/5 h-4/5 mt-10 ml-40">
                                    <Scatter
                                        data={{ datasets: dataset }}
                                        options={options}
                                        height={400}
                                    />
                                    {selectedPerson && (
                                        <div style={{ marginTop: "10px" }}>
                                            Selected Person: {selectedPerson}
                                            Q1: Q2:
                                        </div>
                                    )}
                                    <div className="flex flex-col ml-10 justify-center">
                                        <div className="flex flex-row items-center">
                                            <div
                                                className="bg-lime-500 rounded-full"
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            ></div>
                                            <div className="text-xs ml-2">
                                                <FormattedMessage id="management.winner" />
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <div
                                                className="bg-orange-500 rounded-full"
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            ></div>
                                            <div className="text-xs ml-2">
                                                <FormattedMessage id="management.motivate" />
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <div
                                                className="bg-teal-500 rounded-full"
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            ></div>
                                            <div className="text-xs ml-2">
                                                <FormattedMessage id="management.champion" />
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <div
                                                className="bg-rose-500 rounded-full"
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            ></div>
                                            <div className="text-xs ml-2">
                                                <FormattedMessage id="management.grow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <Footer /> */}
                </section>
            </IntlProvider>
        </>
    );
};

export default ManagementDashboard;
