/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navigator from "../../components/Navigator";
import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header";
import { FiShoppingCart } from "react-icons/fi";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import * as moment from "moment";
import jwtDecode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
/*eslint-disable*/

// import DashboardHeader from "../../components/DashboardHeader";

import FastAPIClient from "../../client";

import config from "../../config";

import ProductDetails from "../../components/ProductDetails";
// import { NotLoggedIn } from "./NotLoggedIn";
// import { NotLoggedIn } from "./NotLoggedIn";

// import "./style.css";
import ScrollFilterButton from "../../components/ScrollFilterButton";

/* eslint-disable */

const client = new FastAPIClient(config);

const Auth0 = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userName, setUsername] = useState();

    const getUserName = () => {
        client.fetchUser().then((data) => {
            // Set the username in the state
            setUsername(data.first_name);
        });
    };

    // const [userMetadata, setUserMetadata] = useState(null);
    // const [accessToken, setAccessToken] = useState(null);
    // useEffect(() => {
    //     const getUserMetadata = async () => {
    //         // const domain = "dev-myway.eu.auth0.com";

    //         try {
    //             // const accessToken = await getAccessTokenSilently({
    //             //     authorizationParams: {
    //             //         audience: `https://${domain}/api/v2/`,
    //             //         scope: "read:current_user",
    //             //     },
    //             // });

    //             const accessToken = await getAccessTokenSilently({
    //                 authorizationParams: {
    //                     audience: `https://www.api.myway.technology`,
    //                     scope: "read:appointments",
    //                 },
    //             });
    //             localStorage.setItem("token_test", accessToken);

    //             // const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    //             // const metadataResponse = await fetch(userDetailsByIdUrl, {
    //             //     headers: {
    //             //         Authorization: `Bearer ${accessToken}`,
    //             //     },
    //             // });

    //             // const { user_metadata } = await metadataResponse.json();

    //             // setUserMetadata(user_metadata);
    //             setAccessToken(accessToken);
    //             console.log(accessToken);
    //             // console.log(accessToken);
    //             // console.log(accessToken);
    //         } catch (e) {
    //             console.log(e.message);
    //         }
    //     };

    //     getUserMetadata();
    // }, []);

    useEffect(() => {
        getUserName();
    }, [isAuthenticated]);

    const { logout } = useAuth0();

    return (
        <section className="flex flex-row bg-white w-full h-full">
            Hello Tobi
            {userName}
            <div className="text-black">
                <h3>User Metadata</h3>

                <p>Hello</p>
                {/* <p>{accessToken}</p> */}
                <p>Hello1</p>

                {/* <p>{user.sub}</p> */}
            </div>
            <button
                className="text-black"
                onClick={() =>
                    logout({
                        logoutParams: {
                            returnTo: "http://127.0.0.1:3000",
                        },
                    })
                }
            >
                Log Out
            </button>
        </section>
    );
};

export default Auth0;
