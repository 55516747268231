import React from "react";

// function setColor(read) {
// 	let result;
// 	if (read) {
// 		result = '#000000';
// 	} else {
// 		result = '#E0115F';
// 	}
// 	return result;
//   }

const ProductSales = ({ productsales }) => {
    return (
        productsales && (
            <div
                className="overflow-hidden overflow-y-scroll"
                style={{
                    maxHeight: 50,
                }}
            >
                <div
                    className="float-left mr-2 text-xs"
                    style={{ minWidth: 120 }}
                >
                    {productsales &&
                        productsales.map((item) => (
                            <div
                                key={item.product_name}
                                className="font-medium  text-gray-800 text-xxs"
                            >
                                {item.product_name}
                            </div>
                        ))}
                </div>
                <div className="text-xs">
                    {productsales &&
                        productsales.map((item) => (
                            <div key={item.product_name}>
                                {item.sales_change.substring(0, 1) == "+" &&
                                    item.sales_change.substring(0, 2) !==
                                        "+i" && (
                                        <span
                                            className="inline-block bg-emerald-400 text-white text-xxs pl-1 pr-1 rounded-full"
                                            data-config-id="change2"
                                        >
                                            {item.sales_change} %
                                        </span>
                                    )}
                                {item.sales_change.substring(0, 1) == "-" && (
                                    <span
                                        className="inline-block bg-rose-500 text-white text-xxs pl-1 pr-1 rounded-full"
                                        data-config-id="change2"
                                    >
                                        {item.sales_change} %
                                    </span>
                                )}
                                {item.sales_change == "+inf" && (
                                    <span
                                        className="inline-block bg-emerald-400 text-white text-xxs pl-1 pr-1 rounded-full"
                                        data-config-id="change2"
                                    >
                                        New
                                    </span>
                                )}

                                <span
                                    className="inline-block bg-emerald-400 text-white text-xxs pl-1 pr-1 rounded-full"
                                    data-config-id="change2"
                                ></span>
                            </div>
                        ))}
                    {/* <span className="inline-block bg-emerald-400 text-white text-xxs pl-1 pr-1 rounded-full" data-config-id="change2">{item.sales_change} %</span></div>))} */}
                </div>
            </div>
        )
    );
};

export default ProductSales;
