/*eslint-disable*/
import React, { useEffect, useState } from "react";
import FastAPIClient from "../../client";
import { Navigate } from "react-router-dom";
import config from "../../config";
import Footer from "../../components/Footer";
import jwtDecode from "jwt-decode";
import * as moment from "moment";
// import ClientTable from "../../components/ClientTable";
import ClientNew from "../../components/ClientNew";
// import ClientOverview from "../../components/ClientOverview";
// import { NotLoggedIn } from "./NotLoggedIn";
import Loader from "../../components/Loader";
import Navigator from "../../components/Navigator";
import "./style.css";
import Header from "../../components/Header";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const ProfileView = ({ clients, language }) => {
    return (
        <>
            {/* <ClientTable clients={clients} client={client} showUpdate={true} /> */}
            {/* <ClientOverview
                clients={clients}
                client={client}
                showUpdate={true}
            /> */}
            {clients.length &&
                clients.map((client) => (
                    <ClientNew
                        alert={client.alert}
                        type={client.type}
                        key={client.id}
                        client={client}
                        language={language}
                    />
                ))}
            {!clients.length && <p>No clients found!</p>}
        </>
    );
};

const ClientDashboard = () => {
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [clients, setClients] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [recommendationValue, setRecommendationValue] = useState([]);
    // const [isLoading, setIsLoading] = useState(true); // New state variable

    const [filterOptions, setFilterOptions] = useState({});
    const [locale, setLocale] = useState();

    useEffect(() => {
        fetchUserClients();
    }, []);

    useEffect(() => {
        fetchRecommendationValue();
        setLocale(localStorage.getItem("language"));
    }, []);

    useEffect(() => {
        if (clients.length > 0) {
            const options = {};
            clients.forEach((client) => {
                // Extracting unique values for each column you want to use as a filter
                // Example: Assuming 'type' and 'arrondissement' are columns in clients data
                if (client.alert) {
                    options.Type = options.Type || [];
                    if (!options.Type.includes(client.type)) {
                        options.Type.push(client.type);
                    }
                }
                if (client.arrondissement) {
                    options.arrondissement = options.arrondissement || [];
                    if (
                        !options.arrondissement.includes(client.arrondissement)
                    ) {
                        options.arrondissement.push(client.arrondissement);
                    }
                }
                // Add more columns here...
            });
            setFilterOptions(options);
        }
    }, [clients]);

    // State to hold selected filters
    const [selectedFilters, setSelectedFilters] = useState({});

    // Function to handle filter selection
    // const handleFilterSelection = (filterType, value) => {
    //     const updatedFilters = { ...selectedFilters };
    //     if (updatedFilters[filterType]) {
    //         if (updatedFilters[filterType].includes(value)) {
    //             updatedFilters[filterType] = updatedFilters[filterType].filter(
    //                 (val) => val !== value
    //             );
    //         } else {
    //             updatedFilters[filterType].push(value);
    //         }
    //     } else {
    //         updatedFilters[filterType] = [value];
    //     }
    //     setSelectedFilters(updatedFilters);
    // };

    // Apply filters to the clients data
    // Apply filters to the clients data
    const applyFilters = () => {
        if (Object.keys(selectedFilters).length === 0) {
            return clients; // No filters selected, return all clients
        }

        // Filter logic based on selectedFilters object
        const filteredClients = clients.filter((client) => {
            // Implement your filtering logic here based on selected filters
            // Example: Check if client type is in selected client types
            return (
                (!selectedFilters.Type?.length ||
                    selectedFilters.Type.includes(client.type)) &&
                (!selectedFilters.arrondissement?.length ||
                    selectedFilters.arrondissement.includes(
                        client.arrondissement
                    ))
                // Add more conditions for other filters...
            );
        });
        return filteredClients;
    };

    // Function to handle filter selection and deselection
    const handleFilterSelection = (filterType, option) => {
        const updatedFilters = { ...selectedFilters };

        // If the option is already selected, deselect it
        if (updatedFilters[filterType]?.includes(option)) {
            updatedFilters[filterType] = updatedFilters[filterType].filter(
                (item) => item !== option
            );
        } else {
            // If not selected, select the option
            updatedFilters[filterType] = updatedFilters[filterType]
                ? [...updatedFilters[filterType], option]
                : [option];
        }

        // If no options are selected for a filter, remove the filter
        if (updatedFilters[filterType]?.length === 0) {
            delete updatedFilters[filterType];
        }

        setSelectedFilters(updatedFilters);
    };

    // Generate checkboxes for each filter option
    // const generateCheckboxes = (filterType) => {
    //     return filterOptions[filterType].map((option) => (
    //         <label className="custom_check" key={option}>
    //             {option}
    //             <input
    //                 type="checkbox"
    //                 checked={
    //                     selectedFilters[filterType] &&
    //                     selectedFilters[filterType].includes(option)
    //                 }
    //                 onChange={() => handleFilterSelection(filterType, option)}
    //             />
    //             <span className="checkmark"></span>
    //         </label>
    //     ));
    // };

    const fetchUserClients = () => {
        client.getUserClients().then((data) => {
            const sortedResults = data?.results.sort((a, b) => {
                // Move objects with 'alert' as true to the beginning
                return b.alert - a.alert; // b.alert - a.alert will move true values to the top
            });
            setClients(sortedResults);
            setRefreshing(false);
        });
    };

    const fetchRecommendationValue = () => {
        client.getRecommendationValue().then((data) => {
            // const sortedResults = data?.results.sort((a, b) => {
            //     // Move objects with 'alert' as true to the beginning
            //     return b.alert - a.alert; // b.alert - a.alert will move true values to the top
            // });
            setRecommendationValue(data);
            // setRefreshing(false);
        });
    };

    // Function to remove all filters
    const removeAllFilters = () => {
        setSelectedFilters({}); // Reset all filters
    };

    // const buttonStyle =
    //     "transition text hover:-translate-y-0 hover:scale-100 font-semibold  bg-white text-royal_red border border-royal_red cursor-pointer hover:bg-royal_red hover:text-white px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs";

    // const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;
    const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         }
    //     }
    // }, []);

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         } else {
    //             setIsLoggedIn(false); // User token has expired or is not valid
    //             setRefreshing(true);
    //         }
    //     } else {
    //         setIsLoggedIn(false); // User token does not exist
    //         setRefreshing(true);
    //     }
    // }, []);

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
        // return (
        //     <BeatLoader
        //         color="#000000"
        //         // loading={loading}
        //         // cssOverride={override}
        //         size={50}
        //         aria-label="Loading Spinner"
        //         data-testid="loader"
        //     />
        // );
    }

    // if (!isLoggedIn) {
    //     // Redirect to another page if user is not logged in
    //     return <Navigate to="/" />;
    // }

    return (
        <>
            {/* <section className="flex flex-row bg-white">
                <div className="z-10 border-r-1 border-white">
                    <Navigator />
                </div>
                <section
                    className="flex flex-col text-center w-full h-screen overflow-auto"
                    style={{ maxHeight: "100vh" }}
                >
                    <Header /> */}
            <div className="flex flex-row">
                <IntlProvider locale={locale} messages={messages[locale]}>
                    {/* <div className="xl-1/3"></div> */}
                    <div className="container px-15 pt-10 md:pt-20 bg-white mx-auto lg:px-10">
                        <div className="flex flex-col md:flex-row md:items-center">
                            <div className="flex w-1/2 xl:w-1/4">
                                <h1 className="ml-5 md:ml-0 md:mb-12 text-xl text-black md:text-left">
                                    <FormattedMessage id="clients.client_overview" />
                                </h1>{" "}
                            </div>
                            <div className="flex flex-col md:flex-row ml-5 mr-5 md:mr-20 md:ml-20 xl:ml-0 mt-5 items-center justify-center mb-5 md:mb-20 ">
                                <FormattedMessage
                                    id="clients.searchplaceholder"
                                    defaultMessage="search"
                                >
                                    {(placeholder) => (
                                        <input
                                            type="text"
                                            className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                                            style={{
                                                minWidth: 350,
                                            }}
                                            placeholder={placeholder}
                                        />
                                    )}
                                </FormattedMessage>
                                <a className={buttonStyle}>
                                    {" "}
                                    <FormattedMessage id="clients.search" />
                                </a>
                            </div>
                        </div>

                        <p className="text leading-relaxed text-black font-semibold mt-5 md:mt-0 ml-5 md:ml-0 text-left">
                            <FormattedMessage id="clients.total_value" />{" "}
                            {recommendationValue} €
                        </p>
                        <div
                            // style={{ minWidth: 200 }}
                            className="filter ml-5 flex flex-col hidden"
                        >
                            <div className="filter">
                                <div className="flex flex-col float-left text-left">
                                    {/* <span className="font-bold mb-5">
                                            {applyFilters().length} Client(s)
                                        </span> */}
                                    {Object.keys(filterOptions).map(
                                        (filterType) => (
                                            <div key={filterType}>
                                                <span className="font-semi-bold mt-5 pb-5 text-sm">
                                                    {filterType}
                                                </span>
                                                {filterOptions[filterType].map(
                                                    (option) => (
                                                        <label
                                                            className="custom_check mt-5"
                                                            key={option}
                                                        >
                                                            {option}
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    selectedFilters[
                                                                        filterType
                                                                    ] &&
                                                                    selectedFilters[
                                                                        filterType
                                                                    ].includes(
                                                                        option
                                                                    )
                                                                }
                                                                onChange={() =>
                                                                    handleFilterSelection(
                                                                        filterType,
                                                                        option
                                                                    )
                                                                }
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                )}
                                                <hr className="my-8 h-px bg-black border-0 black"></hr>
                                            </div>
                                        )
                                    )}
                                    <label
                                        className="text-xs font-bold"
                                        onClick={removeAllFilters}
                                    >
                                        x Remove all filters
                                    </label>
                                </div>
                            </div>
                        </div>
                        {refreshing && filteredList == 0 && <Loader />}
                        {!refreshing && (
                            <div className="text-white w-full xl:w-4/5 mt-10 items-center pl-5 pr-5 mr-5 md:pl-0 md:ml-0 text-left">
                                {applyFilters().length > 0 && (
                                    <ProfileView
                                        clients={applyFilters()}
                                        client={client}
                                        fetchUserClients={fetchUserClients}
                                        language={locale}
                                    />
                                )}
                                {applyFilters().length === 0 && (
                                    <p>No matching clients found!</p>
                                )}
                                {/* {clients.length > 0 && (
                                    <ProfileView
                                        clients={clients}
                                        client={client}
                                        fetchUserClients={fetchUserClients}
                                    />
                                )} */}
                            </div>
                        )}
                        <div className="flex flex-col items-center md:hidden">
                            <div className="flex flex-row border-client border-2 rounded-full items-center w-1/3 p-2">
                                <div className=" flex flex-row ml-7">
                                    <img
                                        src="https://www.svgrepo.com/show/532169/filter.svg"
                                        height="15"
                                        width="15"
                                    />
                                    <h1>Filter</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        // style={{ minWidth: 200 }}
                        className="filter mt-20 xl-1/3 hidden md:flex xl:w-1/5"
                    >
                        <div className="filter mt-20">
                            <div className="flex flex-col float-left text-left">
                                <span className="font-bold mt-20 mb-5">
                                    {applyFilters().length}{" "}
                                    <FormattedMessage id="clients.clients" />
                                </span>
                                {Object.keys(filterOptions).map(
                                    (filterType) => (
                                        <div key={filterType}>
                                            <span className="font-semi-bold mt-5 pb-5 text-sm">
                                                {filterType}
                                            </span>
                                            {filterOptions[filterType].map(
                                                (option) => (
                                                    <label
                                                        className="custom_check mt-5"
                                                        key={option}
                                                    >
                                                        {option}
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                selectedFilters[
                                                                    filterType
                                                                ] &&
                                                                selectedFilters[
                                                                    filterType
                                                                ].includes(
                                                                    option
                                                                )
                                                            }
                                                            onChange={() =>
                                                                handleFilterSelection(
                                                                    filterType,
                                                                    option
                                                                )
                                                            }
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                            )}
                                            <hr className="my-8 h-px bg-black border-0 black"></hr>
                                        </div>
                                    )
                                )}
                                <label
                                    className="text-xs font-bold"
                                    onClick={removeAllFilters}
                                >
                                    <FormattedMessage id="clients.remove_filter" />
                                </label>
                            </div>
                        </div>
                    </div>
                </IntlProvider>
            </div>
            {/* <div className="flex-grow"></div>
                    <Footer />
                </section>
            </section> */}
        </>
    );
};

export default ClientDashboard;
