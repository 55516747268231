import React from "react";

import {
    // FiLogOut,
    FiHome,
    // FiTool,
    FiUsers,
    FiBook,
    // FiFilter,
    // FiShoppingCart,
    FiCalendar,
    FiFilter,
} from "react-icons/fi";

import { Link } from "react-router-dom";

function Footer() {
    return (
        // <footer className={"text-center mt-auto text-black "}>
        //     <div >
        //       {/* <a className="text-white ml-4 text-xxs" href="https://christophergs.com/"> © 2022 Copyright: MyWay Technologies</a> */}
        //     </div>
        // </footer>
        // <footer className="stick top-[100vh] text-center text-black bg-royal_red">
        //     <div>
        //         {/* Your footer content goes here */}
        //         <a
        //             className="text-white ml-4 text-xxs"
        //             href="https://christophergs.com/"
        //         >
        //             {" "}
        //             © 2022 Copyright: MyWay Technologies
        //         </a>
        //     </div>
        // </footer>
        <footer
            style={{ flexShrink: 0, width: "100%" }}
            className="text-center text-white z-50 bg-buttonMainBackground sticky border-r-1 border-white md:hidden"
        >
            <div className="flex flex-row w-full items-center pt-4 pb-8 text-xl">
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/home">
                        <FiHome />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/routing">
                        <FiCalendar />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/my-leads">
                        <FiFilter />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/clients">
                        <FiUsers />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/products">
                        <FiBook />
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
