
import build_param from './build_params.json';

const basePath = build_param.basePath;

const config = {

  apiBasePath: basePath,
  reactAppMode: process.env.REACT_APP_MODE || 'dev',
};

export default config;
