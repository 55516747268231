/* eslint-disable */

import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PopUpMarker from "../PopUpMarker";

const root = document.getElementById("root");

import { Geolocation } from "@capacitor/geolocation";

import { useMedia } from "react-use";

const defaultCenter = {
    lat: 48.8608311,
    lng: 2.3411145,
};

function Map({ leads }) {
    const isMediumScreen = useMedia("(min-width: 48em)");

    const containerStyle = {
        minWidth: "400px",
        // height: "450px",
        height: isMediumScreen ? "450px" : "78vh",
        minHeight: "1050px",
    };
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyAM7Y8grdI5KJ6kNCTUbQrdf6ciXTZ2x8s",
    });

    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        Geolocation.getCurrentPosition()
            .then((position) => {
                const { latitude, longitude } = position.coords;
                setCurrentLocation({ lat: latitude, lng: longitude });
                // const longitude = position.coords.longitude;
                console.log("Longitude:", longitude);
                console.log("Latitude:", latitude);
            })
            .catch((error) => {
                console.error("Error getting current position:", error);
            });
    }, []); // Run once on component mount

    const exampleMapStyles = [
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#444444",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "all",
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
                {
                    visibility: "simplified",
                },
            ],
        },
        {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "all",
            stylers: [
                {
                    color: "#46bcec",
                },
                {
                    visibility: "on",
                },
            ],
        },
    ];

    const [map, setMap] = React.useState(null);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <div
            style={{
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                overflow: "hidden",
            }}
        >
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={currentLocation || defaultCenter}
                    zoom={13}
                    onUnmount={onUnmount}
                    options={{
                        styles: exampleMapStyles,
                        mapTypeControl: false, // Disable map type control
                        streetViewControl: false, // Disable street view control
                        zoomControl: false, // Disable zoom in and zoom out controls
                    }}
                >
                    {currentLocation && (
                        <Marker
                            position={currentLocation}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 10,
                                fillColor: "#4285F4",
                                fillOpacity: 1,
                                strokeColor: "white",
                                strokeWeight: 2,
                            }}
                        />
                    )}

                    {leads.length &&
                        leads.map((lead) => (
                            <PopUpMarker lead={lead} key={lead.name} />
                        ))}
                    <></>
                </GoogleMap>
            )}
        </div>
    );
}

export default React.memo(Map);
