import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Loader() {
    return (
        <div className="flex justify-center items-center h-screen w-full bg-white">
            {/* <iframe
                src="https://giphy.com/embed/kUTME7ABmhYg5J3psM"
                // src="https://giphy.com/gifs/mashable-3oEjI6SIIHBdRxXI40"
                width="180"
                height="160"
                frameBorder="0"
                // class="giphy-embed"
                allowFullScreen
            ></iframe> */}
            {/* <iframe
                src="https://giphy.com/embed/3oEjI6SIIHBdRxXI40"
                width="150"
                height="150"
                frameBorder="0"
                // class="giphy-embed"
                allowFullScreen
            ></iframe> */}
            <BeatLoader
                color="#26C5CC"
                // loading={loading}
                // cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <p></p>
        </div>
    );
}

export default Loader;
