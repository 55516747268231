/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navigator from "../../components/Navigator";
import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header";
import { FiShoppingCart } from "react-icons/fi";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import * as moment from "moment";
import jwtDecode from "jwt-decode";
/*eslint-disable*/

// import DashboardHeader from "../../components/DashboardHeader";

import FastAPIClient from "../../client";

import config from "../../config";

import ProductDetails from "../../components/ProductDetails";
// import { NotLoggedIn } from "./NotLoggedIn";
// import { NotLoggedIn } from "./NotLoggedIn";

// import "./style.css";
import ScrollFilterButton from "../../components/ScrollFilterButton";

/* eslint-disable */

const client = new FastAPIClient(config);

const OrderForm = () => {
    const { isLoggedIn } = useAuthentication();
    const [refreshing, setRefreshing] = useState(true);
    const [products, setProducts] = useState([]);

    const [productQuantities, setProductQuantities] = useState({});

    const totalSum = products.reduce((sum, product) => {
        const quantity = productQuantities[product.product_id] || 0;
        return sum + quantity * product.price;
    }, 0);

    const incrementQuantity = (productId) => {
        setProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1,
        }));
    };

    const decrementQuantity = (productId) => {
        setProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: Math.max((prevQuantities[productId] || 0) - 1, 0),
        }));
    };

    useEffect(() => {
        const getProducts = async () => {
            const check_login = () => {
                const tokenString = localStorage.getItem("token");
                if (tokenString) {
                    const token = JSON.parse(tokenString);
                    const decodedAccessToken = jwtDecode(token.access_token);
                    if (
                        moment.unix(decodedAccessToken.exp).toDate() >
                        new Date()
                    ) {
                        // setIsLoggedIn(true);
                        return true;
                    }
                }
            };
            var login = await check_login();
            if (login) {
                client.getAllProducts().then((data) => {
                    setProducts(data?.results);
                    setFilteredList(data?.results);
                    // defineFilters();
                    setRefreshing(false);
                });
            }
        };
        getProducts();
    }, []);

    useEffect(() => {
        // Simulating an API call to fetch orders
        // Replace this with your actual data fetching logic
        setRefreshing(false);
    }, []);

    const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;
    const buttonStyle2 = `bg-buttonMainBackground border-2 border-buttonMainBorder cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor text-buttonMainFontColor px-4 py-2 mx-auto rounded-full md:mt-0 md:ml-5 text-xs w-full`;

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    if (!isLoggedIn) {
        // Redirect to another page if the user is not logged in
        return <Navigate to="/" />;
    }

    return (
        <section className="flex flex-row bg-white w-full h-full">
            <div className="z-10 border-r-1 border-white">
                <Navigator />
            </div>
            <div
                className="flex flex-col h-full w-full"
                style={{ maxHeight: "100vh" }}
            >
                <Header />
                <div className="flex flex-col md:flex-row items-center md:mt-20 ">
                    <div className="text-left w-full">
                        <h1 className="md:mb-12 text-xl text-black mt-5 ml-5 md:ml-20">
                            New Order
                        </h1>{" "}
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        paddingBottom: "10px",
                        overflowY: "auto",
                        minHeight: "75vh",
                    }}
                >
                    <section className="md:w-4/5 flex flex-col p-4 md:ml-20 text-left">
                        <form>
                            <div className="mb-4 flex flex-row items-center md:w-1/2">
                                <label htmlFor="client">Client:</label>
                                <select
                                    id="client"
                                    name="client"
                                    className="ml-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction"
                                >
                                    <option value="client1">ANIMALIS</option>
                                    <option value="client2">
                                        LA MARE AUX CANARDS
                                    </option>
                                    <option value="client2">
                                        BB RESTAURANT
                                    </option>
                                    <option value="client2">
                                        POTEL ET CHABOT
                                    </option>
                                </select>
                            </div>
                        </form>

                        <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                            {products.map((product) => (
                                <div
                                    key={product.id}
                                    value={product.id}
                                    // className="flex w-full rounded-md border border-grey-200 mb-1 flex flex-row items-center pl-5 pr-5 pt-2 pb-2"
                                    className={`flex w-full rounded-md border ${
                                        productQuantities[product.product_id] >
                                        0
                                            ? "border-emerald-400"
                                            : "border-grey-200"
                                    } mb-1 flex flex-row items-center pl-5 pr-5 pt-2 pb-2`}
                                >
                                    <div className="flex w-full flex-col items-center ">
                                        <div className="flex w-full flex-col items-center">
                                            <div className="flex flex-row w-full items-center text-left mt-3 mb-3">
                                                <div className="w-1/3 flex flex-col items-center">
                                                    <img
                                                        src={
                                                            product.product_img
                                                        }
                                                        height="70"
                                                        width="70"
                                                    />
                                                </div>
                                                <div className="w-2/3 ml-5 flex flex-col text-base md:text-sm xl:text-base">
                                                    <div className="w-full">
                                                        {product.product_name}
                                                    </div>
                                                    <div className="flex flex-row items-center w-full text-gray-600 text-sm">
                                                        {"Ref: "}
                                                        {product.product_id}
                                                        {" |"}
                                                        {product.stock == 0 ? (
                                                            <span className="ml-2 text-red-500">
                                                                <RxCrossCircled />
                                                            </span>
                                                        ) : (
                                                            <span className="ml-2 text-green-500">
                                                                <RxCheckCircled />
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="w-full text-sm mb-2">
                                                        {"€"}
                                                        {product.price}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full justify-center">
                                                <div className="flex justify-center w-1/3 items-center rounded-full border-2 border-gray-300 text-sm">
                                                    <button
                                                        onClick={() =>
                                                            decrementQuantity(
                                                                product.product_id
                                                            )
                                                        }
                                                        className="text-gray-300"
                                                    >
                                                        -
                                                    </button>
                                                    <span className="mx-2">
                                                        {productQuantities[
                                                            product.product_id
                                                        ] || 0}
                                                    </span>
                                                    <button
                                                        onClick={() =>
                                                            incrementQuantity(
                                                                product.product_id
                                                            )
                                                        }
                                                        className="text-gray-300"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <div className="w-1/6 md:w-0"></div>
                                                <div className="flex w-1/3 justify-center items-center">
                                                    <button
                                                        className={buttonStyle}
                                                    >
                                                        <div className="flex flex-col items-center">
                                                            <div className="flex flex-row items-center">
                                                                <FiShoppingCart />{" "}
                                                                <div className="ml-3">
                                                                    Add
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Basket */}

                        <div className="mb-4 flex flex-row items-center w-1/2">
                            <div>
                                <label htmlFor="deliveryDate">
                                    Delivery Date:
                                </label>
                            </div>
                            <div className="ml-10">
                                <input
                                    type="date"
                                    id="deliveryDate"
                                    name="deliveryDate"
                                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-full focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center w-1/2 font-semibold text-lg">
                            <div>Total:</div>
                            <div className="ml-2">{`€${totalSum.toFixed(
                                2
                            )}`}</div>
                        </div>
                        <div className="flex flex-row mb-2">
                            <div className="w-4/5"></div>
                            <div className="w-2/5 md:w-1/5">
                                {" "}
                                <button type="submit" className={buttonStyle2}>
                                    Submit Order
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="flex-grow"></div>
                <Footer />
            </div>
        </section>
    );
};

export default OrderForm;
