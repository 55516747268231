/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import Footer from "../../components/Footer";
// import "./style.css";
// import Navigator from "../../components/Navigator";
import update from "immutability-helper";
import { Link } from "react-router-dom";
// import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";
// import { Navigate } from "react-router-dom";

import { AppLauncher } from "@capacitor/app-launcher";

import Map from "../map";
// import Header from "../../components/Header";
// import { isMoment } from "moment";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const ItemTypes = {
    CARD: "card",
};

const Card = ({
    id,
    text,
    time,
    index,
    distance,
    duration,
    moveCard,
    isLastCard,
}) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div
                ref={ref}
                style={{
                    // border: "2px solid",
                    borderColor: "#f3f4f6",
                    // borderRadius: "8px",
                    // padding: "0.5rem 1rem",
                    // marginBottom: ".5rem",
                    backgroundColor: "white",
                    cursor: "move",
                    opacity,
                }}
                className="w-full"
            >
                <div className="flex flex-row p-2">
                    {/* <div className="top-0 w-2 h-50 bg-emerald-400 mr-5 rounded-t-full"></div> */}
                    <div
                        className={`w-full ${
                            isLastCard ? "" : "border-b-2 border-gray-200"
                        }`}
                    >
                        <div className="flex flex-row items-center p-2 mb-2">
                            <div className="flex flex-col w-2/3 text-left">
                                {index != 0 && (
                                    <div className="w-2/3 text-gray-400 text-xs text-left mb-2">
                                        <FormattedMessage
                                            id="routing.distance"
                                            values={{ number: 1 }}
                                        />{" "}
                                        {distance} {duration}
                                    </div>
                                )}
                                <div className="flex flex-row">
                                    <div className="mb-2">{text} </div>
                                    <div className="flex ml-2 flex-row items-center bg-gold rounded-full text-white text-xxxs font-bold text-center mb-2 pl-1 pr-1">
                                        <div className="">
                                            {" "}
                                            <FormattedMessage
                                                id="routing.new_reco"
                                                values={{ number: 1 }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-2/3 text-gray-400 text-sm text-left mb-2">
                                    {time.start} - {time.end}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 left">
                                <div className="flex flex-row w-full">
                                    <div className="w-5/6"></div>
                                    <div className="w-1/6 ">
                                        <button>
                                            <img
                                                src="https://www.svgrepo.com/show/448722/bin.svg"
                                                height="15"
                                                width="15"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="left mb-4">
                                    {" "}
                                    {/* <Link to={`/client/${id}`} key={id}>
                                    <a className="transition text-xs text-royal_red border-2 border-royal_red hover:-translate-y-0 hover:scale-100 bg-white cursor-pointer hover:bg-fade px-4 py-2 mx-auto mt-3 rounded-full">
                                        Show details
                                    </a>
                                </Link> */}
                                </div>
                                <div className="left mt-5">
                                    {" "}
                                    <Link to={`/client/${id}`} key={id}>
                                        <a className="text-xs text-buttonMainFontColor border border-buttonMainBorder font-semibold bg-buttonMainBackground cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor px-3 py-2 mx-auto mt-3 rounded-full">
                                            <FormattedMessage
                                                id="routing.start_visit"
                                                values={{ number: 1 }}
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

const OpenInMapsButton = () => {
    // const [test, setTest] = useState([]);
    const [mobile, setMobile] = useState(false);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    // Function to open Google Maps on mobile

    const openGoogleMaps = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        console.log(navigator);

        console.log(navigator.userAgent);

        setMobile(isMobile);

        if ("geolocation" in navigator) {
            if (!isMobile) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        // Otherwise, use the web version
                        const mapsLink = `https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029`;
                        window.open(mapsLink, "_blank");

                        // const mapsLink = `https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029`;
                        // // Open the link in a new tab
                        // window.open(mapsLink, "_blank");
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                    }
                );
            } else {
                console.log("NONONONONONO");
                const { value } = AppLauncher.canOpenUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });

                console.log("Can open url: ", value);
                AppLauncher.openUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });
            }
        } else {
            // console.error("Geolocation is not supported by your browser");
            if (isMobile) {
                console.log("HELLOHELLOHELLOHELLOHELLO");
                // If on mobile, use Google Maps app URL scheme
                const destination = "48.7999656,2.2067029"; // Replace with the actual destination
                const { value } = AppLauncher.canOpenUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });

                console.log("Can open url: ", value);
                AppLauncher.openUrl({
                    url: "https://www.google.com/maps/dir/?api=1&destination=48.7999656,2.2067029",
                });
            } else {
                console.log("PROBLEMOOOOOOOOOO");
            }
        }

        // Function to get the user's current location
        const getCurrentLocation = () => {};
    };

    // Call the function when the component mounts
    // openGoogleMaps();

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <a
                className="transition text-xs text-buttonSecondaryFontColor border-2 border-buttonSecondaryBorder hover:-translate-y-0 hover:scale-100 bg-buttonSecondaryBackground cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor px-4 py-2 mx-auto mt-3 rounded-full"
                target="_blank" // Open the link in a new tab
                rel="noopener noreferrer" // Security best practice for opening links in a new tab
                onClick={openGoogleMaps}
            >
                <FormattedMessage
                    id="routing.open_in_maps"
                    values={{ number: 1 }}
                />
            </a>
        </IntlProvider>
    );
};

const Routing = () => {
    // const style = {
    //     width: 400,
    // };

    // const { isLoggedIn } = useAuthentication();
    const [refreshing, setRefreshing] = useState(true);
    const [locale, setLocale] = useState();
    // const { isAuthenticated } = useAuth0();

    useEffect(() => {
        setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const [cards, setCards] = useState([
        // {
        //     id: 57309378,
        //     text: "ALIZEE VET CLINIC",
        //     location: { lat: 48.87028, lng: 2.359859 },
        //     time: { start: "9:00", end: "10:00" },
        //     distance: "12 km",
        //     duration: "32 min",
        // },
        // {
        //     id: 54632894,
        //     text: "110 VIGNE - CHABLIS",
        //     location: { lat: 48.862858, lng: 2.386905 },
        //     time: { start: "11:00", end: "12:00" },
        //     distance: "18 km",
        //     duration: "45 min",
        // },
        // {
        //     id: 54729234,
        //     text: "AMIS CROQUETTES",
        //     location: { lat: 48.846282, lng: 2.300656 },
        //     time: { start: "13:00", end: "14:00" },
        //     distance: "25 km",
        //     duration: "59 min",
        // },
        // {
        //     id: 79854267,
        //     text: "ANIMALIS",
        //     location: { lat: 48.876997, lng: 2.317779 },
        //     time: { start: "14:00", end: "15:00" },
        //     distance: "21 km",
        //     duration: "35 min",
        // },
        // {
        //     id: 79854267,
        //     text: "Zincou",
        //     location: { lat: 48.8801407, lng: 2.3293067 },
        //     time: { start: "09:00", end: "10:00" },
        //     distance: "21 km",
        //     duration: "35 min",
        // },
        // {
        //     id: 57309378,
        //     text: "Poni",
        //     location: { lat: 48.8768764, lng: 2.3347939 },
        //     time: { start: "10:30", end: "11:30" },
        //     distance: "3 km",
        //     duration: "14 min",
        // },
        // {
        //     id: 54729234,
        //     text: "Les Sardignac",
        //     location: { lat: 48.8739727, lng: 2.3450212 },
        //     time: { start: "12:00", end: "13:00" },
        //     distance: "2 km",
        //     duration: "30 min",
        // },
        // {
        //     id: 54632894,
        //     text: "Eels",
        //     location: { lat: 48.8732135, lng: 2.3480782 },
        //     time: { start: "14:00", end: "15:00" },
        //     distance: "3 km",
        //     duration: "15 min",
        // },

        // {
        //     id: 79854267,
        //     text: "Chocho",
        //     location: { lat: 48.8757444, lng: 2.3470663 },
        //     time: { start: "15:30", end: "16:30" },
        //     distance: "3 km",
        //     duration: "20 min",
        // },

        {
            id: 2,
            text: "BB Restaurant",
            location: { lat: 48.8792233, lng: 2.331176 },
            time: { start: "10:00", end: "10:30" },
            distance: "0 km",
            duration: "1 min",
        },
        {
            id: 3,
            text: "Potel et Chabot",
            location: { lat: 48.8661414, lng: 2.2959963 },
            time: { start: "11:00", end: "11:30" },
            distance: "4 km",
            duration: "19 min",
        },
        {
            id: 1,
            text: "La Mare Aux Canards",
            location: { lat: 48.7999656, lng: 2.2067029 },
            time: { start: "13:30", end: "14:30" },
            distance: "11 km",
            duration: "32 min",
        },
    ]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            })
        );
    }, []);

    // Get the current date
    const currentDate = new Date();

    // Get the next day's date
    const nextDay = new Date();

    // Check if the current day is Friday or Saturday
    if (currentDate.getDay() === 5 || currentDate.getDay() === 6) {
        // If it is, set the next day to Monday
        nextDay.setDate(nextDay.getDate() + (8 - nextDay.getDay()));
    }

    // Define an array of weekdays to map the day index to its name

    let weekdays;

    if (locale == "en") {
        weekdays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
    } else {
        weekdays = [
            "Dimanche",
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi",
        ];
    }

    // Format the date as "Day, DD.MM.YYYY"
    const formattedNextDay = `${
        weekdays[nextDay.getDay()]
    }, ${nextDay.toLocaleDateString("en-GB")}`;

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    // if (!isAuthenticated) {
    //     // Redirect to another page if user is not logged in
    //     return <Navigate to="/" />;
    // }

    return (
        <DndProvider backend={HTML5Backend}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <section className="w-full h-full flex flex-row">
                    <div className="flex md:flex-row md:items-center mt-10 md:mt-0 h-full w-full md:w-2/5 xl:w-1/3 w-full ">
                        <div className="flex routing flex-col items-center  w-full">
                            <div
                                // style={style}
                                className="flex flex-col items-center mt-5  w-full"
                            >
                                <div className="flex flex-row w-full mb-10 items-center">
                                    <button className="w-1/4 text-xs flex flex-col items-center">
                                        <img
                                            src="https://www.svgrepo.com/show/510039/left-chevron.svg"
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    </button>
                                    <div className="w-1/2 text-lg">
                                        {formattedNextDay}
                                    </div>
                                    <button className="w-1/4 text-xs flex flex-col items-center">
                                        <img
                                            src="https://www.svgrepo.com/show/510166/right-chevron.svg"
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    </button>
                                </div>
                                {cards.map((card, i) => (
                                    <Card
                                        key={card.id}
                                        index={i}
                                        id={card.id}
                                        text={card.text}
                                        time={card.time}
                                        distance={card.distance}
                                        duration={card.duration}
                                        moveCard={moveCard}
                                        isLastCard={i === cards.length - 1}
                                    />
                                ))}
                                {/* Optimize */}
                                {/* <a className="transition text-xs text-buttonSecondaryFontColor border-2 border-buttonSecondaryBorder hover:-translate-y-0 hover:scale-100 bg-buttonSecondaryBackground cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor px-4 py-2 mx-auto mt-3 rounded-full">
                                        Open in maps
                                    </a> */}
                                <OpenInMapsButton />
                            </div>
                        </div>
                    </div>
                    {cards && <Map cards={cards} />}
                </section>
            </IntlProvider>
        </DndProvider>
    );
};

export default Routing;
