import React from "react";

import // FiLogOut,
// FiHome,
// FiTool,
// FiUsers,
// FiBook,
// // FiFilter,
// FiMap,
"react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import FastAPIClient from "../../client";
import config from "../../config";
const client = new FastAPIClient(config);

// import { Link } from "react-router-dom";

function Header() {
    const navigate = useNavigate();

    const handleLogout = () => {
        client.logout();
        // setIsLoggedIn(false);
        navigate("/");
    };

    return (
        // <footer className={"text-center mt-auto text-black "}>
        //     <div >
        //       {/* <a className="text-white ml-4 text-xxs" href="https://christophergs.com/"> © 2022 Copyright: MyWay Technologies</a> */}
        //     </div>
        // </footer>
        // <footer className="stick top-[100vh] text-center text-black bg-royal_red">
        //     <div>
        //         {/* Your footer content goes here */}
        //         <a
        //             className="text-white ml-4 text-xxs"
        //             href="https://christophergs.com/"
        //         >
        //             {" "}
        //             © 2022 Copyright: MyWay Technologies
        //         </a>
        //     </div>
        // </footer>
        <header
            style={{ flexShrink: 0, width: "100%" }}
            className="text-center text-white z-50 bg-white sticky md:hidden border-b border-grey-300"
        >
            <div className="flex flex-row w-full items-center pt-5 pb-5 mt-10">
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => navigate("/profile")}
                >
                    <div className="w-35 h-35 overflow-hidden rounded-full border-2 border-buttonMainBackground">
                        <img
                            src="https://img.freepik.com/vecteurs-premium/avatar-icon002_750950-52.jpg"
                            // src="https://www.westernunion.com/staticassets/content/dam/wu/jm/responsive/send-money-in-person-from-jamaica-resp.png"
                            width="35"
                            className=""
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center w-1/2"></div>
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => handleLogout()}
                >
                    <div className="w-35 h-35 text-gray-500">
                        <FiLogOut />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
