import React, { useState, useEffect } from "react";
// import DashboardHeader from "../../components/DashboardHeader";
import { faker } from "@faker-js/faker";
// import tailwindConfig from "../../tailwind.config";
// import resolveConfig from "tailwindcss/resolveConfig";
// const twFullConfig = resolveConfig(tailwindConfig)
import FastAPIClient from "../../client";
import config from "../../config";
// import * as FiIcons from "react-icons/fi";
// import Navigator from "../../components/Navigator";
// import { Navigate } from "react-router-dom";
// import useAuthentication from "../../components/Authenticate";
import Loader from "../../components/Loader";

const client = new FastAPIClient(config);

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

// export const doughnut_data = {
// 	labels: ["a", "b", "c", "d"],
// 	datasets: [
// 	{
// 		data: [300, 50, 100, 50],
// 		backgroundColor: 'rgb(71 85 105)',
// 		// hoverBackgroundColor: chartColors
// 	}
// 	]
//   };
// 	const chartColors = [
// 	"#336699",
// 	"#99CCFF",
// 	"#999933",
// 	"#666699",]

//   const doughnut_options = {
// 	legend: {
// 	display: false,
// 	position: "right"
// 	},
// 	elements: {
// 	arc: {
// 		borderWidth: 0
// 	}
// 	}
//   };

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Profile = () => {
    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;
    const [layout, setLayout] = useState([]);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    useEffect(() => {
        fetchLayout();
        document.documentElement.style.setProperty(
            "--client_main_color",
            layout[0]?.client
        );
        document.documentElement.style.setProperty(
            "--call_to_action",
            layout[0]?.call_to_action
        );
        document.documentElement.style.setProperty(
            "--chart_main",
            layout[0]?.chart_main
        );
        document.documentElement.style.setProperty(
            "--chart_second",
            layout[0]?.chart_second
        );
        document.documentElement.style.setProperty(
            "--chart_third",
            layout[0]?.chart_third
        );
        document.documentElement.style.setProperty(
            "--chart_fourth",
            layout[0]?.chart_fourth
        );
        document.documentElement.style.setProperty("--fade", layout[0]?.fade);
        document.documentElement.style.setProperty(
            "--notification",
            layout[0]?.notification
        );
        document.documentElement.style.setProperty(
            "--disruption",
            layout[0]?.disruption
        );
        document.documentElement.style.setProperty("--promo", layout[0]?.promo);
        document.documentElement.style.setProperty("--alert", layout[0]?.alert);
        setRefreshing(false);
    }, []);

    useEffect(() => {
        // setColor(layout[0]?.client);
        // setTest(document.documentElement.style.getPropertyValue('--client_main_color'))
    }, []);

    // const { isLoggedIn } = useAuthentication();
    const [refreshing, setRefreshing] = useState(true);

    // declare the data fetching function

    const fetchLayout = () => {
        client.getLayout().then((data) => {
            // setRefreshing(false);
            setLayout(data?.results);
        });
    };

    const labels_fr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];
    const labels_en = [
        "January",
        "Februar",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    // const data1 = {
    // 	labels,
    // 	datasets: [
    // 		{
    // 			label: 'Dataset 1',
    // 			data: [2, 4, 6, 10, 21, 35],
    // 			borderColor: 'rgb(71 85 105)',
    // 			backgroundColor: 'rgb(71 85 105)',
    // 		},

    // 	],
    // };
    const labels =
        locale === "en" ? labels_en : locale === "fr" ? labels_fr : [];

    const real = locale === "en" ? "Current" : locale === "fr" ? "Réel" : [];

    const forecast =
        locale === "en" ? "Forecast" : locale === "fr" ? "Prévision" : [];

    const comparison_current =
        locale === "en" ? "Current" : locale === "fr" ? "Actuel" : [];

    const comparison_previous =
        locale === "en" ? "Last year" : locale === "fr" ? "Précédent" : [];

    const beer = locale === "en" ? "Beer" : locale === "fr" ? "Bière" : [];

    const wine = locale === "en" ? "Wine" : locale === "fr" ? "Vin" : [];

    const data2 = {
        labels,
        datasets: [
            {
                label: real,
                data: [2, 4, 6, 10, 21, 35, 0, 0, 0, 0, 0],
                backgroundColor: "rgba(85,85,85,255)",
                borderRadius: 15,
            },
            {
                label: forecast,
                data: [0, 0, 0, 0, 0, 0, 40, 45, 50, 60, 70, 80],
                backgroundColor: "#bebebe",
                borderRadius: 15,
            },
        ],
    };

    // const doughnut_data = {
    // labels: ["Veterinary clinic", "Animal shop"],
    // datasets: [
    //     {
    //         data: [300, 50],
    //         backgroundColor: ["rgba(79,91,102,255)", "rgb(167,173,186)"],
    //     },
    // ],
    // };

    const doughnut_data = {
        labels: ["Restaurant", "Café"],
        datasets: [
            {
                data: [300, 50],
                backgroundColor: [
                    "rgba(85,85,85,255)",
                    "#bebebe",
                    // "rgb(167,173,186)",
                ],
            },
        ],
    };

    const doughnut_data2 = {
        labels: [beer, wine],
        datasets: [
            {
                data: [30, 50],
                backgroundColor: [
                    "rgba(85,85,85,255)",
                    "#bebebe",
                    // "rgb(167,173,186)",
                ],
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
            },
            elements: {
                arc: {
                    borderWidth: 0,
                },
            },
        },
    };

    // const stacked_options = {
    //     plugins: {
    //         legend: {
    //             position: "right",
    //         },
    //         title: {
    //             display: false,
    //             text: "Chart.js Bar Chart - Stacked",
    //         },
    //     },
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //         },
    //         y: {
    //             stacked: true,
    //         },
    //     },
    // };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    // const stacked_data = {
    //     labels,
    //     datasets: [
    //         {
    //             label: "Veterinary clinic",
    //             data: stacked_labels.map(() =>
    //                 faker.datatype.number({ min: 0, max: 1000 })
    //             ),
    //             backgroundColor: "rgb(79,91,102)",
    //             borderRadius: 15,
    //         },
    //         // {
    //         //     label: "Hotel",
    //         //     data: stacked_labels.map(() =>
    //         //         faker.datatype.number({ min: 0, max: 1000 })
    //         //     ),
    //         //     backgroundColor: "rgb(101,115,126)",
    //         //     borderRadius: 15,
    //         // },
    //         {
    //             label: "Animal shop",
    //             data: stacked_labels.map(() =>
    //                 faker.datatype.number({ min: 0, max: 1000 })
    //             ),
    //             backgroundColor: "rgb(167,173,186)",
    //             borderRadius: 15,
    //         },
    //     ],
    // };

    const stacked_data = {
        labels,
        datasets: [
            {
                label: "Restaurant",
                data: labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "rgba(85,85,85,255)",
                borderRadius: 15,
            },
            {
                label: "Café",
                data: labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "#bebebe",
                borderRadius: 15,
            },
            // {
            //     label: "Restaurant",
            //     data: stacked_labels.map(() =>
            //         faker.datatype.number({ min: 0, max: 1000 })
            //     ),
            //     backgroundColor: "rgb(167,173,186)",
            //     borderRadius: 15,
            // },
        ],
    };

    // const stacked_data2 = {
    //     labels,
    //     datasets: [
    //         {
    //             label: "Dry food",
    //             data: stacked_labels.map(() =>
    //                 faker.datatype.number({ min: 0, max: 1000 })
    //             ),
    //             backgroundColor: "rgb(79,91,102)",
    //             borderRadius: 15,
    //         },
    //         // {
    //         //     label: "Hotel",
    //         //     data: stacked_labels.map(() =>
    //         //         faker.datatype.number({ min: 0, max: 1000 })
    //         //     ),
    //         //     backgroundColor: "rgb(101,115,126)",
    //         //     borderRadius: 15,
    //         // },
    //         {
    //             label: "Wet food",
    //             data: stacked_labels.map(() =>
    //                 faker.datatype.number({ min: 0, max: 1000 })
    //             ),
    //             backgroundColor: "rgb(167,173,186)",
    //             borderRadius: 15,
    //         },

    //     ],
    // };

    const stacked_data2 = {
        labels,
        datasets: [
            {
                label: beer,
                data: labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "rgba(85,85,85,255)",
                borderRadius: 15,
            },
            // {
            //     label: "Hotel",
            //     data: stacked_labels.map(() =>
            //         faker.datatype.number({ min: 0, max: 1000 })
            //     ),
            //     backgroundColor: "rgb(101,115,126)",
            //     borderRadius: 15,
            // },
            {
                label: wine,
                data: labels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                backgroundColor: "#bebebe",
                borderRadius: 15,
            },
            // {
            //     label: "Juice",
            //     data: stacked_labels.map(() =>
            //         faker.datatype.number({ min: 0, max: 1000 })
            //     ),
            //     backgroundColor: "rgb(167,173,186)",
            //     borderRadius: 15,
            // },
        ],
    };

    // const line_options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: "right",
    //         },
    //         title: {
    //             display: false,
    //             text: "Chart.js Line Chart",
    //         },
    //     },
    // };

    const line_options = {
        responsive: true,
        lineTension: 0.3,
        plugins: {
            legend: {
                position: "right",
            },
            title: {
                display: false,
                text: "Chart.js Line Chart",
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    const line_labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "November",
        "December",
    ];

    const line_data = {
        labels,
        datasets: [
            {
                label: comparison_previous,
                data: line_labels.map(() =>
                    faker.datatype.number({ min: 0, max: 2000 })
                ),
                borderColor: "rgba(85,85,85,255)",
                backgroundColor: "rgba(85,85,85,255)",
            },
            {
                label: comparison_current,
                data: line_labels.map(() =>
                    faker.datatype.number({ min: 0, max: 2000 })
                ),
                borderColor: "#bebebe",
                backgroundColor: "#bebebe",
            },
        ],
    };

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <>
            {/* <DashboardHeader /> */}
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="mt-10 ml-5 md:mt-20 text-left md:ml-20 flex flex-row items-center">
                    <h1 className="mb-12 text-xl text-black mt-5 font-semibold">
                        <FormattedMessage
                            id="performance.my_performance"
                            values={{ number: 1 }}
                        />
                    </h1>
                    <h1 className="mb-12 text-sm text-gray-400 mt-6 ml-5 ">
                        <FormattedMessage
                            id="performance.last_updated"
                            values={{ number: 1 }}
                        />
                        18, 2024
                    </h1>
                </div>
                <div className="flex flex-col ml-5 mr-5 md:mr-0 md:ml-0 md:flex-row pb-6 mb-20 text-black rounded-3xl">
                    {/* style={{minHeight:700,maxHeight:700,minWidth:300,maxWidth:300}} */}
                    <div className=" container flex flex-col bg-white rounded-3xl md:ml-10 mr-10 w-full xl:w-full md:w-full">
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10">
                            <div className="">
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full">
                                    <div className="flex mb-3 items-center justify-between ">
                                        <h3
                                            className="text-black text-left text-base text-gray-00 font-semibold"
                                            data-config-id="header2"
                                        >
                                            <FormattedMessage
                                                id="performance.total_sales"
                                                values={{ number: 1 }}
                                            />
                                        </h3>
                                    </div>
                                    <div className="flex items-center mb-3 mt-5">
                                        <span
                                            className="text-lg font-bold ml-4"
                                            data-config-id="value2"
                                        >
                                            €240,563.00
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +6.9%
                                        </span>
                                    </div>
                                    <div className="relative w-3/4 ml-4 h-6 mb-2 bg-gray-200 rounded mt-3">
                                        <div className="absolute top-0 left-0 w-2/6 h-full bg-gray-600 rounded text-white font-bold"></div>
                                        <div className="text-black absolute left-2/4 mt-1 font-bold text-xs">
                                            {" "}
                                            33 / 100%
                                        </div>
                                    </div>
                                    <p
                                        className="text-xs text-gray-600 font-bold"
                                        data-config-id="desc2"
                                    >
                                        <FormattedMessage
                                            id="performance.yearly_goal"
                                            values={{ number: 1 }}
                                        />
                                    </p>
                                </div>
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-10">
                                    <div className="flex mb-3 items-center justify-between">
                                        <h3
                                            className="text-black text-left font-semibold"
                                            data-config-id="header2"
                                        >
                                            <FormattedMessage
                                                id="performance.estimated_bonus"
                                                values={{ number: 1 }}
                                            />
                                        </h3>
                                    </div>
                                    <div className="flex items-center mb-3">
                                        <span
                                            className="text-lg font-bold ml-4"
                                            data-config-id="value2"
                                        >
                                            €13,563.00
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.accumulated_sales"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ maxWidth: 450 }}
                                    className="mb-5 md:ml-10 mt-5 flex items-center justify-center w-full"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={data2}
                                    />
                                </div>
                            </div>
                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.sales_comparison"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minWidth: 100,
                                        maxWidth: 500,
                                    }}
                                    className="mb-5 mt-5 md:ml-10"
                                >
                                    <Line
                                        options={line_options}
                                        data={line_data}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 w-full">
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.conversion_rate"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            97%
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +7ppts
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            90%{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.executed_nba"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            141
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +8%
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            130{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.customer_churn"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            30%
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            -12%
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            34%{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.number_clients"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            112
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +2%
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            110{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.basket_value"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            €1450
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +18%
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            €1230{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                    <h3 className="text-black font-semibold">
                                        <FormattedMessage
                                            id="performance.purchase_frequency"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div className="flex items-center mt-3 ml-4">
                                        <span
                                            className="md:text-xl font-bold"
                                            data-config-id="value2"
                                        >
                                            4
                                        </span>
                                        <span
                                            className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                            data-config-id="change2"
                                        >
                                            +33%
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className="text-xs md:text-base ml-4 mt-2 text-black"
                                            data-config-id="value2"
                                        >
                                            3{" "}
                                            <FormattedMessage
                                                id="performance.last_year"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.sales_by_client_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minHeight: 100,
                                        maxWidth: 450,
                                    }}
                                    className="mb-5 mt-5 md:ml-20"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data}
                                    />
                                </div>
                            </div>
                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.client_by_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ maxWidth: 300 }}
                                    className="md:ml-40"
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.sales_by_product_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minHeight: 100,
                                        maxWidth: 450,
                                    }}
                                    className="mb-5 mt-5 md:ml-20"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data2}
                                    />
                                </div>
                            </div>
                            <div className="p-2 mb-3 bg-white rounded-md border-2 border-gray-100 w-full mt-3 text-left">
                                <h3 className="text-black font-semibold">
                                    <FormattedMessage
                                        id="performance.product_by_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{ maxWidth: 300 }}
                                    className="md:ml-40"
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
};

export default Profile;
