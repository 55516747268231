import React, { useState, useEffect } from "react";
// import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import "./style.css";
// import Navigator from "../../components/Navigator";
import FastAPIClient from "../../client";
import config from "../../config";
// import { Navigate } from "react-router-dom";
import * as moment from "moment";
// import jwtDecode from "jwt-decode";
// import Loader from "../../components/Loader";
// import Header from "../../components/Header";
import { FormattedMessage, IntlProvider } from "react-intl";
import Loader from "../../components/Loader";

import { useAuth0 } from "@auth0/auth0-react";

const client = new FastAPIClient(config);

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Home = () => {
    const [time, setTime] = useState(false);
    const [username, setUsername] = useState(false);
    const [leadNotification, setLeadNotification] = useState(false);
    const [recommendationNotification, setRecommendationNotification] =
        useState(false);
    const [refreshing, setRefreshing] = useState(true);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [locale, setLocale] = useState();
    // const [refreshing, setRefreshing] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    // const { isAuthenticated } = useAuth0();
    // const [user, setUser] = useState(false);
    // const locale = "en";

    useEffect(() => {
        // Set the current time using the moment library
        setTime(moment().format("HH:mm"));
    }, []);

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            console.log("Fetching user");
            const data = await client.fetchUser();

            // Set user state
            // await setUser(data);
            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);
            setLocale(localStorage.getItem("language"));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://www.api.myway.technology",
                        scope: "read:appointments",
                    },
                });

                console.log("logging token");
                console.log(accessToken);
                // localStorage.setItem(
                //     "@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments",
                //     accessToken
                // );
                localStorage.setItem("token", accessToken);
                // You can set the current time using the moment library here if needed
            } catch (error) {
                console.error("Failed to fetch access token:", error);
            }
        };
        // localStorage.setItem("language", "hase");
        const tokenData = localStorage.getItem("token");

        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            fetchAccessToken();
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        // // Fetch the user's name from the client
        // getUserName();
        // getLeadNotifications();
        // getRecommendationNotifications();
        // // setRefreshing(false);
        // if (!localStorage.getItem("language")) {
        //     fetchUserData();
        // } else {
        //     setLocale(localStorage.getItem("language"));
        // }
        // setRefreshing(true);
        const fetchData = async () => {
            await Promise.all([
                getUserName(),
                getLeadNotifications(),
                getRecommendationNotifications(),
            ]);

            if (!localStorage.getItem("language")) {
                console.log("No language");
                await fetchUserData();
            } else {
                setLocale(localStorage.getItem("language"));
            }
        };

        fetchData();
        // localStorage.setItem("language", "hase");
        const tokenData = localStorage.getItem("token");
        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            console.log("Im the token");
            console.log(tokenData);
        }

        // const storedLanguage =
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            // Set the username in the state
            setUsername(data);
        });
        console.log("done user");
    };

    const getLeadNotifications = () => {
        client.getLeadNotifications().then((data) => {
            // Set the username in the state
            setLeadNotification(data);
        });
        setRefreshing(false);
    };

    const getRecommendationNotifications = () => {
        client.getRecommendationNotifications().then((data) => {
            // Set the username in the state
            setRecommendationNotification(data);
        });
    };

    const nav = useNavigate();

    // Define the styles using template literals
    const buttonStyle = `bg-buttonSecondaryBackground border-2 border-buttonSecondaryBorder cursor-pointer hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 md:ml-5 text-xs w-full`;
    const widget_style = `flex flex-col bg-white text-black items-center justify-center md:bg-white rounded-md md:border-1 shadow-md border-notification p-2 md:p-5 md:text-lg hover:bg-gray-100 hover:shadow-md h-24`;
    // const widget_style_focus = `flex flex-col bg-white text-black border border-buttonSecondaryBorder items-center justify-center md:bg-white rounded-md md:border-1 shadow-md border-notification p-2 md:p-5 md:text-lg hover:bg-gray-100 hover:shadow-md h-24`;

    // const start_widget_style = `flex flex-col bg-gray-100 text-royal_red border-2 border-royal_red items-center justify-center md:bg-white rounded-md md:border-1 shadow-md border-notification p-2 md:p-5 md:text-lg hover:bg-gray-100 hover:shadow-md h-24`;
    const alert_style = `text-white text-xxs font-bold mt-1 rounded-full bg-gold pl-2 pr-2 pt-1 pb-1`;

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         } else {
    //             setIsLoggedIn(false); // User token has expired or is not valid
    //             setRefreshing(false);
    //         }
    //     } else {
    //         setIsLoggedIn(false); // User token does not exist
    //         setRefreshing(false);
    //     }
    // }, []);

    // if (refreshing) {
    //     return <Loader />; // Display Loader while data is being fetched
    // }

    // if (!isAuthenticated) {
    //     // Redirect to another page if user is not logged in
    //     return <Navigate to="/" />;
    // }

    return (
        <>
            {/* <section className="flex flex-row bg-white">
                <div className="z-10 border-r-1 border-white">
                    <Navigator />
                </div>
                <section className="w-full bg-white flex flex-col h-screen">
                    <Header />


                    <div className="flex flex-col w-full items-center mt-10 md:mt-20">

                    </div> */}
            <section>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    {!username ||
                        (locale != "en" && locale != "fr" && (
                            <div>
                                <Loader />
                            </div>
                        ))}
                    {!refreshing &&
                        username &&
                        (locale == "en" || locale == "fr") && (
                            <div className="w-full rounded-3xl md:rounded-none flex fex-row items-center h-full mt-5 md:mt-20">
                                <div className="flex flex-col items-center w-full">
                                    <div className="flex flex-col items-center justify-center rounded-3xl md:rounded-none">
                                        <div className="mt-10 md:mt-12 text-lg md:text-xl tracking-wider text-black">
                                            {time && (
                                                // Display the current time and username once they are available
                                                <>
                                                    {/* It&apos;s {time}, Welcome{" "}
                                            {username.first_name}! */}
                                                    <FormattedMessage
                                                        id="home.welcome"
                                                        values={{
                                                            time,
                                                            username:
                                                                username.first_name,
                                                        }}
                                                    />
                                                    <h1 className="text-gold text-base mt-5 font-bold">
                                                        {/* You have new tasks waiting for
                                                you! */}
                                                        <FormattedMessage id="home.newTasks" />
                                                    </h1>
                                                    <div>
                                                        <span
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                            }}
                                                            className="rc-icon rc-behavior rc-brand3"
                                                        ></span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="flex flex-col md:flex-row mr-20 ml-20 md:mt-5 items-center justify-center mb-5 md:mb-20 ">
                                            <FormattedMessage
                                                id="home.SearchClients"
                                                defaultMessage="search"
                                            >
                                                {(placeholder) => (
                                                    <input
                                                        type="text"
                                                        className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                                                        style={{
                                                            minWidth: 350,
                                                        }}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                    />
                                                )}
                                            </FormattedMessage>
                                            <a className={buttonStyle}>
                                                {" "}
                                                <FormattedMessage id="home.Search" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center w-full">
                                        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-10 w-5/6 md:w-1/2">
                                            {/* Display widgets based on the user's modules */}

                                            {username.routemodule && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/routing")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.myDay" />
                                                    </div>

                                                    <div className="text-white text-xxs font-bold mt-1 rounded-full bg-gold pl-2 pr-2 pt-1 pb-1">
                                                        <FormattedMessage
                                                            id="home.newVisits"
                                                            values={{
                                                                number: 3,
                                                            }}
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            {username.clientModule && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/clients")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.Clients" />
                                                    </div>
                                                    {recommendationNotification !=
                                                        0 && (
                                                        <div
                                                            className={
                                                                alert_style
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="home.newRecos"
                                                                values={{
                                                                    number: recommendationNotification,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </button>
                                            )}
                                            {username.leadModule && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/leads")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.Leads" />
                                                    </div>
                                                    {leadNotification != 0 && (
                                                        <div
                                                            className={
                                                                alert_style
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="home.newLeads"
                                                                values={{
                                                                    number: leadNotification,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </button>
                                            )}
                                            {username.trainingModule && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/training")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.Training" />
                                                    </div>
                                                    <div
                                                        className={alert_style}
                                                    >
                                                        <FormattedMessage
                                                            id="home.newTask"
                                                            values={{
                                                                number: 1,
                                                            }}
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            {username.productModule && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/products")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.Product" />
                                                    </div>
                                                </button>
                                            )}
                                            {!username.manager && (
                                                <button
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/profile")
                                                    }
                                                >
                                                    <div className="">
                                                        Profile
                                                    </div>
                                                </button>
                                            )}
                                            {username.manager && (
                                                <div
                                                    className={widget_style}
                                                    onClick={() =>
                                                        nav("/dashboard")
                                                    }
                                                >
                                                    <div className="">
                                                        <FormattedMessage id="home.Management" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </IntlProvider>
            </section>
        </>
    );
};

export default Home;
