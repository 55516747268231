import React from "react";

function PopupModalSmall({ onCloseBtnPress, children }) {
	return (
		<div className="animate-fade-in-down  container flex justify-center w-full z-10"

			>
			<div className="inset-0 flex items-center justify-center bg-gray-800 bg-opacity-20 absolute">
				<div
					className="rounded p-2 bg-white"
					style={{
						overflowY: "auto",
						overflowX: "auto"}}
				>
					<div className="flex items-center justify-between grow" >
						<h3 className="text-xxl font-bold uppercase text-black ml-5 mt-5 pl-20"></h3>
						<div className="cursor-pointer">
							<svg
								onClick={onCloseBtnPress}
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="rgb(0 0 0)"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							</svg>
						</div>
					</div>
					{children}
				</div>
			</div>
		</div>
	);
}

export default PopupModalSmall;
