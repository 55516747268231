// import Switch from '../Switch';

/* eslint-disable */

import React, { useState } from "react";
import ControlledExpandedNode from "../ExpansionNode";

const headerStyle = "text-lg text-black pb-4 ml-8";

const dogFolder = {
    name: "",
    children: [
        {
            name: "Dog [2/100]",
            children: [{ name: "Dry [1/100]" }, { name: "Wet [1/100]" }],
        },
    ],
};

const catFolder = {
    name: "",
    children: [
        {
            name: "Cat [0/100]",
            children: [
                { name: "Dry [0/100]" },
                {
                    name: "Wet [0/100]",
                },
            ],
        },
    ],
};

const CollapseAssortment = ({ children }) => {
    const [open, setOPen] = useState(true);

    const toggle = () => {
        setOPen(!open);
    };

    return (
        <div
            className="touch-none relative mt-4 text-left text-black bg-white ml-3 mr-3 md:mr-0 border-2 border-gray-100 rounded-md"
            style={{
                maxHeight: 350,
            }}
        >
            <div className="ml-5 mr-5 mt-5 ">
                <div className="flex w-full flex-row">
                    <div className="flex w-full flex-row pt-2 items-center pb-4">
                        <div className="flex float-left">
                            <embed
                                src="https://www.svgrepo.com/show/456886/hierarchy-circle.svg"
                                alt="triangle with all three sides equal"
                                height="20px"
                                width="20px"
                            />
                        </div>
                        <div className="flex text-lg md:text-base xl:text-lg text-black w-5/6 ml-2 mt-1">
                            Assortment
                        </div>

                        <div className="flex-none pl-20 xl:w-1/6 md:w-1/6 sm:w-1/6">
                            <button onClick={toggle}>
                                <embed
                                    className="mr-0"
                                    src="https://cdn-icons-png.flaticon.com/512/60/60781.png"
                                    width="15px"
                                    height="15px"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                {open && (
                    // <div
                    //     className="overflow-hidden overflow-y-scroll mb-5"
                    //     style={{
                    //         maxHeight: 250,
                    //     }}
                    // >
                    //     {children}
                    // </div>
                    <div>
                        <div className="border-2 border-gray-100 rounded-sm p-2 w-full">
                            <div className="flex flex-row font-semibold text-sm">
                                Reference Overview
                            </div>
                            <div className="flex flex-row">
                                <ControlledExpandedNode folder={dogFolder} />
                                <ControlledExpandedNode folder={catFolder} />
                            </div>
                        </div>
                        <div className="border-2 border-gray-100 rounded-sm p-2 mt-2 mb-2">
                            <div className="flex flex-row font-semibold text-sm mt-2">
                                Ongoing Events
                            </div>
                            <div className="flex flex-row text-sm md:text-xs xl:text-sm mb-3 mt-1">
                                <ul
                                    style={{
                                        listStyleType: "disc",
                                        // margin: 0,
                                        // padding: 0,
                                    }}
                                    className="ml-5"
                                >
                                    <li>
                                        <span className="font-semibold ">
                                            Promo "Dogust Delights"
                                        </span>{" "}
                                        - 50% on dog treats - 01.08.2024 -
                                        31.08.2024
                                    </li>
                                    <li>
                                        <span className="font-semibold">
                                            Promo "Furry Friday"{" "}
                                        </span>{" "}
                                        - 50% on cat treats - 19.01.2024
                                    </li>
                                    <li>
                                        <span className="font-semibold">
                                            Cashback{" "}
                                        </span>{" "}
                                        - 21.01.2024 - 26.01.2024{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CollapseAssortment;
