import React from "react";

const Product = ({ product }) => {
    return (
        product && (
            <>
                <div className="flex items-center mt-2  mb-4 text-black">
                    <div className="flex flex-row items-center p-2 mb-1 bg-white border-2 border-gray-100 rounded">
                        <div className="float-left pl-2 w-1/4">
                            <img src={product?.product_img}></img>
                        </div>
                        <div
                            className="float-left ml-7 text-xs w-3/4 text-left"
                            // style={{ maxWidth: 200, minWidth: 250 }}
                        >
                            <p className="text-sm md:text-xs xl:text-sm">
                                <b>{product?.product_name}</b>
                            </p>
                            <p className="mt-2 md:mt-1 xl:md-2">
                                <b>Description:</b>{" "}
                                {product?.product_desc &&
                                product.product_desc.length > 50
                                    ? `${product.product_desc.substring(
                                          0,
                                          50
                                      )}...`
                                    : product.product_desc}
                            </p>
                            {/* <p>
                                <b>Degré d’alcool:</b>{" "}
                                {product?.product_level_3}
                            </p> */}
                            <p className="mt-2 md:mt-0 xl:md-2">
                                <b>Stock:</b> {product?.stock}
                            </p>
                            {/* <p><b>Price:</b> €19.80</p> */}
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export default Product;
