import React from "react";

import Collapsible from "../Collapsible";

// function setColor(read) {
// 	let result;
// 	if (read) {
// 		result = '#000000';
// 	} else {
// 		result = '#E0115F';
// 	}
// 	return result;
//   }

const List = ({
    recommendation,
    getRecommendations,
    clientInfo,
    client_id,
}) => {
    return (
        recommendation && (
            <>
                {/* <nobr className="font-semibold" style={{color:setColor(recommendation.read)}}> [NEW!]</nobr>) */}
                <li className="text-sm p-2 mb-1 bg-white border-2 border-gray-100 rounded w-full">
                    <Collapsible
                        item={recommendation}
                        text={recommendation.task_desc}
                        read={recommendation.read}
                        getRecommendations={getRecommendations}
                        client_id={client_id}
                        clientInfo={clientInfo}
                    />
                    {/* {!recommendation.read && (<span className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full" data-config-id="change2" style={{fontSize:10}}>New!</span>)} */}
                </li>
            </>
        )
    );
};

export default List;
