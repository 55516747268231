/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    DirectionsRenderer,
} from "@react-google-maps/api";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const containerStyle = {
    width: "1600px",
    // height: "450px",
};

// const containerStyle = {
//     position: "relative",
//     width: "1000px",
//     height: "450px",
// };

const overlayStyle = {
    position: "absolute",
    top: 50,
    left: "calc(50% + 200px)",
    right: 200,
    bottom: 800,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "auto", // Allows clicks to pass through the overlay to the map
    // Customize the overlay style as needed
    // backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "15px", // Adjust the radius as needed
    borderColor: "black",
    border: "1px",
};

const center = {
    lat: 48.8738428,
    lng: 2.3240815,
};

// const routes = [
//     {
//         location: {
//             id: "ID of FIrst Place",
//             lat: 40.756795,
//             lng: -73.954298,
//         },
//     },
//     {
//         location: {
//             id: "ID of Second Place",
//             lat: 40.753167,
//             lng: -73.96812,
//         },
//     },
//     {
//         location: {
//             id: "ID of Third Place",
//             lat: 40.853167,
//             lng: -73.96812,
//         },
//     },
// ];

const exampleMapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#444444",
            },
        ],
    },
    // {
    //     featureType: "landscape",
    //     elementType: "all",
    //     stylers: [
    //         {
    //             color: "#f2f2f2",
    //         },
    //     ],
    // },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#46bcec",
            },
            {
                visibility: "on",
            },
        ],
    },
];

const Map = ({ cards }) => {
    const [directions, setDirections] = useState(null);
    let directionsService;

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyAM7Y8grdI5KJ6kNCTUbQrdf6ciXTZ2x8s",
    });

    useEffect(() => {
        // console.log("Cards changed:", cards);

        // if (cards !== null) {
        //     getCoordinates(cards);
        // }

        // console.log("Cards changed:", directions);
        // // You can perform additional actions here based on the card changes
        // // ...
        try {
            // console.log("Cards changed:", cards);

            if (cards !== null) {
                getCoordinates(cards);
            }

            console.log("Cards changed:", directions);
            // You can perform additional actions here based on the card changes
            // ...
        } catch (error) {
            // console.error("An error occurred in useEffect:", error);
            // You can add additional error handling logic if needed
        }
    }, [cards]);

    const getCoordinates = (cards) => {
        directionsService = new window.google.maps.DirectionsService();

        // const routesCopy = cards.map((route) => {
        //     return {
        //         location: { lat: route.location.lat, lng: route.location.lng },
        //         stopover: true,
        //     };
        // });
        const routesCopy = Object.values(cards).map((card) => {
            return {
                location: { lat: card.location.lat, lng: card.location.lng },
                stopover: true,
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.pop().location;

        const waypoints = routesCopy;

        console.log(origin, destination, waypoints);
        getDirection(origin, destination, waypoints);
    };

    const onMapLoad = (map) => {
        directionsService = new window.google.maps.DirectionsService();

        // const routesCopy = cards.map((route) => {
        //     return {
        //         location: { lat: route.location.lat, lng: route.location.lng },
        //         stopover: true,
        //     };
        // });
        const routesCopy = Object.values(cards).map((card) => {
            return {
                location: { lat: card.location.lat, lng: card.location.lng },
                stopover: true,
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                      routesCopy[0].location.lat,
                      routesCopy[0].location.lng
                  )
                : routesCopy.pop().location;

        const waypoints = routesCopy;

        console.log(origin, destination, waypoints);
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: window.google.maps.TravelMode.DRIVING,
                      waypoints: waypoints,
                  },
                  (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                          setDirections(result);
                      } else {
                          //   console.error(`Error fetching directions ${result}`);
                      }
                  }
              )
            : directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: window.google.maps.TravelMode.DRIVING,
                  },
                  (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                          setDirections(result);
                      } else {
                          //   console.error(`Error fetching directions ${result}`);
                      }
                  }
              );
    };

    const openInGoogleMaps = () => {
        if (directions && directions.request) {
            const { request } = directions;

            const originString = `${request.origin.location.lat()},${request.origin.location.lng()}`;
            const destinationString = `${request.destination.location.lat()},${request.destination.location.lng()}`;
            const waypointsString = request.waypoints
                ? request.waypoints
                      .map(
                          (waypoint) =>
                              `${waypoint.location.lat()},${waypoint.location.lng()}`
                      )
                      .join("|")
                : "";

            const url = `https://www.google.com/maps/dir/?api=1&origin=${originString}&destination=${destinationString}&waypoints=${waypointsString}&travelmode=driving`;
            window.open(url, "_blank");
        }
    };

    const online = true;

    const [locale, setLocale] = useState();

    useEffect(() => {
        // // Fetch the user's name from the client
        // getUserName();
        // getLeadNotifications();
        // getRecommendationNotifications();
        // // setRefreshing(false);
        // if (!localStorage.getItem("language")) {
        //     fetchUserData();
        // } else {
        //     setLocale(localStorage.getItem("language"));
        // }
        // setRefreshing(true);
        const fetchData = async () => {
            if (!localStorage.getItem("language")) {
                console.log("No language");
                await fetchUserData();
            } else {
                setLocale(localStorage.getItem("language"));
            }
        };
        fetchData();
    });

    return isLoaded ? (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="flex w-3/5 xl:w-2/3 hidden md:flex">
                {online && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={12}
                        onLoad={onMapLoad}
                        options={{
                            styles: exampleMapStyles,
                            mapTypeControl: false, // Disable map type control
                            streetViewControl: false, // Disable street view control
                            zoomControl: false, // Disable zoom in and zoom out controls
                        }}
                        className="flex"
                    >
                        {directions !== null && (
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    polylineOptions: {
                                        strokeColor: "red",
                                        strokeOpacity: 1.0,
                                        strokeWeight: 5,
                                    },
                                }}
                            />
                        )}
                    </GoogleMap>
                )}
                {!online && (
                    <div className="flex flex-row items-center w-full">
                        <div className="flex flex-col items-center w-full">
                            <div>You are not online</div>
                        </div>
                    </div>
                )}
                <div style={overlayStyle}>
                    <FormattedMessage
                        id="map.SearchClients"
                        defaultMessage="search"
                    >
                        {(placeholder) => (
                            <input
                                type="text"
                                className={`mt-1 block w-full px-3 py-2 bg-white border border-black rounded-full text-sm shadow-sm placeholder-slate-400
                                          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-callToAction
                                          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                          invalid:border-pink-500 invalid:text-pink-600
                                          focus:invalid:border-pink-500 focus:invalid:ring-pink-500 w-full`}
                                // style={{ minWidth: 350 }}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>

                {/* <button onClick={openInGoogleMaps}>Open in Google Maps</button> */}
            </div>
        </IntlProvider>
    ) : (
        <></>
    );
};

export default React.memo(Map);
