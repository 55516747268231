import React from "react";
import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";

// const folder = {
//     name: "",
//     children: [
//         {
//             name: "Dog",
//             children: [
//                 { name: "Avocados" },
//                 { name: "Bananas" },
//                 { name: "Berries" },
//                 { name: "Oranges" },
//                 { name: "Pears" },
//             ],
//         },
//         {
//             name: "Cat",
//             children: [
//                 { name: "Apple Juice" },
//                 { name: "Chocolate" },
//                 { name: "Coffee" },
//                 {
//                     name: "Tea",
//                     children: [
//                         { name: "Black Tea" },
//                         { name: "Green Tea" },
//                         { name: "Red Tea" },
//                         { name: "Matcha" },
//                     ],
//                 },
//             ],
//         },
//     ],
// };

// const data = flattenTree(folder);

function ControlledExpandedNode({ folder }) {
    const data = flattenTree(folder);
    // const [expandedIds, setExpandedIds] = useState();

    // const onKeyDown = (e) => {
    //     if (e.key === "Enter") {
    //         getAndSetIds();
    //     }
    // };

    // const getAndSetIds = () => {
    //     setExpandedIds(
    //         document
    //             .querySelector("#txtIdsToExpand")
    //             .value.split(",")
    //             .filter((val) => !!val.trim())
    //             .map((x) => {
    //                 if (isNaN(parseInt(x.trim()))) {
    //                     return x;
    //                 }
    //                 return parseInt(x.trim());
    //             })
    //     );
    // };

    return (
        <div className="w-1/2">
            {/* <div>
                <label htmlFor="txtIdsToExpand">
                    Comma-delimited list of branch node IDs to expand:
                </label>
                <input id="txtIdsToExpand" type="text" onKeyDown={onKeyDown} />
                <button onClick={() => getAndSetIds()}>Set</button>
            </div> */}
            {/* <div>
                <button onClick={() => setExpandedIds([])}>
                    Clear all expanded nodes
                </button>
            </div> */}
            <div className="checkbox">
                <TreeView
                    data={data}
                    aria-label="Controlled expanded node tree"
                    // expandedIds={expandedIds}
                    // defaultExpandedIds={[]}
                    nodeRenderer={({
                        element,
                        isBranch,
                        isExpanded,
                        isDisabled,
                        getNodeProps,
                        level,
                        handleExpand,
                    }) => {
                        return (
                            <div
                                {...getNodeProps({ onClick: handleExpand })}
                                style={{
                                    marginLeft: 40 * (level - 1),
                                    opacity: isDisabled ? 0.5 : 1,
                                }}
                                className="flex flex-row items-center mt-1"
                            >
                                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                <span className="name text-sm">
                                    {element.name}
                                </span>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );
    return <IoMdArrowDropright className={classes} />;
};

export default ControlledExpandedNode;
