import React, { useState, useEffect } from "react";

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const CustomerDetails = ({ customer }) => {
    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);
    var alert_color = [];
    var font_type = [];
    if (
        customer.churn_probability == "Élevé" ||
        customer.churn_probability == "High"
    ) {
        alert_color = "#f43f5e";
        font_type = "bold";
    } else {
        alert_color = "text-gray-800";
    }
    return (
        customer && (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="mt-2 text-black flex-col">
                        <div className="mb-2 flex-row">
                            <div
                                className="float-left mr-2 text-xs flex-col"
                                style={{ minWidth: 120, maxWidth: 100 }}
                            >
                                <h1 className="font-semibold  text-black">
                                    <FormattedMessage
                                        id="client.contact_name"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1>{customer.contact}</h1>
                            </div>
                            <div className="text-xs">
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.customer_id"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1 className="">{customer.id}</h1>
                            </div>
                        </div>
                        <div className="mb-2 flex-row">
                            <div
                                className="float-left mr-2 text-xs flex-col"
                                style={{ minWidth: 120, maxWidth: 100 }}
                            >
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.adress"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1>{customer.location}</h1>
                            </div>
                            <div className="text-xs flex-col">
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.contact_number"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1>{customer.number}</h1>
                            </div>
                        </div>
                        <div className="mb-2 flex-row">
                            <div
                                className="float-left mr-2 text-xs flex-col"
                                style={{ minWidth: 120, maxWidth: 100 }}
                            >
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.total_sales"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1>{customer.sales} €</h1>
                            </div>
                            <div className="text-xs flex-col">
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.customer_type"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1>{customer.type}</h1>
                            </div>
                        </div>
                        <div className="mb-2 flex-row">
                            <div
                                className="float-left mr-2 text-xs flex-col"
                                style={{ minWidth: 120, maxWidth: 100 }}
                            >
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.contract_type"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                {customer.id == 1 && <h1>Bière</h1>}
                                {customer.id != 1 && <h1>-</h1>}
                            </div>
                            <div className="text-xs flex-col">
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.contract_value"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                {customer.id == 1 && <h1>50 000 €</h1>}
                                {customer.id != 1 && <h1>-</h1>}
                            </div>
                        </div>

                        <div className="mb-2 flex-row">
                            <div
                                className="float-left mr-2 text-xs flex-col"
                                style={{ minWidth: 120, maxWidth: 100 }}
                            >
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.contract_end"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                {customer.id == 1 && <h1>01.08.2024</h1>}
                                {customer.id != 1 && <h1>-</h1>}
                            </div>
                            <div className="text-xs flex-col">
                                <h1 className="font-semibold text-black">
                                    <FormattedMessage
                                        id="client.churn_probability"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                                <h1
                                    className=""
                                    style={{
                                        color: alert_color,
                                        fontWeight: font_type,
                                    }}
                                >
                                    {customer.churn_probability}
                                </h1>
                            </div>
                        </div>
                        {/* <div className="mb-2 flex-row">
                        <div
                            className="float-left mr-2 text-xs flex-col"
                            style={{ minWidth: 120 }}
                        >
                            <h1 className="font-semibold text-black">
                                Credit Score
                            </h1>
                            <h1 className="">{customer.last_purchase}</h1>
                        </div>
                        <div className="text-xs flex-col">
                            <h1 className="font-semibold text-black">
                                Overdue Amount
                            </h1>
                            <h1>{customer.price_segment}</h1>
                        </div>
                    </div> */}
                        {/* <div className="mb-5 mt-2 flex-row">
                        <div
                            className="float-left mr-2 text-xs mt-2 flex-col"
                            style={{ minWidth: 120, maxWidth: 100 }}
                        >
                            <h1 className="font-semibold text-black">
                                {" "}
                                Churn probability
                            </h1>
                            <h1
                                className=""
                                style={{
                                    color: alert_color,
                                    fontWeight: font_type,
                                }}
                            >
                                {customer.churn_probability}
                            </h1>
                        </div>
                    </div> */}
                    </div>
                </IntlProvider>
            </>
        )
    );
};

export default CustomerDetails;
