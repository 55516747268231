import React, { useEffect, useState } from "react";

// import { Navigate } from "react-router-dom";
// import * as moment from "moment";
// import jwtDecode from "jwt-decode";
/*eslint-disable*/

// import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import Navigator from "../../components/Navigator";

import FastAPIClient from "../../client";

import config from "../../config";

import ProductDetails from "../../components/ProductDetails";
// import { NotLoggedIn } from "./NotLoggedIn";
import { NotLoggedIn } from "./NotLoggedIn";
import Loader from "../../components/Loader";

import "./style.css";
import ScrollFilterButton from "../../components/ScrollFilterButton";
import Header from "../../components/Header";
/* eslint-disable */

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Products = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [products, setProducts] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [selectedMaison, setSelectedMaison] = useState([]);
    const [selectedColour, setSelectedColour] = useState([]);
    const [selectedCepage, setSelectedCepage] = useState([]);
    const [selectedAppelation, setSelectedAppelation] = useState([]);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
    }, []);

    const [refreshing, setRefreshing] = useState(true);
    // Selected Year filter
    const [searchValue, setSearchValue] = useState();

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         } else {
    //             setIsLoggedIn(false); // User token has expired or is not valid
    //             setRefreshing(false);
    //         }
    //     } else {
    //         setIsLoggedIn(false); // User token does not exist
    //         setRefreshing(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         }
    //     }
    // }, []);

    useEffect(() => {
        const getProducts = async () => {
            // const check_login = () => {
            //     const tokenString = localStorage.getItem("token");
            //     if (tokenString) {
            //         const token = JSON.parse(tokenString);
            //         const decodedAccessToken = jwtDecode(token.access_token);
            //         if (
            //             moment.unix(decodedAccessToken.exp).toDate() >
            //             new Date()
            //         ) {
            //             setIsLoggedIn(true);
            //             return true;
            //         }
            //     }
            // };
            // var login = await check_login();
            // if (login) {
            client.getAllProducts().then((data) => {
                setProducts(data?.results);
                setFilteredList(data?.results);
                // defineFilters();
                setLocale(localStorage.getItem("language"));
            });
            // }
        };
        getProducts();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setRefreshing(false);
        }, 1500); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer); // Clear the timer on component unmount
    }, [products]);

    // var defineFilters = () => {
    //     for (var i = 0; i < 3; i++) {
    //         try { throw i }
    //         catch { i } {
    //             setSearchTerms(search_terms_new.concat(i))
    //         }

    //     }
    // }

    const searchTerms = [];
    products.map((product) => {
        if (searchTerms.indexOf(product.product_name) === -1) {
            searchTerms.push(product.product_name);
        }
    });

    const product_level_1 = [];
    products.map((product) => {
        if (product_level_1.indexOf(product.product_level_1) === -1) {
            product_level_1.push(product.product_level_1);
        }
    });

    const product_level_2 = [];
    products.map((product) => {
        if (product_level_2.indexOf(product.product_level_2) === -1) {
            product_level_2.push(product.product_level_2);
        }
    });

    const product_year = [];
    products.map((product) => {
        if (product_year.indexOf(product.product_year) === -1) {
            product_year.push(product.product_year);
        }
    });

    const product_level_3 = [];
    products.map((product) => {
        if (product_level_3.indexOf(product.product_level_3) === -1) {
            product_level_3.push(product.product_level_3);
        }
    });

    const product_level_4 = [];
    products.map((product) => {
        if (product_level_4.indexOf(product.product_level_4) === -1) {
            product_level_4.push(product.product_level_4);
        }
    });

    const filterByName = (filteredData) => {
        // Avoid filter for null value
        if (!searchValue) {
            return filteredData;
        }

        const filterini = filteredData.filter((product) =>
            product.product_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
        );
        return filterini;
    };

    const filterByMaison = (filteredData) => {
        // Avoid filter for empty string
        if (selectedMaison.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedMaison.includes(lead.product_level_1)
        );

        return filtered;
    };
    const filterByColour = (filteredData) => {
        // Avoid filter for empty string
        if (selectedColour.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedColour.includes(lead.product_year)
        );

        return filtered;
    };
    const filterByCepage = (filteredData) => {
        // Avoid filter for empty string
        if (selectedCepage.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedCepage.includes(lead.product_level_2)
        );

        return filtered;
    };
    const filterByAppelation = (filteredData) => {
        // Avoid filter for empty string
        if (selectedAppelation.length == 0) {
            return filteredData;
        }

        const filtered = filteredData.filter((lead) =>
            selectedAppelation.includes(lead.product_level_3)
        );

        return filtered;
    };

    const handleMaisonChange = (maison, event) => {
        console.log(event.target.value);
        if (!selectedMaison.includes(maison)) {
            setSelectedMaison(selectedMaison.concat(maison));
        } else {
            let filteredArray = selectedMaison.filter(
                (item) => item !== maison
            );
            setSelectedMaison(filteredArray);
        }
    };

    const handleColourChange = (colour, event) => {
        console.log(event.target.value);
        if (!selectedColour.includes(colour)) {
            setSelectedColour(selectedColour.concat(colour));
        } else {
            let filteredArray = selectedColour.filter(
                (item) => item !== colour
            );
            setSelectedColour(filteredArray);
        }
    };

    const handleCepageChange = (cepage, event) => {
        console.log(event.target.value);
        if (!selectedCepage.includes(cepage)) {
            setSelectedCepage(selectedCepage.concat(cepage));
        } else {
            let filteredArray = selectedCepage.filter(
                (item) => item !== cepage
            );
            setSelectedCepage(filteredArray);
        }
    };

    const handleAppelationChange = (appelation, event) => {
        console.log(event.target.value);
        if (!selectedAppelation.includes(appelation)) {
            setSelectedAppelation(selectedAppelation.concat(appelation));
        } else {
            let filteredArray = selectedAppelation.filter(
                (item) => item !== appelation
            );
            setSelectedAppelation(filteredArray);
        }
    };

    // Toggle seletedYear state
    const handleSearchValue = (value) => {
        // const inputPrice = event.target.value;

        // if (inputPrice === selectedPrice) {
        // setSelectedPrice("");
        // } else {
        // setSelectedPrice(inputPrice);
        // }
        if (value != "") {
            setSearchValue(value);
            setSelectedMaison([]);
            setSelectedColour([]);
            setSelectedCepage([]);
            setSelectedAppelation([]);
            var clist = document.getElementsByTagName("input");
            for (var i = 0; i < clist.length; ++i) {
                clist[i].checked = false;
            }
            // setSelectedPrice(event.target.value);
            setBool(false);
        }
    };

    useEffect(() => {
        // setFilteredList(leads);
        var filteredData = products;
        filteredData = filterByName(filteredData);
        filteredData = filterByMaison(filteredData);
        filteredData = filterByColour(filteredData);
        filteredData = filterByCepage(filteredData);
        filteredData = filterByAppelation(filteredData);
        setFilteredList(filteredData);
    }, [
        selectedMaison,
        selectedColour,
        selectedCepage,
        selectedAppelation,
        searchValue,
    ]);

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         }
    //     }
    // }, []);

    const autocompleteMatch = (input) => {
        if (input == "") {
            return [];
        }
        var reg = new RegExp(input.toLowerCase());
        return searchTerms.filter(function (term) {
            if (term.toLowerCase().match(reg)) {
                return term;
            }
        });
    };

    const [test_val, setVal] = useState([]);
    const [show_bool, setBool] = useState(false);

    const showResults = (val) => {
        if (val.length > 0) {
            setBool(true);
        } else {
            setBool(false);
        }
        let terms = autocompleteMatch(val);

        setVal(terms);
    };

    const updateValue = (text) => {
        if (text != "") {
            setBool(false);
            showResults(text);
            return (document.getElementById("q").value = text);
        }
    };
    // document.getElementById('q').addEventListener('input', (e) => {
    //     if(e.currentTarget.value==''){
    //         setSearchValue()
    //     }
    //   })

    const resetFilters = () => {
        setSearchValue();
        setSelectedMaison([]);
        setSelectedColour([]);
        setSelectedCepage([]);
        setSelectedAppelation([]);
        setFilteredList(products);
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) {
            clist[i].checked = false;
        }
        // setSelectedPrice(event.target.value);
        setBool(false);
    };

    // const refreshing = false

    // if (!isLoggedIn) return <NotLoggedIn />;

    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;
    // if (refreshing) return <Loader />;

    // if (refreshing) {
    //     return <Loader />; // Display Loader while data is being fetched
    // }

    // if (!isLoggedIn) {
    //     return <NotLoggedIn />; // Display NotLoggedIn if user is not logged in
    // }

    // if (!isLoggedIn) return (<NotLoggedIn />)

    if (refreshing) {
        // Redirect to another page if user is not logged in
        return <Loader />;
    }

    // if (!isLoggedIn) {
    //     // Redirect to another page if user is not logged in
    //     return <Navigate to="/" />;
    // }

    const search = "product.search";
    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div
                    className="flex flex-row h-screen"
                    style={{
                        // flex: 1,
                        // paddingBottom: "10px",
                        overflowY: "auto",
                    }}
                >
                    <div className="w-full md:ml-20 xl:w-2/3 md:w-2/3 sm:w-2/3 px-15 text-left">
                        <div className="flex flex-col">
                            <div className="flex flex-col md:flex-row ml-5 md:mt-20">
                                <div>
                                    <h1 className="md:mb-12 text-xl text-black mt-5 ">
                                        <FormattedMessage
                                            id="product.product_catalogue"
                                            values={{ number: 1 }}
                                        />
                                        {/* Catalogue produits */}
                                    </h1>
                                </div>
                                <div className="md:ml-20 w-2/3">
                                    {/* <input type="text" onChange={(e) => handleSearchValue(e.target.value)} className={`text-black z-20 hover:text-black h-10 w-full max-w-xs m-auto pr-8 pl-5 z-0 border-2 border-black focus:shadow focus:outline focus:outline-slate-600`} style={{ minWidth: 350 }} placeholder="Search Leads..." /> */}
                                    <form autoComplete="off" className="mt-5">
                                        <FormattedMessage
                                            id="product.searchplaceholder"
                                            defaultMessage="search"
                                        >
                                            {(placeholder) => (
                                                <input
                                                    type="search"
                                                    name="q"
                                                    id="q"
                                                    onChange={(e) =>
                                                        showResults(
                                                            e.target.value
                                                        )
                                                    }
                                                    onClick={(e) =>
                                                        handleSearchValue(
                                                            e.target.value
                                                        )
                                                    }
                                                    className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
                              focus:outline-none focus:border-callToAction focus:ring-1 focus:ring-callToAction
                              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                              invalid:border-pink-500 invalid:text-pink-600
                              focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
                                                    style={{ minWidth: 350 }}
                                                    onTouchCancel={resetFilters}
                                                    // placeholder="Search Products..."
                                                    //   placeholder="Recherche produit..."
                                                    placeholder={placeholder}
                                                    // placeholder={
                                                    //     <FormattedMessage id="product.search" />
                                                    // }
                                                    // placeholder={intl.formatMessage(
                                                    //     { id: "product.search" }
                                                    // )}
                                                />
                                            )}
                                        </FormattedMessage>

                                        {show_bool && (
                                            <div
                                                id="result"
                                                className="text-black absolute z-40 text-sm border-2 border-callToAction rounded-b-md"
                                                style={{
                                                    minWidth: 350,
                                                }}
                                            >
                                                <ul>
                                                    {" "}
                                                    {test_val.length &&
                                                        test_val.map((res) => (
                                                            <li
                                                                name="hand"
                                                                key={res}
                                                                onClick={() => {
                                                                    updateValue(
                                                                        res
                                                                    );
                                                                    handleSearchValue(
                                                                        res
                                                                    );
                                                                }}
                                                            >
                                                                {res}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                            {/* <div className="mb-5">
                                    <div
                                        className="container px-5 text-left gray-50 items-top justify-center grid xl:grid-cols-2 lg:grid-cols-1 gap-4"
                                        // style={{
                                        //     minHeight: 600,
                                        //     maxHeight: 850,
                                        // }}
                                    >
                                        {filteredList.length &&
                                            filteredList.map((prod) => (
                                                <ProductDetails
                                                    product={prod}
                                                    key={prod.product_id}
                                                />
                                            ))}
                                    </div>
                                </div>
                                <div className="flex flex-col items-center md:hidden fixed bottom-0 left-0 right-0 z-50">
                                    <div className="flex flex-row border-client border-2 rounded-full items-center w-1/3 p-2">
                                        <div className="flex flex-row ml-7">
                                            <img
                                                src="https://www.svgrepo.com/show/532169/filter.svg"
                                                height="15"
                                                width="15"
                                            />
                                            <h1>Filter</h1>
                                        </div>
                                    </div>
                                </div> */}

                            <div className="mb-5 relative mt-5">
                                <div className="container px-5 text-left gray-50 items-top justify-center grid xl:grid-cols-2 lg:grid-cols-1 gap-4">
                                    {filteredList.length &&
                                        filteredList.map((prod) => (
                                            <ProductDetails
                                                product={prod}
                                                key={prod.product_id}
                                                locale={locale}
                                            />
                                        ))}
                                </div>
                            </div>

                            {/* This div is absolutely positioned at the bottom of the screen and appears on top of the other content */}
                            {/* <div className="flex flex-col items-center md:hidden absolute bottom-20 left-0 right-0">
                                    <div className="flex flex-row border-client bg-white border-2 rounded-full items-center w-1/3 p-2">
                                        <div className="flex flex-row ml-7">
                                            <img
                                                src="https://www.svgrepo.com/show/532169/filter.svg"
                                                height="15"
                                                width="15"
                                            />
                                            <h1>Filter</h1>
                                        </div>
                                    </div>
                                </div> */}
                            <ScrollFilterButton />
                        </div>
                    </div>
                    <div className="w-full xl:w-1/5 md:w-1/5 sm:w-1/5 pl-20 sticky top-20 z-50 hidden md:block">
                        <div className=" flex flex-col float-left mt-40 text-left sticky top-40 z-50">
                            <span className="font-bold mt-30">
                                {/* {filteredList.length} Product(s) */}
                                {filteredList.length}{" "}
                                <FormattedMessage
                                    id="product.products"
                                    values={{ number: 1 }}
                                />
                            </span>
                            {/* <div className="mt-5 text-sm mb-3">Maison</div>
                                {product_level_1 &&
                                    product_level_1.map((level) => (
                                        <label
                                            key={level}
                                            className="custom_check"
                                        >
                                            {level}
                                            <input
                                                key={level}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleMaisonChange(level, e)
                                                }
                                            />{" "}
                                            <span
                                                key={level}
                                                className="checkmark"
                                            ></span>
                                        </label>
                                    ))} */}
                            {/* <div className="year-filter text-black text-sm font-semibold mb-3 mt-5">
                                    Type
                                </div>
                                <div className="text-black">
                                    {product_level_4 &&
                                        product_level_4.map((level) => (
                                            <label
                                                key={level}
                                                className="custom_check"
                                            >
                                                {level}
                                                <input
                                                    key={level}
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                        handleColourChange(
                                                            level,
                                                            e
                                                        )
                                                    }
                                                />{" "}
                                                <span
                                                    key={level}
                                                    className="checkmark"
                                                ></span>
                                            </label>
                                        ))}
                                </div> */}
                            <div className="year-filter mt-5 text-black font-semibold text-sm mb-3">
                                {/* Status */}
                                {/* Alcohol */}
                                <FormattedMessage id="product.brand" />
                                {/* Brand */}
                            </div>
                            <div className="text-black">
                                {product_level_2 &&
                                    product_level_2.map((level) => (
                                        <label
                                            key={level}
                                            className="custom_check"
                                        >
                                            {level}
                                            <input
                                                key={level}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleCepageChange(level, e)
                                                }
                                            />{" "}
                                            <span
                                                key={level}
                                                className="checkmark"
                                            ></span>
                                        </label>
                                    ))}
                            </div>
                            <div className="year-filter font-semibold text-black text-sm mb-3">
                                {/* Lifestages */}
                                {/* Category */}
                                {/* Type d'alcool */}
                                <FormattedMessage id="product.alcohol" />
                            </div>
                            <div className="text-black">
                                {product_level_3 &&
                                    product_level_3.map((level) => (
                                        <label
                                            key={level}
                                            className="custom_check"
                                        >
                                            {level}
                                            <input
                                                key={level}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleAppelationChange(
                                                        level,
                                                        e
                                                    )
                                                }
                                            />{" "}
                                            <span
                                                key={level}
                                                className="checkmark"
                                            ></span>
                                        </label>
                                    ))}
                            </div>
                            <hr className="my-8 h-px bg-black border-0 black"></hr>
                            <label
                                className="text-xs font-bold"
                                onClick={resetFilters}
                            >
                                {" "}
                                x Remove all filters
                            </label>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
};

export default Products;
