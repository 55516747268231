import React, { useState, useEffect } from "react";

const ScrollFilterButton = () => {
    const [showFilter, setShowFilter] = useState(true);

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            setShowFilter(true);

            // Clear the previous timeout if it exists
            clearTimeout(timeoutId);

            // Set a timeout to hide the filter button after a couple of seconds
            timeoutId = setTimeout(() => {
                setShowFilter(false);
            }, 2000);
        };

        const handleScrollStop = () => {
            // Clear the previous timeout if it exists
            clearTimeout(timeoutId);

            // Set a timeout to hide the filter button after a couple of seconds
            timeoutId = setTimeout(() => {
                setShowFilter(false);
            }, 2000);
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Attach the scroll stop event listener
        window.addEventListener("scroll", handleScrollStop);

        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", handleScrollStop);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div
            className={`flex flex-col items-center md:hidden absolute ${
                showFilter ? "bottom-20" : "-bottom-20"
            } left-0 right-0 transition-all duration-300`}
        >
            <div className="flex flex-row border-client bg-white border-2 rounded-full items-center w-1/3 p-2">
                <div className="flex flex-row ml-7">
                    <img
                        src="https://www.svgrepo.com/show/532169/filter.svg"
                        height="15"
                        width="15"
                    />
                    <h1>Filter</h1>
                </div>
            </div>
        </div>
    );
};

export default ScrollFilterButton;
