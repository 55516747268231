import React from "react";

export class StepThree extends React.Component {
    constructor() {
        super();
        this.state = {
            password: "",
            passwordConfirm: "",
        };
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handlePasswordConfirmChanged =
            this.handlePasswordConfirmChanged.bind(this);
    }

    handlePasswordChanged(event) {
        this.setState({ password: event.target.value });
    }

    handlePasswordConfirmChanged(event) {
        this.setState({ passwordConfirm: event.target.value });
    }

    render() {
        return (
            <div className="flex flex-row items-center justify-center mb-10 mt-10 ">
                <div className="flex xl:w-1/2 md:w-1/2 items-center justify-center">
                    {/* <img className="rounded-md" src='https://www.thespruceeats.com/thmb/3o0c9bNCt-xAsGpIFVHk37oRoCY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/senior-mans-hands-harvesting-grapes-from-vine-556415823-58339d1f5f9b58d5b1bf918a.jpg' width='500' /> */}
                    {/* <img className="rounded-md mt-10" src='https://brewnation.fr/wp-content/uploads/2020/01/71V3XmWTIML._SL1418_.jpg' width='500' /> */}
                    <img
                        className="rounded-md"
                        src="https://epc-champagne.com/app/uploads/2022/11/Franck-Pascal-2011-1.png"
                        width="800"
                    />
                </div>
                <div className="flex flex-col text-left xl:w-1/2 md:w-1/2  text-base">
                    {/* <span className="text-base font-semibold text-client">
                        Viticulture
                    </span>
                    <span className="text-lg font-semibold text-client">
                        Technical operations for production of the Cru
                    </span>{" "}
                    <br />
                    <span className="text-sm leading-6 text-gray-700">
                        Vineyards are harvested according to level of maturity
                        and grape profile. Precision plot selection, within the
                        limits of machine-picking, is accomplished by combining
                        plots with similar profiles for tailored vinification
                        processes. Plot and intra-plot selection is based on
                        vigour, grape load and soil type. Extraction is
                        controlled throughout the alcoholic fermentation stage
                        by pumping over, cap punching and racking: with specific
                        extraction protocols, according to maturity level and
                        grape profile.
                    </span>
                    <span className="text-sm leading-6 text-gray-700">
                        {" "}
                        <br />
                        Vatting periods vary between 15 and 30 days, depending
                        on the wine profile.
                    </span>{" "}
                    <br />
                    <span className="text-sm leading-6 text-gray-700">
                        Wine batches are kept in French oak barrels (1/3 new
                        barrels, 1/3 one-wine barrels and 1/3 two-wine barrels).
                        Maturing periods vary between 10 and 14 months,
                        depending on the structure of the wine.
                    </span>{" "}
                    <br />
                    <span className="text-sm leading-6 text-gray-700">
                        The cooperage methods selected for maturing Château
                        Patache d’Aux wines have evolved with time. Barrel
                        toasting levels are lower today than in the past. The
                        estate’s aim is to move towards a barrel profile
                        providing wine structure without toasted or grilled
                        aromas, for enhanced terroir definition.
                    </span> */}
                    <span className="text-base font-semibold text-client">
                        Terroir
                    </span>
                    <span className="text-lg font-semibold text-client">
                        UN TERROIR BIEN PARTICULIER
                    </span>{" "}
                    <br />
                    <span className="text-sm leading-6 text-gray-700">
                        Situé à Baslieux-Sous-Châtillon, dans la région de
                        Champagne, notre domaine familial est riche de plus d’un
                        siècle d’histoire et de savoir-faire. Depuis plus de 60
                        millions d’années, les vignes y poussent et s’adaptent
                        aux multiples changements climatiques.
                    </span>
                    <span className="text-sm leading-6 text-gray-700">
                        {" "}
                        <br />
                        Dotés d’un terroir peu habituel et d’un éclectisme
                        géologique sans pareil, nous exploitons un sol argileux,
                        comprenant 3 types de pierres : le calcaire dur, le
                        silex et la meulière. Et au-delà de la grande pente du
                        vignoble se trouvent également 2 ruisseaux, le Belval et
                        la Maquerelle.
                    </span>{" "}
                    <br />
                    <span className="text-sm leading-6 text-gray-700">
                        Ces lieux exceptionnels sont parfaits pour transformer
                        les méthodes de vinification utilisées dans la région et
                        favoriser une agriculture biodynamique pour l’ensemble
                        de notre production. Notre défi quotidien est de gérer
                        les nombreux changements de climats qui surviennent dans
                        la région et laisser la vigne se développer de façon
                        naturelle. C’est de cette façon que nous parvenons à
                        rendre la viticulture si vivante.
                    </span>{" "}
                    {/* <br />
                    <span className="text-sm leading-6 text-gray-700">
                        The cooperage methods selected for maturing Château
                        Patache d’Aux wines have evolved with time. Barrel
                        toasting levels are lower today than in the past. The
                        estate’s aim is to move towards a barrel profile
                        providing wine structure without toasted or grilled
                        aromas, for enhanced terroir definition.
                    </span> */}
                    {/* <span className="text-base font-semibold text-client">Beertender</span>
        <span className="text-lg font-semibold text-client">Product specifications</span> <br />
        <span className="text-sm leading-6 text-gray-700 mr-10">
        This reference machine accommodates a wide selection of 5-litre kegs, for an always fresh and tasty tapping ritual. Its top-of-the-range design, inspired by bar pressure columns, is an invitation to tasting. Take your time: the quality of the beer remains constant for up to 30 days after opening the keg.</span> */}
                    {/* <span className="text-sm leading-6 text-gray-700"> <br />
Vatting periods vary between 15 and 30 days, depending on the wine profile.
</span> <br />
<span className="text-sm leading-6 text-gray-700">
Wine batches are kept in French oak barrels (1/3 new barrels, 1/3 one-wine barrels and 1/3 two-wine barrels). Maturing periods vary between 10 and 14 months, depending on the structure of the wine.
</span> <br />
<span className="text-sm leading-6 text-gray-700">
The cooperage methods selected for maturing Château Patache d’Aux wines have evolved with time. Barrel toasting levels are lower today than in the past. The estate’s aim is to move towards a barrel profile providing wine structure without toasted or grilled aromas, for enhanced terroir definition.
</span> */}
                </div>
            </div>
        );
    }
}
