import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import build_param from './build_params.json';
import { isPlatform } from "@ionic/react";

const frontendPath = build_param.frontendPath;
const Auth0_domain = build_param.domain;
const Auth0_clientId = build_param.client_id;

console.log(Auth0_domain)
console.log(Auth0_clientId)

const iosOrAndroid = isPlatform('hybrid');

const callbackUri = iosOrAndroid
  ? "com.mywaydev.app://dev-myway.eu.auth0.com/capacitor/com.mywaydev.app/callback"
  : `${frontendPath}/home`;

console.log(callbackUri)

ReactDOM.render(
    <React.StrictMode>
       <Auth0Provider
    // domain="dev-myway.eu.auth0.com"
    domain={Auth0_domain}
    // domain="auth-dev.myway.technology"
    // clientId="KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR" // What is the clientId?
    clientId={Auth0_clientId} // What is the clientId?
    useRefreshTokens={true}
    useRefreshTokensFallback={false}
    cacheLocation="localstorage"
    authorizationParams={{
      // redirect_uri: `${frontendPath}/home`,
      redirect_uri: callbackUri,
      audience: "https://www.api.myway.technology", // What is the audience?
      scope: "openid profile read:appointments" // what is the scope?
    }}

  >
    {/* <Auth0Provider
    domain="dev-myway.eu.auth0.com"
    clientId="KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR"
    useRefreshTokens={true}
    useRefreshTokensFallback={false}
    authorizationParams={{
      redirect_uri: "com.mywaydev.app://dev-myway.eu.auth0.com/capacitor/com.mywaydev.app/callback",
      audience: "https://www.api.myway.technology", // What is the audience?
      scope: "openid profile read:appointments" // what is the scope?
    }}
  > */}
      <App />
      </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
