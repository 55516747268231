/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// import Navigator from "../Navigator";
// import Footer from "../Footer";
import { useNavigate, Navigate } from "react-router-dom";
// import jwtDecode from "jwt-decode";
// import * as moment from "moment";
// import { useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Collapse from "../Collapse";
// import CollapseProduct from "../CollapseProduct";
import PopupModalSmall from "../ModalSmall/PopupModalSmall";
import Product from "../Product";
// import CollapseSales from "../CollapseSales";
import ProductSales from "../ProductSales";
import List from "../Listelement/List";
import CustomerDetails from "../CustomerDetails/CustomerDetails";
import Loader from "../Loader";

import FastAPIClient from "../../client";
import config from "../../config";

import GaugeChart from "react-gauge-chart";

import "./list.css";
import CollapseAssortment from "../CollapseAssortment";
import CollapseComponent from "../CollapseComponent";
// import Header from "../Header";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const ButtonComponent = () => {
    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    return (
        <div className="flex flex-row items-center w-1/4">
            <button
                onClick={() => handleButtonClick("yes")}
                style={{
                    // backgroundColor:
                    //     selectedButton === "yes" ? "green" : "white",
                    color: selectedButton === "yes" ? "#34d399" : "#9ca3af",
                    borderColor:
                        selectedButton === "yes" ? "#34d399" : "#9ca3af",
                    borderWidth: "2px", // specify the border width here
                    borderStyle: "solid", // set the border style to solid
                    borderRadius: "14px",
                }}
                disabled={selectedButton === "yes"}
                className="w-1/2 md:w-1/4"
            >
                Yes
            </button>
            <button
                onClick={() => handleButtonClick("no")}
                style={{
                    color: selectedButton === "no" ? "#f43f5e" : "#9ca3af",
                    // : selectedButton === "no" ? "red" : "white",
                    borderColor:
                        selectedButton === "no" ? "#f43f5e" : "#9ca3af",
                    borderWidth: "2px", // specify the border width here
                    borderStyle: "solid", // set the border style to solid
                    borderRadius: "14px",
                }}
                disabled={selectedButton === "no"}
                className="w-1/2 md:w-1/4 ml-4"
            >
                No
            </button>
        </div>
    );
};

function ClientDetailsPage() {
    const { clientId } = useParams();
    const [clientInfo, setClientInfo] = useState([]);
    const [product, setTobi] = useState([]);
    const [productsales, setProductSales] = useState([]);
    const [list, setList] = useState([]);
    const [reminder, setReminder] = useState([]);
    const [orderhistory, setClientHistory] = useState([]);
    const targetDivRef = useRef(null);
    const [refreshing, setRefreshing] = useState(true);

    const [salesCurrentYear, setSalesCurrentYear] = useState([]);
    const [percentageSalesChange, setPercentageSalesChange] = useState([]);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This navigates back one step in the history
    };

    // useEffect to calculate the sum when productsales changes
    useEffect(() => {
        // Calculate the sum of sales_current_year
        const sumSalesCurrentYear = productsales.reduce(
            (total, item) => total + item.sales_current_year,
            0
        );
        setSalesCurrentYear(sumSalesCurrentYear);

        // // Calculate the sum of sales_current_year
        const sumSalesPreviousYear = productsales.reduce(
            (total, item) => total + item.sales_previous_year,
            0
        );

        // setSalesPreviousYear(sumSalesPreviousYear);

        const percentage_change =
            (sumSalesCurrentYear - sumSalesPreviousYear) / sumSalesPreviousYear;

        setPercentageSalesChange(percentage_change);

        // Log or use the sum as needed
    }, [productsales]);

    const [toggle, setToggle] = useState(false);

    const headerStyle = "text-lg md:text-base xl:text-lg text-black pb-4 ml-8";

    const [reminderModal, setReminderModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    // const navigate = useNavigate();

    const handleStartVisit = () => {
        setToggle(true);
        // if (targetDivRef.current) {
        //     targetDivRef.current.scrollIntoView({ behavior: "smooth" });
        // }
        // You can add additional logic here if needed
    };

    const handleSaveVisit = () => {
        setToggle(false);
        // if (targetDivRef.current) {
        //     targetDivRef.current.scrollIntoView({ behavior: "smooth" });
        // }
        // You can add additional logic here if needed
    };

    const [user, setUser] = useState(false);

    useEffect(() => {
        getUserName();
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    useEffect(() => {
        // Scroll to the start of the target div when 'toggle' changes
        if (toggle && targetDivRef.current) {
            setTimeout(() => {
                targetDivRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100); // Adjust the delay as needed a
        }
    }, [toggle, targetDivRef]);

    useEffect(() => {
        // Fetch client details based on clientId
        // Use clientId to make an API call and setClientDetails with the data
        // Example: client.getClientDetails(clientId).then(data => setClientDetails(data));
        getProducts(clientId);
        getProductSales(clientId);
        getReminder(clientId);
        getClientInfo(clientId);
        getClientHistory(clientId);
        getRecommendations(clientId);
        targetDivRef.current = document.getElementById("yourTargetDivId");
        setRefreshing(false);
    }, []);

    const getProducts = (id) => {
        client.getProducts(id).then((data) => {
            setTobi(data?.results);
        });
    };

    const getProductSales = (id) => {
        client.getProductSales(id).then((data) => {
            setProductSales(data?.results);
        });
    };

    const getReminder = (id) => {
        client.getReminder(id).then((data) => {
            setReminder(data?.results);
        });
    };

    const getClientInfo = (id) => {
        client.getClient(id).then((data) => {
            setClientInfo(data);
        });
    };

    const getRecommendations = (id) => {
        client.getRecommendations(id).then((data) => {
            setList(data?.results);
        });
    };

    const getClientHistory = (id) => {
        client.getClientHistory(id).then((data) => {
            setClientHistory(data?.results);
        });
    };

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Calculate the previous year
    const previousYear = currentYear - 1;

    // useEffect(() => {
    //     const tokenString = localStorage.getItem("token");
    //     if (tokenString) {
    //         const token = JSON.parse(tokenString);
    //         const decodedAccessToken = jwtDecode(token.access_token);
    //         if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
    //             setIsLoggedIn(true);
    //         } else {
    //             setIsLoggedIn(false); // User token has expired or is not valid
    //             setRefreshing(false);
    //         }
    //     } else {
    //         setIsLoggedIn(false); // User token does not exist
    //         setRefreshing(false);
    //     }
    // }, []);

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    // if (!isLoggedIn) {
    //     // Redirect to another page if user is not logged in
    //     return <Navigate to="/" />;
    // }

    return (
        <section className="flex flex-row bg-white">
            {/* <div className="sticky z-50 border-r-1 border-white">
                <Navigator />
            </div>
            <section
                className="flex flex-col text-center w-full"
                style={{
                    maxHeight: "100vh",
                }}
            >
                {" "}
                <Header /> */}
            <div
                style={{
                    flex: 1,
                    overflowY: "auto",
                }}
            >
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <button
                        // onClick={() => navigate("/routing")}
                        onClick={handleGoBack}
                        className="bg-gray-800 p-1 rounded-md mt-5 ml-4 xl:ml-14 flex items-center justify-center"
                        style={{ width: "1.5rem", height: "1.5rem" }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="white"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                        >
                            <path
                                fillRule="evenodd"
                                d="M6.354 11.354a.5.5 0 0 1 0-.708L2.707 8l3.647-3.646a.5.5 0 1 1 .708.708L4.707 8l2.354 2.354a.5.5 0 0 1-.708.708z"
                            />
                            <path
                                fillRule="evenodd"
                                d="M3.5 8a.5.5 0 0 1 .5-.5h7.793a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"
                            />
                        </svg>
                    </button>

                    <div className="flex flex-row items-center mt-5">
                        <div className="xl:ml-10 pl-4 text-left text-black bg-white text-xl font-bold uppercase text-black">
                            {clientInfo?.name}
                        </div>
                        {user.routemodule && (
                            <button className=" ml-5 bg-white border border-buttonSecondaryBorder rounded-full text-buttonSecondaryFontColor font-semibold pl-2 pr-2 text-sm hover:bg-buttonSecondaryHoverBackground hover:text-buttonSecondaryHoverFontColor">
                                {" "}
                                + Route
                            </button>
                        )}
                    </div>
                    <div className="flex flex-col md:flex-row w-full xl:pr-10 xl:pl-10 mt-4">
                        <div className="float-left md:w-1/4 flex-col">
                            <div className="text-left text-black bg-white ml-3 mr-3 md:mr-0 border-2 border-gray-100 mb-3 rounded-md">
                                <div className="mb-5 mt-13 ml-4 pt-5">
                                    <div className="float-left mt-1">
                                        <embed
                                            src="https://www.svgrepo.com/show/41822/phonebook.svg"
                                            alt="triangle with all three sides equal"
                                            height="20px"
                                            width="20px"
                                        />
                                    </div>
                                    <h1 className={headerStyle}>
                                        <FormattedMessage
                                            id="client.client_details"
                                            values={{ number: 1 }}
                                        />
                                    </h1>
                                    {clientInfo && (
                                        <CustomerDetails
                                            customer={clientInfo}
                                            // key={clientInfo?.name}
                                        />
                                    )}
                                </div>
                            </div>
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.sales"
                                        values={{ number: 1 }}
                                    />
                                }
                                iconSrc="https://www.svgrepo.com/show/8125/money.svg"
                            >
                                <div
                                    className="p-2 mb-3 bg-white border-2 border-gray-100 rounded"
                                    // style={{ minWidth: 225 }}
                                >
                                    <div className="flex mb-3 items-center justify-between">
                                        <h3
                                            className="text-gray-700 md:text-sm"
                                            data-config-id="header2"
                                        >
                                            <FormattedMessage
                                                id="client.sales_ytd"
                                                values={{ number: 1 }}
                                            />
                                        </h3>
                                        <button className="focus:outline-none">
                                            <svg
                                                className="h-4 w-4 text-gray-200"
                                                viewBox="0 0 16 4"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-config-id="auto-svg-1-2"
                                            ></svg>
                                        </button>
                                    </div>
                                    <div className="flex items-center text-lg mb-3">
                                        {salesCurrentYear && (
                                            <span
                                                className="font-bold text-black md:text-base text-lg xl:text-lg"
                                                data-config-id="value2"
                                            >
                                                €{salesCurrentYear}
                                            </span>
                                        )}
                                        {percentageSalesChange &&
                                            percentageSalesChange &&
                                            percentageSalesChange >= 0 && (
                                                <span
                                                    className="inline-block ml-2 py-1 px-2 bg-emerald-400 text-white text-xs rounded-full"
                                                    data-config-id="change2"
                                                >
                                                    +
                                                    {(
                                                        percentageSalesChange *
                                                        100
                                                    ).toFixed(0)}
                                                    %
                                                </span>
                                            )}
                                        {percentageSalesChange &&
                                            percentageSalesChange < 0 && (
                                                <span
                                                    className="inline-block ml-2 py-1 px-2 bg-rose-500 text-white text-xs rounded-full"
                                                    data-config-id="change2"
                                                >
                                                    {(
                                                        percentageSalesChange *
                                                        100
                                                    ).toFixed(0)}
                                                    %
                                                </span>
                                            )}
                                        {/* {clientInfo &&
                                        clientInfo.sales_change == "NaN" && (
                                            <span
                                                className="inline-block ml-2 py-1 px-2 bg-emerald-400 text-white text-xxs font-semibold rounded-full"
                                                data-config-id="change2"
                                            >
                                                New
                                            </span>
                                        )} */}
                                    </div>
                                    {clientInfo &&
                                        clientInfo.sales_change &&
                                        clientInfo.sales_change.substring(
                                            0,
                                            1
                                        ) == "+" && (
                                            <div className="relative w-full h-1 mb-2 bg-gray-200 rounded">
                                                <div className="absolute top-0 left-0 w-4/6 h-full bg-emerald-400 rounded"></div>
                                            </div>
                                        )}
                                    {clientInfo &&
                                        clientInfo.sales_change &&
                                        clientInfo.sales_change.substring(
                                            0,
                                            1
                                        ) == "-" && (
                                            <div className="relative w-full h-1 mb-2 bg-gray-200 rounded">
                                                <div className="absolute top-0 left-0 w-4/6 h-full bg-rose-500 rounded"></div>
                                            </div>
                                        )}
                                    {clientInfo &&
                                        clientInfo.sales_change == "NaN" && (
                                            <div className="relative w-full h-1 mb-2 bg-gray-200 rounded">
                                                <div className="absolute top-0 left-0 w-full h-full bg-gray-500 rounded"></div>
                                            </div>
                                        )}

                                    <p
                                        className="text-xs text-gray-600"
                                        data-config-id="desc2"
                                    >
                                        <FormattedMessage
                                            id="client.yearly_goal"
                                            values={{ number: 1 }}
                                        />
                                    </p>
                                </div>

                                <div className="p-2 bg-white border-2 border-gray-100 rounded text-left">
                                    <h3
                                        className="text-gray-700 pb-2 md:text-sm"
                                        data-config-id="header2"
                                    >
                                        <FormattedMessage
                                            id="client.product_sales"
                                            values={{ number: 1 }}
                                        />{" "}
                                        vs. {previousYear}
                                    </h3>
                                    <ProductSales productsales={productsales} />
                                </div>
                            </CollapseComponent>
                            {user.storemodule && (
                                <CollapseComponent
                                    text="Client History"
                                    iconSrc="https://www.svgrepo.com/show/355302/task.svg"
                                    isOpen={false}
                                >
                                    {orderhistory &&
                                        orderhistory.history &&
                                        orderhistory.history.map((prod) => (
                                            <div
                                                key={prod.product_id}
                                                className="list-wrapper mb-10"
                                            >
                                                {prod.type == "order" && (
                                                    <div className="flex flex-row items-center mt-10 relative w-full">
                                                        <div className="list-bullet w-1/5">
                                                            <img
                                                                src="https://www.svgrepo.com/show/491386/credit-card.svg"
                                                                height="15"
                                                                width="15"
                                                                alt="Ordered"
                                                            />
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xxs">
                                                            <span className="font-semibold">
                                                                {
                                                                    prod.created_at
                                                                }
                                                            </span>
                                                        </div>
                                                        {/* <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xxs text-left">
                                                            <span className="">
                                                                Ordered{" "}
                                                                {prod.quantity
                                                                    ? prod.quantity +
                                                                      " "
                                                                    : ""}
                                                                {prod.title} for{" "}
                                                                {prod.price}€
                                                                each.
                                                            </span>
                                                        </div> */}
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xxs text-left">
                                                            <span className="">
                                                                <FormattedMessage id="client.order_part1" />{" "}
                                                                {prod.quantity
                                                                    ? prod.quantity +
                                                                      " "
                                                                    : ""}
                                                                {prod.title}{" "}
                                                                <FormattedMessage id="client.order_part2" />{" "}
                                                                {prod.price}€
                                                                <FormattedMessage id="client.order_part3" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {prod.type == "visit" && (
                                                    <div className="flex flex-row items-center mt-10 relative w-full">
                                                        <div className="list-bullet w-1/5">
                                                            <img
                                                                src="https://www.svgrepo.com/show/491435/map-pin.svg"
                                                                height="15"
                                                                width="15"
                                                                alt="Ordered"
                                                            />
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xxs text-left">
                                                            <span className="font-semibold">
                                                                {
                                                                    prod.created_at
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xxs">
                                                            <span className="">
                                                                Visited by Chen.
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}{" "}
                                </CollapseComponent>
                            )}
                        </div>

                        <div className="flex flex-col float-left md:w-1/2">
                            <div
                                className="overflow-hidden text-left text-black bg-white ml-3 mr-3 md:mr-0 border-2 border-gray-100 rounded-md"
                                // style={{
                                //     minWidth: 550,
                                //     maxWidth: 450,
                                //     minHeight: 200,
                                // }}
                            >
                                <div className="float-left mb-2 mt-5 w-full">
                                    <div className="float-left mt-1 ml-5 mr-2">
                                        <embed
                                            src="https://www.svgrepo.com/show/12987/podium.svg"
                                            alt="triangle with all three sides equal"
                                            height="20px"
                                            width="20px"
                                        />
                                    </div>
                                    <div className="">
                                        <p className={headerStyle}>
                                            <FormattedMessage
                                                id="client.nba"
                                                values={{ number: 1 }}
                                            />{" "}
                                        </p>
                                    </div>

                                    <div className="w-full pr-5 pl-5">
                                        <ul className="display-inline text-gray-700 mb-5 text-sm w-full">
                                            {list.length > 0 &&
                                                list.map((item) => (
                                                    <List
                                                        recommendation={item}
                                                        key={item.task_id}
                                                        getRecommendations={
                                                            getRecommendations
                                                        }
                                                        clientInfo={clientInfo}
                                                        client_id={item.id}
                                                    />
                                                ))}
                                            {list.length == 0 && (
                                                <h1>
                                                    {/* You rock - no open tasks! */}
                                                    {/* Plus de tâches, vous êtes
                                                    géniale ! */}
                                                    <FormattedMessage id="client.notasks" />
                                                </h1>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                            className="overflow-hidden touch-none relative mt-4 text-left text-black bg-white ml-4 border-2 border-gray-100 rounded-md"
                            // style={{
                            //     maxWidth: 550,
                            // }}
                        >
                            <div className="flex flex-col mb mt-5 ml-4 mr-4 flex-inital">
                                <div
                                    className="flex flex-row items-center w-full"
                                    // style={{ minWidth: 550 }}
                                >
                                    <div className="float-left ml-1 mt-1">
                                        <embed
                                            src="https://www.svgrepo.com/show/521658/feedback.svg"
                                            alt="triangle with all three sides equal"
                                            height="20px"
                                            width="20px"
                                        />
                                    </div>
                                    <div className="w-4/6 mt-2 ml-2 text-lg">
                                        Feedback
                                    </div>

                                    <div className="flex w-full xl:w-1/4 md:w-1/4 sm:w-1/4 ml-20">

                                    </div>
                                </div>

                            </div>
                        </div> */}
                            <div
                                className="overflow-hidden touch-none relative mt-4 text-left text-black bg-white ml-3 mr-3 md:mr-0 border-2 border-gray-100 rounded-md mb-3"
                                // style={{
                                //     maxWidth: 550,
                                // }}
                            >
                                <div className="flex flex-col mb mt-5 ml-4 mr-4 flex-inital">
                                    <div
                                        className="flex flex-row items-center w-full"
                                        // style={{ minWidth: 550 }}
                                    >
                                        <div className="float-left ml-1 mt-1">
                                            <embed
                                                src="https://img.icons8.com/ios/512/leave.png"
                                                alt="triangle with all three sides equal"
                                                height="20px"
                                                width="20px"
                                            />
                                        </div>
                                        <div className="w-4/6 mt-2 ml-2 text-lg md:text-base xl:text-lg">
                                            <FormattedMessage
                                                id="client.reminder"
                                                values={{ number: 1 }}
                                            />{" "}
                                        </div>

                                        <div className="flex w-full xl:w-1/4 md:w-1/3 sm:w-1/3 ml-20">
                                            <a
                                                onClick={setReminderModal}
                                                className="ml-10 transition text-xxs ease-in-out border-chartMain border delay-150 hover:-translate-y-0 hover:scale-100  bg-white cursor-pointer hover:bg-chartMain hover:text-white text-chartMain font-bold px-2 py-1 mx-auto rounded"
                                            >
                                                +{" "}
                                                <FormattedMessage
                                                    id="client.reminder"
                                                    values={{ number: 1 }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <ul className="display-inline text-black mb-4 text-sm">
                                        {reminder.length > 0 ? (
                                            reminder.map((item) => (
                                                <li
                                                    className="flex flex-row text-sm bg-white rounded-md border-2 border-gray-100 mt-2 p-1 items-center"
                                                    key={item.subject}
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className="ml-5">
                                                        <span className="font-semibold">
                                                            {item.date}
                                                        </span>
                                                        <span className="ml-2 font">
                                                            {item.subject}
                                                        </span>
                                                    </div>
                                                    <img
                                                        src="https://www.svgrepo.com/show/488118/close-circle.svg"
                                                        height="15"
                                                        width="15"
                                                        className="mr-2"
                                                    />
                                                </li>
                                            ))
                                        ) : (
                                            // <h1 className="ml-5 mt-2">

                                            //     No reminder scheduled!
                                            // </h1>
                                            <h1 className="ml-5 mt-2">
                                                <FormattedMessage id="client.noreminder" />
                                            </h1>
                                        )}
                                    </ul>
                                </div>
                                {reminderModal && (
                                    <PopupModalSmall
                                        onCloseBtnPress={() => {
                                            // updateNotifications()
                                            setReminderModal(false);
                                        }}
                                    >
                                        <div className="text-black pl-10 pr-10">
                                            <div className="text-callToAction font-bold">
                                                Schedule reminder
                                            </div>
                                            <div className="flex flex-row">
                                                <div className="flex-none text-black text-xs font-bold pt-2">
                                                    Select date
                                                </div>
                                                <DatePicker
                                                    className="relative text-xxs rounded-md p-1 ml-3 border border-grey z-50"
                                                    selected={startDate}
                                                    onChange={(date) =>
                                                        setStartDate(date)
                                                    }
                                                    dateFormat="d MMMM yyyy"
                                                />
                                            </div>
                                            <div className="">
                                                <label className="font-bold text-xs text-black">
                                                    Subject
                                                </label>
                                            </div>
                                            <div>
                                                <textarea
                                                    rows="1"
                                                    cols="32"
                                                    className=" border-2 text-black text-xs"
                                                ></textarea>
                                            </div>
                                            <div className="pl-40">
                                                <a className="bg-callToAction text-white rounded-md p-1 text-xxs font-bold">
                                                    Save
                                                </a>
                                            </div>
                                        </div>
                                    </PopupModalSmall>
                                )}
                            </div>
                            {user.storemodule && <CollapseAssortment />}
                            {!user.storemodule && (
                                // <Collapse>
                                //     {orderhistory &&
                                //         orderhistory.history &&
                                //         orderhistory.history.map((prod) => (
                                //             <div
                                //                 key={prod.product_id}
                                //                 className="list-wrapper mb-10"
                                //             >
                                //                 {prod.type == "order" && (
                                //                     <div className="flex flex-row items-center mt-10 relative w-full">
                                //                         <div className="list-bullet w-1/5">
                                //                             <img
                                //                                 src="https://www.svgrepo.com/show/491386/credit-card.svg"
                                //                                 height="15"
                                //                                 width="15"
                                //                                 alt="Ordered"
                                //                             />
                                //                         </div>
                                //                         <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xs">
                                //                             <span className="font-semibold">
                                //                                 {
                                //                                     prod.created_at
                                //                                 }
                                //                             </span>
                                //                         </div>
                                //                         <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xs">
                                //                             <span className="">
                                //                                 Ordered{" "}
                                //                                 {prod.quantity
                                //                                     ? prod.quantity +
                                //                                       " "
                                //                                     : ""}
                                //                                 {prod.title} for{" "}
                                //                                 {prod.price}€
                                //                                 each.
                                //                             </span>
                                //                         </div>
                                //                     </div>
                                //                 )}
                                //                 {prod.type == "visit" && (
                                //                     <div className="flex flex-row items-center mt-10 relative w-full">
                                //                         <div className="list-bullet w-1/5">
                                //                             <img
                                //                                 src="https://www.svgrepo.com/show/491435/map-pin.svg"
                                //                                 height="15"
                                //                                 width="15"
                                //                                 alt="Ordered"
                                //                             />
                                //                         </div>
                                //                         <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xs">
                                //                             <span className="font-semibold">
                                //                                 {
                                //                                     prod.created_at
                                //                                 }
                                //                             </span>
                                //                         </div>
                                //                         <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xs">
                                //                             <span className="">
                                //                                 Visited by Chen.
                                //                             </span>
                                //                         </div>
                                //                     </div>
                                //                 )}
                                //             </div>
                                //         ))}{" "}
                                // </Collapse>
                                <CollapseComponent
                                    text={
                                        <FormattedMessage
                                            id="client.client_history"
                                            values={{ number: 1 }}
                                        />
                                    }
                                    iconSrc="https://www.svgrepo.com/show/355302/task.svg"
                                    isOpen={false}
                                >
                                    {orderhistory &&
                                        orderhistory.history &&
                                        orderhistory.history.map((prod) => (
                                            <div
                                                key={prod.product_id}
                                                className="list-wrapper mb-10"
                                            >
                                                {prod.type == "order" && (
                                                    <div className="flex flex-row items-center mt-10 relative w-full">
                                                        <div className="list-bullet w-1/5">
                                                            <img
                                                                src="https://www.svgrepo.com/show/491386/credit-card.svg"
                                                                height="15"
                                                                width="15"
                                                                alt="Ordered"
                                                            />
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xxs">
                                                            <span className="font-semibold">
                                                                {
                                                                    prod.created_at
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xxs text-left">
                                                            {/* <span className="">
                                                                Ordered{" "}
                                                                {prod.quantity
                                                                    ? prod.quantity +
                                                                      " "
                                                                    : ""}
                                                                {prod.title} for{" "}
                                                                {prod.price}€
                                                                each.
                                                            </span> */}
                                                            <span className="">
                                                                <FormattedMessage id="client.order_part1" />{" "}
                                                                {prod.quantity
                                                                    ? prod.quantity +
                                                                      " "
                                                                    : ""}
                                                                {prod.title}{" "}
                                                                <FormattedMessage id="client.order_part2" />{" "}
                                                                {prod.price}€{" "}
                                                                <FormattedMessage id="client.order_part3" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {prod.type == "visit" && (
                                                    <div className="flex flex-row items-center mt-10 relative w-full">
                                                        <div className="list-bullet w-1/5">
                                                            <img
                                                                src="https://www.svgrepo.com/show/491435/map-pin.svg"
                                                                height="15"
                                                                width="15"
                                                                alt="Ordered"
                                                            />
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-2/5 text-xxs text-left">
                                                            <span className="font-semibold">
                                                                {
                                                                    prod.created_at
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="text-sm bg-white rounded-md mt-2 p-1 w-4/5 text-xxs">
                                                            <span className="">
                                                                Visited by Chen.
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}{" "}
                                </CollapseComponent>
                            )}
                        </div>

                        <div className="flex flex-col overflow-hidden touch-none float-left md:w-1/4">
                            <CollapseComponent
                                text={
                                    <FormattedMessage
                                        id="client.product_reco"
                                        values={{ number: 1 }}
                                    />
                                }
                                iconSrc="https://www.svgrepo.com/show/161792/new-products-label.svg"
                            >
                                {product.length &&
                                    product.map((prod) => (
                                        <Product
                                            product={prod}
                                            key={prod.product_id}
                                        />
                                    ))}{" "}
                            </CollapseComponent>

                            {user.storemodule && (
                                <div className="ml-3 mr-3 mt-4 border-2 border-gray-100 bg-white rounded-md flex flex-col items-center">
                                    <div className="mt-2 flex w-full flex-row pt-2 items-center">
                                        <div className="flex float-left ml-4">
                                            <embed
                                                src="https://www.svgrepo.com/show/521242/store-1.svg"
                                                alt="triangle with all three sides equal"
                                                height="30px"
                                                width="30px"
                                            />
                                        </div>
                                        <div className="flex text-left text-lg md:text-base xl:text-lg text-black w-full ml-4">
                                            Perfect store score
                                        </div>

                                        {/* <div className="flex-none w-1/5 xl:w-1/6 md:w-1/6 sm:w-1/6"></div> */}
                                    </div>
                                    <div className="w-1/2 mt-3">
                                        <GaugeChart
                                            id="gauge-chart2"
                                            nrOfLevels={20}
                                            percent={0.86}
                                            animate={false}
                                            percent={0.87}
                                            colors={["#f43f5e", "#34d399"]}
                                            textColor="#000000"
                                            needleColor="#C0C0C0"
                                            needleBaseColor="#C0C0C0"
                                        />
                                    </div>
                                    <table className="table-auto text-xs mt-5 mb-5 float-left w-3/4 md:w-4/5 xl:w-3/4">
                                        <tbody>
                                            <tr className="">
                                                <td className="text-left font-semibold border-b pb-1">
                                                    Assortment Cat %
                                                </td>
                                                <td className="text-left pl-5 border-b pb-1">
                                                    60%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-semibold border-b pb-1 pt-1">
                                                    Assortment Dog %
                                                </td>
                                                <td className="text-left  pl-5 border-b pb-1 pt-1">
                                                    55%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-semibold border-b pb-1 pt-1">
                                                    Assortment %
                                                </td>
                                                <td className="text-left  pl-5 border-b pb-1 pt-1">
                                                    57%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-semibold  border-b pb-1 pt-1">
                                                    Planogram Execution %
                                                </td>
                                                <td className="text-left  pl-5 border-b pb-1 pt-1">
                                                    100%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-semibold pb-1 pt-1">
                                                    Secondary Display %
                                                </td>
                                                <td className="text-left  pl-5 pb-1 pt-1">
                                                    100%
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=""></div>
                    {user.storemodule && (
                        <div className="flex flex-col items-center">
                            <div className="flex flex-row w-full mt-5 mb-5 md:mb-5">
                                <div className="w-2/3 md:w-5/6"></div>

                                <button
                                    onClick={() => handleStartVisit()}
                                    className="bg-buttonMainBackground rounded-full text-buttonMainFontColor font-semibold w-1/3 md:w-1/6 pt-2 mr-5 md:mr-10 pb-2 drop-shadow-lg "
                                >
                                    Start visit
                                </button>

                                <button
                                    onClick={() => navigate("/orderform")}
                                    className="bg-buttonMainBackground rounded-full text-buttonMainFontColor font-semibold w-1/3 md:w-1/6 pt-2 mr-5 md:mr-20 pb-2 drop-shadow-lg "
                                >
                                    Order
                                </button>
                            </div>
                        </div>
                    )}
                    <div id="yourTargetDivId"></div>
                    {toggle && (
                        <section className="w-full bg-white flex flex-col items-center rounded-sm">
                            {/* <div
                            className="border-2 border-gray-200 rounded-md w-4/5 mb-5 text-left mt-10"
                            id="yourTargetDivId"
                        >
                            <div className="font-semibold ml-6 mt-4">
                                Next Best Actions
                            </div>
                            <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                <div className="w-3/4 text-sm">
                                    {" "}
                                    Share of shelf =&gt; 10% dog
                                </div>
                                <ButtonComponent />
                            </div>
                            <div className="flex flex-row p-4 ml-2 mr-4">
                                <div className="w-3/4 text-sm">
                                    {" "}
                                    Share of shelf =&gt; 15% cat
                                </div>
                                <ButtonComponent />
                            </div>
                        </div> */}
                            <div className="flex flex-col w-full md:w-4/5 items-center border-2 border-gray-100 rounded-md mt-4">
                                <div className="font-semibold ml-6 mt-4 text-left text-lg">
                                    Perfect Store Report
                                </div>
                                <div className="border-2 border-gray-200 rounded-md w-full md:w-4/5 mb-5 text-left mt-10">
                                    <div className="font-semibold ml-6 mt-4">
                                        Shelf share
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            Share of shelf =&gt; 10% dog
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            Share of shelf =&gt; 15% cat
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                </div>
                                <div className="border-2 border-gray-200 rounded-md w-full md:w-4/5 text-left mb-5">
                                    <div className="font-semibold ml-6 mt-4">
                                        Distributed SKU&apos;s
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MINI ADULT CHUNKS IN GRAVY
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MINI PUPPY CHUNKS IN GRAVY
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MINI ADULT
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MINI PUPPY
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MEDIUM DENTAL CARE
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            MINI DENTAL CARE
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                </div>
                                <div className="border-2 border-gray-200 rounded-md w-full md:w-4/5 text-left mb-4">
                                    <div className="font-semibold ml-6 mt-4">
                                        Planogram
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            Categories seperated
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 border-b-2 border-gray-200 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            High value items visible
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                    <div className="flex flex-row p-4 ml-2 mr-4">
                                        <div className="w-3/4 text-sm">
                                            {" "}
                                            Arranged
                                        </div>
                                        <ButtonComponent />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row w-full md:w-4/5 mt-5 mb-10">
                                <div className="flex w-5/6"></div>
                                <button
                                    onClick={() => handleSaveVisit()}
                                    className="md:w-1/6 w-1/2 bg-buttonMainBackground rounded-full text-buttonMainFontColor pl pr pt-1 pb-1 font-semibold align-right"
                                >
                                    Save
                                </button>
                            </div>
                        </section>
                    )}
                </IntlProvider>
            </div>
        </section>
    );
}

export default ClientDetailsPage;
