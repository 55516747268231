import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";

const useAuthentication = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const tokenString = localStorage.getItem("token");
        if (tokenString) {
            const token = JSON.parse(tokenString);
            const decodedAccessToken = jwtDecode(token.access_token);
            if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false); // User token has expired or is not valid
                // setRefreshing(false);
            }
        } else {
            setIsLoggedIn(false); // User token does not exist
            // setRefreshing(false);
        }
    }, []);

    return { isLoggedIn };
};

export default useAuthentication;
