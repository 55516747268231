import React, {useState, useEffect} from 'react';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import './App.css';
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import Login from './pages/login';
// import { useNavigate } from "react-router-dom";
// import SignUp from './pages/sign-up';
import Home from './pages/home';
import ClientDashboard from './pages/clients';
import ErrorPage from './pages/error-page';
import Profile from './pages/profile';
import Leads from './pages/leads';
import Products from './pages/products';
import ManagementDashboard from './pages/management'
// import Assessment from './pages/training/assessment'
import Training from './pages/training/training/index'
// import PerformanceOverview from "./pages/training/performance_overview"
// import TrainingManagement from './pages/training/training_management';
// import Elearning from './pages/training/elearning';
// import Rules from './pages/training/rules';
// import Casestudies from './pages/training/case-studies';
// import Settings from './pages/settings';
import ClientDetailsPage from './components/ClientDetails';
import Routing from './pages/routing';
import Orders from './pages/orders';
import OrderForm from './pages/OrderForm';
import Auth0 from './pages/auth0'
import { useAuth0 } from "@auth0/auth0-react";
// import Visit from './pages/visit';
import Navigator from './components/Navigator';
import Loader from './components/Loader';
import Footer from './components/Footer';
import Header from './components/Header';
// import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  // const navigate = useNavigate();
  console.log(isAuthenticated)
  const location = useLocation();
  console.log(location.pathname); // Log the current pathname to the console


  const [isLoading, setIsLoading] = useState(true);

  // Simulating loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 100); // Adjust the timeout as needed
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <Loader/>
  }


  return (
    <>
      {/* {isAuthenticated && location.pathname === "/" &&
        navigate("/home")
      } */}
      {isAuthenticated &&
      <section className="flex flex-row bg-white">
                <div className="z-10 border-r-1 border-white">
                    <Navigator />
                </div>
                <section className="w-full bg-white flex flex-col h-screen"
                 style={{
                  overflowY: "auto",
              }}>
                    <Header />
                    <div className="w-full bg-white flex flex-col h-screen" style={{
                  overflowY: "auto",
              }}>{children}</div>


                   <div className="flex-grow"></div>
                <Footer />
                </section>

            </section>
           }
      {!isAuthenticated && location.pathname === "/" &&
        <>{children}</>
      }
    </>
  );
};

const App = () => {
  const { handleRedirectCallback } = useAuth0();

  // const navigate = useNavigate();

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      console.log("hihi")
      console.log(url)
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        console.log("waiting")
        // await navigate("/home")
        await handleRedirectCallback(url);
        await Browser.close()
        // await handleRedirectCallback(url);
        console.log("closing")

      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);
  return (
    <div className="App bg-white">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/auth0" element={<Auth0 />} />
            <Route exact path="/clients" element={<ClientDashboard />} />
            <Route exact path="/home" element={<Home />} />
            {/* <Route exact path="/sign-up" element={<SignUp />} /> */}
            <Route exact={true} path="*" element={<ErrorPage />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/leads" element={<Leads />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/dashboard" element={<ManagementDashboard />} />
            <Route exact path="/training" element={<Training />} />
            <Route exact path="/client/:clientId" element={<ClientDetailsPage/>} />
            <Route exact path="/routing" element={<Routing/>} />
            <Route path="/orders" element={<Orders/>} />
            <Route path="/orderform" element={<OrderForm/>} />
            {/* <Route exact path="/assessment" element={<Assessment />} /> */}
            {/* <Route exact path="/performance" element={<PerformanceOverview />} /> */}
            {/* <Route exact path="/training_management" element={<TrainingManagement />} /> */}
            {/* <Route exact path="/elearning" element={<Elearning />} /> */}
            {/* <Route exact path="/rules" element={<Rules />} /> */}
            {/* <Route exact path="/case-studies" element={<Casestudies />} /> */}
            {/* <Route exact path="/settings" element={<Settings />} /> */}

            {/* <Route path="/visit" element={<Visit/>} /> */}
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;
